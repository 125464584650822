const invoiceValidation = () => ({
    company: () => ({
        required: '会社名を入力してください。',
    }),
    address: () => ({
        required: '住所を入力してください。',
    }),
    bankAccount: () => ({
        required: '銀行口座を入力してください。',

    }),
    paymentDate: () => ({
        required: '振込期限を入力してください。',
    }),
    date: () => ({
        required: '日付を入力してください。',
    }),
    customer: () => ({
        required: 'お客様を入力してください。',
    }),
    address2: () => ({
        required: '住所を選択してください。',
    }),
    callNum: () => ({
        required: '請求番号を入力してください。',
    }),
    projectTitle: () => ({
        required: '件名を入力してください。',
    }),
    quoteType: () => ({
        required: '見積種別を選択してください。'
    })

});

export default invoiceValidation;
