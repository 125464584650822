import { UploadProps } from 'antd';
import { useEffect, FocusEventHandler, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { alertSuccess, validateZipcode } from 'utils/helper/appHelper';
import quoteApi from 'services/quoteApi';
import moment from 'moment';
import { validateFullWhitespace } from 'utils/validation/validatorHelper';
import staffApi from 'services/staffApi';
import partnerService from 'services/partnerService';
import { ClassificationEnum } from 'utils/enums';
import { TYPE_OF_SCREEN } from 'pages/project/projectPlan/projectTabs/constant';

export type ReceivedProps = {
    screenType: 'create' | 'edit' | 'detail';
};
const classificationOptions = Object.values(ClassificationEnum).map((item) => ({
    value: item.value,
    name: item.title,
}));
const useQuoteForm = (props: ReceivedProps) => {
    const { screenType } = props;
    const {
        control,
        setValue,
        register,
        handleSubmit,
        formState: { errors, touchedFields },
        clearErrors,
        watch,
        getValues,
        setError,
    } = useForm();
    const id = useParams();
    const navigate = useNavigate();
    const [workersList, setWorkersList] = useState<any>([]);
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;

    const dummyRequest: UploadProps['customRequest'] = async ({
        file,
        onSuccess,
    }) => {
        setTimeout(() => {
            if (onSuccess) {
                onSuccess('ok');
            }
        }, 0);
    };

    const handleClose = () => {
        clearErrors();
        navigate('/partners');
    };

    const submitData = async (value: any, evt: any) => {
        if (value?.post_code) {
            const isZipcodeFormat = validateZipcode(value?.post_code);
            if (!isZipcodeFormat) {
                setError('post_code', {
                    type: 'validate',
                    message:
                        '郵便番号は数字のみで、000-0000の形式でなければなりません。',
                });
                return;
            }
        }

        if (value?.zipCodeConstruction) {
            const isZipcodeFormat = validateZipcode(value?.zipCodeConstruction);
            if (!isZipcodeFormat) {
                setError('post_code', {
                    type: 'validate',
                    message:
                        '郵便番号は数字のみで、000-0000の形式でなければなりません。',
                });
                return;
            }
        }
        let data = {
            ...value,
            representative_info: {
                name: value?.name,
                role: value?.role,
                phone_number: value?.phone_number,
                email: value?.email,
            },
        };
        try {
            if (screenType === TYPE_OF_SCREEN.create) {
                const res = await partnerService.createCooperativeCompany(data);
                if (res) {
                    alertSuccess('協力会社の更新に成功しました。');
                }
            } else {
                if(!id.id) return;
                const res = await partnerService.updateCooperativeCompany(
                    id.id,
                    data,
                );
                if (res) {
                    alertSuccess('協力会社の更新に成功しました。');
                    navigate(`/partners`);
                }
            }
            handleClose();
        } catch (error) {
            console.log(error);
        }
    };

    const getListWorkers = async () => {
        try {
            const res = await staffApi.getStaffs(user?.access_token, {});
            if (res !== null && res !== undefined) {
                const workersOptions = res?.data?.staffs?.map((item) => {
                    return { name: item?.name, value: item?.id };
                });
                setWorkersList(workersOptions);
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const getDetail = async () => {
        if(!id.id) return;
        try {
            const res = await partnerService.getCooperativeCompanyDetail(
                id.id,
            );
            if (res) {
                const data = (res as any)?.cooperative_company;
                setValue('company_id', data?.id);
                setValue('company_name', data?.company_name);
                setValue('company_name', data?.company_name);
                setValue('post_code', data?.post_code);
                setValue('city', data?.city);
                setValue('prefecture', data?.prefecture);
                setValue('address', data?.address);
                setValue('fax_number', data?.fax_number);
                setValue('classification', data?.classification === 'personal' ? ClassificationEnum.personal.value : ClassificationEnum.corporation.value);
                setValue('industry_type', data?.industry_type);
                setValue('introducer', data?.introducer);
                setValue('name', data?.representative_info?.name);
                setValue('role', data?.representative_info?.role);
                setValue('phone_number', data?.representative_info?.phone_number);
                setValue('email', data?.representative_info?.email);
                setValue('comment', data?.comment);
                setValue('staff_id', data?.staff?.id);
            }
        } catch (error) {
            //
        } finally {
        }
    };

    const handleValidateWhitespace: FocusEventHandler<
        HTMLInputElement | HTMLTextAreaElement
    > = (e) => {
        const onlyContainWhitespaces = validateFullWhitespace(e.target.value);
        if (onlyContainWhitespaces) {
            setValue(e.target.name, '');
        } else {
            setValue(e.target.name, e.target.value.trim());
        }
    };

    useEffect(() => {
        if (id) {
            getDetail();
        }
    }, [id]);

    useEffect(() => {
        setValue('classification', classificationOptions[0].value);
        getListWorkers();
        if (screenType === TYPE_OF_SCREEN.create) {
            setValue('staff_id', '');
        }
    }, []);

    return {
        ...props,
        handleSubmit,
        submitData,
        control,
        dummyRequest,
        setValue,
        register,
        clearErrors,
        watch,
        getValues,
        setError,
        errors,
        navigate,
        handleValidateWhitespace,
        screenType,
        workersList,
        classificationOptions,
        touchedFields,
        id,
    };
};

export type Props = ReturnType<typeof useQuoteForm>;

export default useQuoteForm;
