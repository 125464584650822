import styled from "styled-components";

export const InvoiceDetailStyles = styled.div`
  p {
    margin: 0px;
  }
  .wrapperBox {
    box-shadow: 0px 0px 3px #0C1A4B3D;
  }

  .uploadedImages {
    margin-bottom: 40px;
  }

  .quoteDetailWrapper {
    margin-bottom: 30px;
  }
`;