import {
    Dispatch,
    FocusEventHandler,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

import scheduleService from 'services/scheduleService';
import { CustomizedState } from '../SchedulerBox/hook';
import { validate } from './validate';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useForm } from 'react-hook-form';
import { validateFullWhitespace } from 'utils/validation/validatorHelper';
import scheduleApi from 'services/scheduleApi';
import { alertSuccess, alertSuccessPopup } from 'utils/helper/appHelper';
import Swal from 'sweetalert2';

export type ReceivedProps = {
    open: boolean;
    openDeleteConfirmationDialog: boolean;
    workerOptions: {
        id: any;
        name: string;
        value: string;
        label: string
    }[];
    quoteOptions: {
        id: any;
        name: string;
        value: string;
        label: string
    }[];
    activeTab: number;
    fetchSchedules: () => Promise<void>;
    setOpen: Dispatch<SetStateAction<boolean>>;
    setOpenDeleteConfirmationDialog: Dispatch<SetStateAction<boolean>>;
    setCurrentItemId: Dispatch<SetStateAction<number>>;
    setDetailId: Dispatch<SetStateAction<any>>;
    detailId: any
};

export const SCHEDULER_COLORS = [
    '#195192',
    '#4950F1',
    '#D83232',
    '#E3A900',
    '#002A5B',
    '#FF6231',
    '#32CED8',
    '#009F08',
    '#754D4D',
    '#FE3571',
    '#B7B7B7',
];

const useCreateScheduleDialog = (props: ReceivedProps) => {
    const { detailId, setDetailId } = props
    const location = useLocation();
    const state = location.state as CustomizedState;
    const editingId = state?.editingId;
    const { userData } = useSelector((state: RootState) => state.users);
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const [timeError, setTimeError] = useState('')
    const [loading, setLoading] = useState(false)
    const {
        control,
        setValue,
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
        watch,
        getValues,
        setError,
    } = useForm();
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: (() => ({
            worker_id:
                props.activeTab === 0
                    ? props.workerOptions?.find(
                        (item) => item.id === (userData as any)?.worker?.id,
                    )
                    : null,
            title: '',
            start_date: '',
            end_date: '',
            is_all_day: false,
            worker_schedules_attributes: [],
            color: '#195192',
            content: '',
            place: '',
            is_show_on_todo_list: true,
            targeted: false,
            status: 'initial',
        }))(),
        validate,
        onSubmit: (values) => submitForm(values),
    });

    const fetchSchedule = useCallback(async () => {
        try {
            const { schedule } = await scheduleService.getSchedule(detailId);
            console.log('schedule', schedule)
            if (schedule) {
                setValue('staff_id', schedule?.staff_id)
                setValue('quote_id', schedule?.quote_id)
                setValue('title', schedule?.title)
                setValue('content', schedule?.content)
                setValue('location', schedule?.location)
                formik.setFieldValue(
                    'color',
                    schedule?.color,
                )
                setValue('start_date', moment(schedule?.start_date))
                setValue('end_date', moment(schedule?.end_date))
            }
            // const worker_schedules_attributes = [
            //     ...schedule.worker_schedule_attributes,
            // ].map((item) => ({
            //     ...item,
            //     id: item.worker_id,
            // }));

            // formik.setValues({
            //     ...schedule,
            //     worker_id: props.workerOptions?.find(
            //         (item) => item.id === schedule?.worker?.id,
            //     ),
            //     worker_schedules_attributes,
            // });
        } catch (error) {
            //
        }
    }, [editingId]);

    const submitData = async (value: any) => {
        setLoading(true)
        if (!formik.values.is_all_day && (!value?.start_time || !value?.end_time)) {
            setTimeError('日時を入力してください。')
            return
        }

        if (formik.values.is_all_day && (!value?.start_date || !value?.end_date)) {
            setTimeError('終日を入力してください。')
            return
        }
        setTimeError('')
        const body: any = {
            staff_id: value?.staff_id,
            quote_id: value?.quote_id,
            title: value?.title,
            content: value?.content,
            location: value?.location,
            color: formik.values.color,
        }
        if (!formik.values.is_all_day) {
            body.start_time = moment(value?.start_time).format('HH:mm'),
                body.end_time = moment(value?.end_time).format('HH:mm')
        } else {
            body.start_date = value?.start_date,
                body.end_date = value?.end_date
        }
        try {
            if (detailId) {
                const res = await scheduleApi.updateSchedule(user?.access_token, body, detailId)
                if (res) {
                    alertSuccess('作成しました')
                    props.setOpen(false);
                    props.fetchSchedules();
                }
            } else {
                const res = await scheduleApi.createSchedule(user?.access_token, body)
                if (res) {
                    alertSuccess('作成しました')
                    props.setOpen(false);
                    props.fetchSchedules();
                }
            }

            setLoading(false)
        } catch (error) {
            console.log('error', error)
            setLoading(false)
        }
    }

    const submitForm = async (payload: Record<string, any>) => {
        const data: any = {
            ...payload,
            worker_id: payload.worker_id.id,
        };

        if (payload.is_all_day) {
            const start_date = moment(payload.start_date).format('YYYY/MM/DD');
            const end_date = moment(payload.end_date).format('YYYY/MM/DD');

            payload.start_date = new Date(`${start_date} 00:00:01`);
            payload.end_date = new Date(`${end_date} 23:59:59`);
        } else {
            const start_date = moment(payload.start_date).toISOString();
            const end_date = moment(payload.end_date).toISOString();

            payload.start_date = start_date;
            payload.end_date = end_date;
        }

        const { worker_schedule_attributes } = payload;
        const newScheduleAttr = formik.values.worker_schedules_attributes.map(
            (workerItem: any) => ({
                worker_id: workerItem.id,
                existingOnForm: true,
            }),
        );

        const mergeArray = _.merge(
            _.keyBy(worker_schedule_attributes, 'worker_id'),
            _.keyBy(newScheduleAttr, 'worker_id'),
        );
        const newArray = _.values(mergeArray);

        data.worker_schedules_attributes = newArray.map((item) => {
            if (item.existingOnForm && !item.id) {
                return {
                    worker_id: item.worker_id,
                };
            }
            if (!item.existingOnForm && item.id) {
                return {
                    _destroy: true,
                    id: item.id.toString(),
                    worker_id: item.worker_id,
                };
            }
            return {
                id: item.id.toString(),
                worker_id: item.worker_id,
            };
        });

        delete data.worker_schedule_attributes;

        try {
            if (!editingId) {
                await scheduleService.createSchedule(data);
            } else {
                await scheduleService.updateSchedule(editingId, data);
            }
        } catch (error) {
            //
        }
        props.fetchSchedules();
        props.setOpen(false);
    };

    const handleValidateWhitespace: FocusEventHandler<
        HTMLInputElement | HTMLTextAreaElement
    > = (e) => {
        const onlyContainWhitespaces = validateFullWhitespace(e.target.value);
        if (onlyContainWhitespaces) {
            setValue(e.target.name, '');
        } else {
            setValue(e.target.name, e.target.value.trim());
        }
    };

    useEffect(() => {
        // fetchSchedule();
        formik.setFieldValue(
            'is_all_day',
            true,
        )
        if (detailId) {
            fetchSchedule()
        }
    }, [detailId]);

    return {
        ...props,
        formik,
        editingId,
        control,
        setValue,
        register,
        handleSubmit,
        errors,
        clearErrors,
        watch,
        getValues,
        setError,
        handleValidateWhitespace,
        submitData,
        timeError,
        setTimeError,
        detailId,
        setDetailId
    };
};

export type Props = ReturnType<typeof useCreateScheduleDialog>;

export default useCreateScheduleDialog;
