import { FC } from 'react';

import SchedulerBox from './SchedulerBox';

import plus from '../../assets/icons/Plus.png';
import TabBox from './TabBox';
import useSchedules, { Props, ReceivedProps } from './hook';
import { SchedulesWrapper } from './style';
import FilterBox from './FilterBox';
import { useNavigate } from 'react-router-dom';

const SchedulesLayout: FC<Props> = ({
    openDeleteConfirmationDialog,
    openScheduleFormDialog,
    openPlanUpdateModal,
    activeTab,
    workerOptions,
    positionOptions,
    departmentOptions,
    searchParam,
    setSearchParam,
    setActiveTab,
    setOpenScheduleFormDialog,
    setOpenDeleteConfirmationDialog,
    setOpenPlanUpdateModal,
    quoteOptions, setQuoteOptions,
    detailId, setDetailId
}) => {
    const navigate = useNavigate();

    return (
        <>
            <SchedulesWrapper className="container-schedules d-flex flex-column">
                <div className="schedules-content d-flex flex-column">
                    <div className="schedules-header d-flex flex-row">
                        <div className="page-title" />
                        {activeTab === 1 && <button
                            className="button-create"
                            onClick={() => {
                                setOpenScheduleFormDialog(true);
                                setDetailId(null)
                            }}
                        >
                            <img width={'16px'} height={'16px'} src={plus} />
                            <span>新規作成</span>
                        </button>}
                    </div>

                    <TabBox activeTab={activeTab} setActiveTab={setActiveTab} />

                    <FilterBox
                        activeTab={activeTab}
                        workerOptions={workerOptions || []}
                        positionOptions={positionOptions || []}
                        departmentOptions={departmentOptions || []}
                        setSearchParam={setSearchParam}
                        quoteOptions={quoteOptions || []}
                        setActiveTab={setActiveTab}
                    />

                    <SchedulerBox
                        activeTab={activeTab}
                        searchParam={searchParam}
                        workerOptions={workerOptions || []}
                        quoteOptions={quoteOptions || []}
                        setOpenScheduleFormDialog={setOpenScheduleFormDialog}
                        setOpenDeleteConfirmationDialog={
                            setOpenDeleteConfirmationDialog
                        }
                        setOpenPlanUpdateModal={setOpenPlanUpdateModal}
                        openDeleteConfirmationDialog={
                            openDeleteConfirmationDialog
                        }
                        openScheduleFormDialog={openScheduleFormDialog}
                        openPlanUpdateModal={openPlanUpdateModal}
                        detailId={detailId}
                        setDetailId={setDetailId}
                    />
                </div>
            </SchedulesWrapper>
        </>
    );
};

const Schedules: FC<ReceivedProps> = (props) => (
    <SchedulesLayout {...useSchedules(props)} />
);

export default Schedules;
