import { ERROR_MESSAGE } from "pages/project/projectPlan/projectTabs/constant";
import { useEffect, useState } from "react";
import { useModal } from "react-hooks-use-modal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import quoteApi from "services/quoteApi";
import quoteService from "services/quoteService";
import { alertError, alertSuccess, alertSuccessPopup } from "utils/helper/appHelper";
import { UploadProps } from 'antd';
import { useForm } from "react-hook-form";
import moment from "moment";


export type ReceivedProps = Record<never, never>;
const useProjectDetail = (props: ReceivedProps) => {
    const {
        control,
        setValue,
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
        watch,
        getValues,
        setError,
    } = useForm();
    const [fileLength, setFileLength] = useState<number>(0);
    const [indexError, setIndexError] = useState(0);
    const [totalImageSize, setTotalImageSize] = useState<number>(0);
    const [sizeUploadLimitError, setSizeUploadLimitError] =
        useState<boolean>(false);
    const [listFile, setListFile]: any = useState([]);
    const [isAllowUpload, setAllowUpload] = useState(true);
    const { id } = useParams()
    const navigate = useNavigate()
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const [projectDetail, setProjectDetail] = useState<any>({})
    const [loading, setIsLoading] = useState(false)
    const getDetail = async () => {
        try {
            const res = await quoteApi.getQuoteDetail(user?.access_token, id);
            if (res) {
                const data = res.data.quote;
                setProjectDetail(data)
                const fileArr: any = [];
                let imageSize = 0;
                if (data?.images.length > 0) {
                    for (let i = 0; i < data?.images.length; i++) {
                        await fetch(data?.images[i].image_path)
                            .then((res) => res.blob())
                            .then((myBlob) => {
                                const myFile: any = new File(
                                    [myBlob],
                                    'image.jpeg',
                                    { type: myBlob.type },
                                );
                                fileArr.push({
                                    uid: `-${i + 1}`,
                                    name: myFile.name,
                                    url: data?.images[i].image_path,
                                    status: 'done',
                                    originFileObj: myFile,
                                    lastModified: myFile.lastModified,
                                    lastModifiedDate: myFile.lastModifiedDate,
                                    type: myFile.type,
                                    size: myFile.size,
                                });
                                imageSize += myFile.size;
                            });
                    }
                }
                setValue('images', fileArr);
                setTotalImageSize((prev) => prev + imageSize);
                setListFile(fileArr);
                setFileLength(fileArr.length);
                setAllowUpload(data?.images?.length < 10);
            }
        } catch (error) {
            //
        } finally {
            //   setLoading(false);
        }
    };

    const submitData = async (value: any, evt: any) => {
        // evt.preventDefault()
        let formData = new FormData();
        for (let i = 0; i < value?.images.length; i++) {
            formData.append('images[]', value?.images[i].originFileObj);
        }
        try {
            const res = await quoteApi.updateQuote(user?.access_token, formData, id);
            if (res) {
                alertSuccessPopup({
                    title: '見積依頼内容の更新に成功しました。',
                    confirmButtonText: 'OK',
                });

                navigate(`/quote/list`);
                setValue('images', '');
            }
        } catch (error) {
            setIsLoading(false);
        }
    };

    const dragProps: UploadProps = {
        name: "file",
        onChange(info) {
            console.log("onChange info", info);
        },
        onDrop(e) {
            console.log("Dropped files", e.dataTransfer.files);
        },
    };
    const dummyRequest: UploadProps["customRequest"] = async ({
        file,
        onSuccess
    }) => {
        setTimeout(() => {
            if (onSuccess) {
                onSuccess("ok");
            }
        }, 0);
    };

    useEffect(() => {
        if (id) {
            getDetail();
        }
    }, [id]);

    return {
        ...props,
        control,
        dragProps,
        dummyRequest,
        setValue,
        register,
        clearErrors,
        watch,
        getValues,
        setError,
        errors,
        navigate,
        fileLength,
        isAllowUpload,
        listFile,
        setFileLength,
        setAllowUpload,
        setListFile,
        totalImageSize,
        setTotalImageSize,
        setIndexError,
        indexError,
        sizeUploadLimitError, setSizeUploadLimitError,
        projectDetail,
        handleSubmit,
        submitData
    };
};

export type Props = ReturnType<typeof useProjectDetail>;

export default useProjectDetail;
