import { TypeOfT } from "utils/type/translations/Translation.type";
import { validZipCode, required, validKatakana } from "../validator";

const quoteValidation = (t: TypeOfT) => ({
    notes: () => ({
        required: '依頼内容を入力してください。',
    }),
    dateTime: () => ({
        required: '工事希望日を入力してください。',
    }),
    zipCode: () => ({
        required: '現場：郵便番号を入力してください。',
        validate: {
            regex: validZipCode('zipcode', t!),
        }
    }),
    title: () => ({
        required: 'タイトルを入力してください。',
    }),
    content: () => ({
        required: '依頼内容を入力してください。',
    }),
    address: () => ({
        required: '住所を入力してください。',
    }),
    type: () => ({
        required: '見積種別を選択してください。',
    }),
    first_name: () => ({
        required: required("customer_first_name", t),
    }),
    last_name: () => ({
        required: required("customer_last_name", t),
    }),
    project_title: () => ({
        required: "件名は必須です。",
    }),
    first_name_kana: () => ({
        required: required("customer_first_name_kana", t),
        validate: {
            validKatakana: validKatakana("customer_first_name_kana", t),
        },
    }),
    last_name_kana: () => ({
        required: required("customer_last_name_kana", t),
        validate: {
            validKatakana: validKatakana("customer_last_name_kana", t),
        },
    }),
    phone: () => ({
        required: required("customer_phone", t),
    }),
});

export default quoteValidation;
