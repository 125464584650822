import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import invoiceApi from "services/invoiceApi";

export type ReceivedProps = Record<never, never>;
const useSPInvoiceDetail = (props: ReceivedProps) => {
    const [isUpdateStatus, setUpdateStatus] = useState<boolean>(false);
    const [invoice, setInvoice]: any = useState();
    let storage = localStorage.getItem('line:accessToken');
    const params = useParams();
    const {id} = params;

    const fetchInvoice = async () => {
        try {

            const res = await invoiceApi.getLineInvoiceDetail(storage, id);
            if (res) {
                setInvoice(res?.data?.invoice);
            }
        } catch (error) {
            //
        }
    };

    const updateStatusInvoice = async (status: string) => {
        try {
            await invoiceApi.updateInvoiceStatus(storage, status, id);
            setUpdateStatus(!isUpdateStatus);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchInvoice();
    }, [isUpdateStatus]);
    return {
        ...props,
        invoice,
        updateStatusInvoice
    };
};
export type Props = ReturnType<typeof useSPInvoiceDetail>;

export default useSPInvoiceDetail;
