import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';
import moment from 'moment';
import omit from 'lodash/omit';
import { useSelector } from 'react-redux';

import { RootState } from './../../../store/index';
import scheduleService from 'services/scheduleService';
import { getDateTimeUtc } from 'utils/helper/dateHelper';
import { DATE_FORMAT } from 'utils/constants/date';
import { columnsGanttSchedule, columnsTime } from './TimeLine';
import { MONTH_FORMAT, MONTH_FORMAT_2, YEAR_FORMAT } from 'pages/project/projectPlan/projectTabs/constant';

export type ReceivedProps = {
    setOpenScheduleFormDialog: Dispatch<SetStateAction<boolean>>;
    setOpenDeleteConfirmationDialog: Dispatch<SetStateAction<boolean>>;
    setOpenPlanUpdateModal: Dispatch<SetStateAction<boolean>>;
    searchParam: any;
    openDeleteConfirmationDialog: boolean;
    openScheduleFormDialog: boolean;
    openPlanUpdateModal: boolean;
    workerOptions: {
        id: any;
        name: string;
        value: string;
        label: string
    }[];
    quoteOptions: {
        id: any;
        name: string;
        value: string;
        label: string
    }[];
    activeTab: number;
    setDetailId: Dispatch<SetStateAction<any>>;
    detailId: any
};

export const SWITCHER_LIST = [
    {
        value: 'Day',
        label: '日',
    },
    // {
    //     value: 'Week',
    //     label: '週',
    // },
    {
        value: 'Month',
        label: '月',
    },
    {
        value: 'Year',
        label: '年'
    }
];

export interface CustomizedState {
    editingId: number;
}

const useSchedulerBox = (props: ReceivedProps) => {
    const { userData } = useSelector((state: RootState) => state.users);
    const { detailId, setDetailId } = props
    const [showDayList, setShowDayList] = useState<boolean>(false);
    const [currentViewName, setCurrentViewName] = useState<string>('Year');
    const [currentDate, setCurrentDate] = useState<any>();
    const [localScheduleDate, setLocalScheduleData] = useState<any[]>();
    const [currentItemId, setCurrentItemId] = useState<any>(null);
    const [scheduleList, setScheduleList] = useState<any>({
        data: [],
    });
    const [scheduleGantt, setScheduleGantt] = useState<any>([])
    const [dateFilter, setDateFilter] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1))
    const [listColor, setListColor] = useState([''])
    const [loading, setLoading] = useState<boolean>(false);
    const [isShowData, setIsShowData] = useState((localStorage.getItem("showData") === "false" ? false : true));

    const commitChanges = () => { };

    const fetchSchedules = useCallback(async () => {
        // if (!userData.worker) return;
        const lastYearDate = new Date(dateFilter.getFullYear(), 11, 31, 23, 59, 59)
        const lastMonthDate = new Date(dateFilter.getFullYear(), dateFilter.getMonth() + 1, 0, 23, 59, 59)
        const firstYearDate = new Date(dateFilter.getFullYear(), 0, 1, 0, 1, 0)
        const firstMonthDate = new Date(dateFilter.getFullYear(), dateFilter.getMonth(), 1, 0, 1, 0)
        const queryFilter = {
            ...props.searchParam,
            filter_value: props.searchParam?.free_word,
            quote_id: props.activeTab === 0 ? props.searchParam?.position_id : null,
            staff_id:
                props.activeTab === 0
                    ? null
                    : props.searchParam.worker_id,
        };
        if (currentViewName === SWITCHER_LIST[2].value) {
            queryFilter.year = moment(dateFilter).format(YEAR_FORMAT)
        } else if (currentViewName === SWITCHER_LIST[1].value) {
            queryFilter.month_year = moment(dateFilter).format(MONTH_FORMAT_2)
        } else {
            queryFilter.day = moment(dateFilter).format(DATE_FORMAT)
        }

        Object.keys(queryFilter).forEach((key) => {
            if ([undefined, null, ''].includes(queryFilter[key])) {
                delete queryFilter[key];
            }
        });

        try {
            setLoading(true);
            const { schedules, meta, quotes } = await scheduleService.getCalendars({
                ...queryFilter,
            });
            const schedulesData = schedules.map((item) => ({
                ...omit(item, ['start_date', 'end_date']),
                startDate: item.start_date ? getDateTimeUtc(item.start_date) : getDateTimeUtc(item.start_time),
                endDate: item.end_date ? getDateTimeUtc(item.end_date) : getDateTimeUtc(item.end_time),
                allDay: item.start_time ? false : true,
            }));
            const dataGantt: any = []
            const dataSchedule: any = []
            const colorArr: string[] = []
            dataGantt.push(columnsTime)
            if (props.activeTab === 1) {
                schedules.map((item, index) => {
                    let start_date = new Date(item?.start_date)
                    start_date = new Date(start_date.getFullYear(), start_date.getMonth(), start_date.getDate(), 0, 1)
                    let end_date = new Date(item?.end_date)
                    end_date = new Date(end_date.getFullYear(), end_date.getMonth(), end_date.getDate(), 23, 59)
                    const tempGantt: any = []
                    if (item?.start_date && item?.end_date) {
                        tempGantt.push(String(item?.id),
                            `${item?.title}`, getStartDate(firstMonthDate, firstYearDate, start_date, end_date), getEndDate(lastMonthDate, lastYearDate, end_date, start_date))
                        dataGantt.push(tempGantt)
                        dataSchedule.push({
                            id: item?.id,
                            project_title: item?.title,
                            start_date: moment(item?.start_date).format(DATE_FORMAT),
                            end_date: item?.end_date &&
                                moment(item?.end_date).format(DATE_FORMAT),
                            staff_name: item?.staff_name,
                            color: item?.color
                        })
                        colorArr.push(item?.color)
                    }
                });
            } else {
                quotes.map((item, index) => {
                    const tempGantt: any = []
                    let desired_date = new Date(item?.desired_date)
                    desired_date = new Date(desired_date.getFullYear(), desired_date.getMonth(), desired_date.getDate(), 0, 1)
                    let desired_end_date = new Date(item?.desired_end_date)
                    desired_end_date = new Date(desired_end_date.getFullYear(), desired_end_date.getMonth(), desired_end_date.getDate(), 23, 59)
                    if (item?.desired_date && item?.desired_end_date) {
                        tempGantt.push(String(item?.id),
                            `${item?.project_title}`, getStartDate(firstMonthDate, firstYearDate, desired_date, desired_end_date), getEndDate(lastMonthDate, lastYearDate, desired_end_date, desired_date))
                        dataGantt.push(tempGantt)
                        dataSchedule.push({
                            id: item?.id,
                            project_title: item?.project_title,
                            start_date: moment(item?.desired_date).format(DATE_FORMAT),
                            end_date: item?.desired_end_date &&
                                moment(item?.desired_end_date).format(DATE_FORMAT),
                            staff_name: item?.staff_name,
                            color: item?.color
                        })
                        colorArr.push('#fff')
                    }
                });
            }

            setScheduleGantt(dataGantt)
            setScheduleList(dataSchedule)
            setListColor(colorArr)
            // const newArr = [...schedulesData];
            // setScheduleList({
            //     data: newArr.sort(
            //         (d1, d2) =>
            //             new Date(d1.startDate).getTime() -
            //             new Date(d2.startDate).getTime(),
            //     ),
            // });
            setLoading(false);
        } catch (error) {
            setScheduleList({
                data: []
            })
            setLoading(false);
        }
    }, [userData, props.searchParam, props.activeTab, dateFilter, currentViewName]);

    const deleteSchedule = async () => {
        try {
            await scheduleService.deleteSchedule(currentItemId);
            props.setOpenDeleteConfirmationDialog(false);
            props.setOpenScheduleFormDialog(false);
            fetchSchedules();
        } catch (error) {
            //
        }
    };

    const getEndDate = (monthLast, yearLast, dateEnd, dateStart) => {
        if (dateStart.getMonth() === dateEnd.getMonth() && dateStart.getDate() === dateEnd.getDate()) {
            return dateEnd
        }
        else
            if (currentViewName === SWITCHER_LIST[2].value) {
                if (new Date(dateEnd).getTime() > yearLast.getTime()) {
                    return yearLast
                } else {
                    return new Date(dateEnd)
                }
            } else if (currentViewName === SWITCHER_LIST[1].value) {
                if (new Date(dateEnd).getTime() > monthLast.getTime()) {
                    // console.log('dateEnd', dateEnd)
                    return monthLast
                } else {
                    return dateEnd
                }
            } else {
                if (new Date(dateEnd).getTime() > dateFilter.getTime()) {
                    return new Date(dateFilter.getFullYear(), dateFilter.getMonth(), dateFilter.getDate(), 23, 59, 0)
                } else {
                    return new Date(dateFilter.getFullYear(), dateFilter.getMonth(), dateFilter.getDate(), 23, 59, 0)
                }
            }
    }

    const getStartDate = (monthLast, yearLast, dateStart, dateEnd) => {
        if (dateStart.getMonth() === dateEnd.getMonth() && dateStart.getDate() === dateEnd.getDate()) {
            return dateStart
        }
        else if (currentViewName === SWITCHER_LIST[2].value) {
            if (new Date(dateStart).getTime() < yearLast.getTime()) {
                return yearLast
            } else {
                return new Date(dateStart)
            }
        } else if (currentViewName === SWITCHER_LIST[1].value) {
            if (new Date(dateStart).getTime() < monthLast.getTime()) {
                // console.log('dateStart', dateStart)
                return monthLast
            } else {
                return new Date(dateStart)
            }
        } else {
            if (new Date(dateStart).getTime() < dateFilter.getTime()) {
                return new Date(dateFilter.getFullYear(), dateFilter.getMonth(), dateFilter.getDate(), 0, 0, 1)
            } else {
                return new Date(dateStart)
            }
        }
    }

    const setNext = () => {
        if (currentViewName === SWITCHER_LIST[2].value) {
            setDateFilter(new Date(dateFilter.setFullYear(dateFilter.getFullYear() + 1)))
        } else if (currentViewName === SWITCHER_LIST[1].value) {
            setDateFilter(new Date(dateFilter.setMonth(dateFilter.getMonth() + 1)))
        } else {
            setDateFilter(new Date(dateFilter.setDate(dateFilter.getDate() + 1)))
        }
    }

    const setPrevious = () => {
        // dateFilter.setFullYear(dateFilter.getFullYear() - 1)
        if (currentViewName === SWITCHER_LIST[2].value) {
            setDateFilter(new Date(dateFilter.setFullYear(dateFilter.getFullYear() - 1)))
        } else if (currentViewName === SWITCHER_LIST[1].value) {
            setDateFilter(new Date(dateFilter.setMonth(dateFilter.getMonth() - 1)))
        } else {
            setDateFilter(new Date(dateFilter.setDate(dateFilter.getDate() - 1)))
        }
    }


    useEffect(() => {
        fetchSchedules();
    }, [fetchSchedules]);

    return {
        ...props,
        loading,
        showDayList,
        currentViewName,
        currentDate,
        localScheduleDate,
        scheduleList,
        deleteSchedule,
        setCurrentItemId,
        fetchSchedules,
        commitChanges,
        setLocalScheduleData,
        setCurrentDate,
        setShowDayList,
        setCurrentViewName,
        isShowData,
        setIsShowData,
        scheduleGantt,
        detailId, setDetailId,
        dateFilter, setDateFilter,
        setNext, setPrevious,
        listColor
    };
};

export type Props = ReturnType<typeof useSchedulerBox>;

export default useSchedulerBox;
