export const OrderItemsData = [
    {
        isdustrial_wastes: 'コンクリートガラ',
        amount: 85,
        unit: 't',
        price_for_one_truck: '40000',
        number_of_truck: 3,
        unit_price: '1500',
        disposal_unit: 5,
        company: '(株)章栄',
        destination: 'SRC',
        collect: 400,
        disposal: 200,
    },
    {
        isdustrial_wastes: 'アスファルトガラ',
        amount: '',
        unit: '',
        price_for_one_truck: '',
        number_of_truck: '',
        unit_price: '',
        disposal_unit: '',
        company: '',
        destination: '',
        collect: '',
        disposal: '',
    },
    {
        isdustrial_wastes: 'その他がれき',
        amount: '',
        unit: '',
        price_for_one_truck: '',
        number_of_truck: '',
        unit_price: '',
        disposal_unit: '',
        company: '',
        destination: '',
        collect: '',
        disposal: '',
    },
    {
        isdustrial_wastes: 'ガラス・陶磁器くず',
        amount: '',
        unit: '',
        price_for_one_truck: '',
        number_of_truck: '',
        unit_price: '',
        disposal_unit: '',
        company: '',
        destination: '',
        collect: '',
        disposal: '',
    },
    {
        isdustrial_wastes: '廃プラスチック類',
        amount: '',
        unit: '',
        price_for_one_truck: '',
        number_of_truck: '',
        unit_price: '',
        disposal_unit: '',
        company: '',
        destination: '',
        collect: '',
        disposal: '',
    },
    {
        isdustrial_wastes: '金属くず',
        amount: '',
        unit: '',
        price_for_one_truck: '',
        number_of_truck: '',
        unit_price: '',
        disposal_unit: '',
        company: '',
        destination: '',
        collect: '',
        disposal: '',
    },
    {
        isdustrial_wastes: '紙くず',
        amount: '',
        unit: '',
        price_for_one_truck: '',
        number_of_truck: '',
        unit_price: '',
        disposal_unit: '',
        company: '',
        destination: '',
        collect: '',
        disposal: '',
    },
];

export const maniFestPopupData = [
    {
        title: '第 一 次 マ ニ フ ェ ス ト',
        data: [
            {
                title: 'A(発行控え)',
                distributor: {
                    obtain: true,
                    checked: false,
                },
                colection_and_transportation: { checked: true, },
                middleman: { checked: true, },
                colection_and_transportation_sc: { checked: true, },
                final_diposal_site: { checked: true, },
            },
            {
                title: 'B1(収集運搬終了控え)',
                distributor: { checked: false, },
                colection_and_transportation: { checked: false, },
                middleman: { checked: true, },
                colection_and_transportation_sc: { checked: true, },
                final_diposal_site: { checked: true, },
            },
            {
                title: 'B2(収集運搬終了報告)',
                distributor: { checked: false, },
                colection_and_transportation: { checked: false, },
                middleman: { checked: true, },
                colection_and_transportation_sc: { checked: true, },
                final_diposal_site: { checked: true, },
            },
            {
                title: 'C1(中間処理完了控え)',
                distributor: { checked: false, },
                colection_and_transportation: { checked: false, },
                middleman: { checked: false, },
                colection_and_transportation_sc: { checked: true, },
                final_diposal_site: { checked: true, },
            },
            {
                title: 'C2(中間処理完了報告)',
                distributor: { checked: false, },
                colection_and_transportation: { checked: false, },
                middleman: { checked: false, },
                colection_and_transportation_sc: { checked: true, },
                final_diposal_site: { checked: true, },
            },
            {
                title: 'D(中間処理完了報告)',
                distributor: { checked: false, },
                colection_and_transportation: { checked: false, },
                middleman: { checked: false, },
                colection_and_transportation_sc: { checked: true, },
                final_diposal_site: { checked: true, },
            },
            {
                title: 'E(最終処分完了報告)',
                distributor: { checked: false, },
                colection_and_transportation: { checked: false, },
                middleman: { checked: false, },
                colection_and_transportation_sc: { checked: true, },
                final_diposal_site: { checked: true, },
            },
        ],
    },
    {
        title: '第 二 次 マ ニ フ ェ ス ト',
        data: [
            {
                title: 'A(発行控え)',
                distributor: {
                    obtain: false,
                    checked: true,
                },
                colection_and_transportation: { checked: true },
                middleman: { checked: false },
                colection_and_transportation_sc: { checked: true },
                final_diposal_site: { checked: true },
            },
            {
                title: 'B1(収集運搬終了控え)',
                distributor: {
                    obtain: false,
                    checked: true,
                },
                colection_and_transportation: { checked: true, },
                middleman: { checked: false, },
                colection_and_transportation_sc: { checked: false, },
                final_diposal_site: { checked: true, },
            },
            {
                title: 'B2(収集運搬終了報告)',
                distributor: { checked: true, },
                colection_and_transportation: { checked: true, },
                middleman: { checked: false, },
                colection_and_transportation_sc: { checked: false, },
                final_diposal_site: { checked: true, },
            },
            {
                title: 'C1(中間処理完了控え)',
                distributor: { checked: true, },
                colection_and_transportation: { checked: true, },
                middleman: { checked: false, },
                colection_and_transportation_sc: { checked: false, },
                final_diposal_site: { checked: false, },
            },
            {
                title: 'C2(中間処理完了報告)',
                distributor: { checked: true, },
                colection_and_transportation: { checked: true, },
                middleman: { checked: false, },
                colection_and_transportation_sc: { checked: false, },
                final_diposal_site: { checked: false, },
            },
            {
                title: 'D(中間処理完了報告)',
                distributor: { checked: true, },
                colection_and_transportation: { checked: true, },
                middleman: { checked: false, },
                colection_and_transportation_sc: { checked: false, },
                final_diposal_site: { checked: false, },
            },
            {
                title: 'E(最終処分完了報告)',
                distributor: { checked: true, },
                colection_and_transportation: { checked: true, },
                middleman: { checked: false, },
                colection_and_transportation_sc: { checked: false, },
                final_diposal_site: { checked: false, },
            },
        ],
    },
];

export const TYPE_OF_SCREEN: any = {
    create: "create",
    edit: "edit",
    detail: "detail"
}

export const ALL_CHARACTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

export const OPTION_NONE = [
    {
        value: '',
        name: 'なし'
    }
]

export const ERROR_MESSAGE = 'もう一度お試しください'

export const MAX_LENGTH = 255

export const DATE_FORMAT = 'YYYY-MM-DD'
export const MONTH_FORMAT = 'YYYY-MM'
export const MONTH_FORMAT_2 = 'MM-YYYY'
export const YEAR_FORMAT = 'YYYY'