import styled from 'styled-components';

export const InvoiceSelectStyle = styled.div`
    select {
    height: 40px;
    }
            .select-search {
            .select-search__input {
                border: 1px solid #cdd1d5;
                box-shadow: none;
height: 40px;
                &:hover {
                    border-color: #215493;
                }
            }

            .select-search__select {
                .select-search__options {
                    padding-left: 0 !important;
                    .select-search__row {
                        .select-search__option.is-selected {
                            background: #215493 !important;;
                        }
                    }
                }
            }
        }
`;