import { Controller, ErrorOption, useForm } from 'react-hook-form';
import { SPCustomerInfoFormStyles } from './styled';
import { useNavigate } from 'react-router-dom';
import { Col, Input, Row } from 'antd';
import { PatternFormat } from 'react-number-format';
import customerInfoValidation from 'utils/validation/registers/customers/customerInfoValidation';
import { useTranslation } from 'react-i18next';
import { FocusEventHandler, useEffect, useRef, useState } from 'react';
import lineCustomerApi from 'services/lineCustomerApi';
import { alertPopup, validateZipcode } from 'utils/helper/appHelper';
import { validateFullWhitespace } from 'utils/validation/validatorHelper';
import { fullWidthNumConvert } from 'pages/invoice';

const SPCustomerInfoForm: React.FC<{}> = () => {
    const {
        control,
        handleSubmit,
        register,
        setError,
        setValue,
        formState: { errors },
        resetField,
        clearErrors,
        getValues,
    } = useForm();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const findZipcodeTimer = useRef<NodeJS.Timeout>();

    const handleValidateWhitespace: FocusEventHandler<
        HTMLInputElement | HTMLTextAreaElement
    > = (e) => {
        const onlyContainWhitespaces = validateFullWhitespace(e.target.value);
        if (onlyContainWhitespaces) {
            setValue(e.target.name, '');
        }
    };

    useEffect(() => {
        const getCustomerInfo = async () => {
            try {
                const response = await lineCustomerApi.getInfo();
                if (response) {
                    const { data } = response;
                    if (data && data.customer) {
                        setValue('first_name', data.customer.first_name || '');
                        setValue(
                            'first_name_kana',
                            data.customer.first_name_katakana || '',
                        );
                        setValue('last_name', data.customer.last_name || '');
                        setValue(
                            'last_name_kana',
                            data.customer.last_name_katakana || '',
                        );
                        setValue('zipcode', data.customer.zipcode || '');
                        setValue('address', data.customer.address || '');
                        setValue('phone', data.customer.phone_number || '');
                    }
                }
            } catch (error) {
                //
            }
        };

        getCustomerInfo();
    }, []);

    const submitData = async (value: any) => {
        if (value?.zipcode) {
            const isZipcodeFormat = validateZipcode(value?.zipcode);
            if (!isZipcodeFormat) {
                setError('zipcode', {
                    type: 'validate',
                    message:
                        '郵便番号は数字のみで、000-0000の形式でなければなりません。',
                });
                return;
            }
        }
        setSubmitLoading(true);
        if (value.phone) {
            value.phone = value.phone.trim();
        }
        let formData = new FormData();
        formData.append('first_name', value?.first_name || '');
        formData.append('first_name_katakana', value?.first_name_kana || '');
        formData.append('last_name', value?.last_name || '');
        formData.append('last_name_katakana', value?.last_name_kana || '');
        formData.append('phone_number', value.phone || '');
        formData.append('zipcode', value?.zipcode || '');
        formData.append('address', value?.address || '');
        try {
            const response = await lineCustomerApi.updateInfo(formData);
            if (response) {
                const { data } = response;
                if (data.status && data.status === 'error') {
                    alertPopup({ type: 'error', title: data.message || '' });
                    return;
                }
                alertPopup({
                    type: 'success',
                    title: '保存が完了しました。',
                    haveCustomClass: true,
                });
                backToWebTop();
            }
        } catch (error) {
            //
        } finally {
            setSubmitLoading(false);
        }
    };

    const backToWebTop = () => {
        navigate('/line');
    };

    const parsePostalCode = (zipcode: string) => {
        if (!zipcode) {
            setError('zipcode', {
                type: 'required',
                message: '郵便番号は必須です。',
            });
            return;
        }

        const isZipcodeFormat = validateZipcode(zipcode);
        if (!isZipcodeFormat) {
            setError('zipcode', {
                type: 'validate',
                message:
                    '郵便番号は数字のみで、000-0000の形式でなければなりません。',
            });
            return;
        }

        if (findZipcodeTimer.current) {
            clearTimeout(findZipcodeTimer.current);
        }

        let foundAddress: string;
        const postalCode = require('japan-postal-code');
        postalCode.get(zipcode, function (address) {
            foundAddress = `${address?.prefecture}${address?.city}${address?.area}`;
        });

        findZipcodeTimer.current = setTimeout(() => {
            if (foundAddress) {
                setValue('address', foundAddress);
                clearErrors('address');
                clearErrors('zipcode');
            } else {
                setValue('address', '');
                setError('zipcode', {
                    type: 'validate',
                    message: '入力された郵便番号に該当する住所が存在しません。',
                });
            }
        }, 1000);
    };

    useEffect(() => {
        return () => {
            if (findZipcodeTimer.current) {
                clearTimeout(findZipcodeTimer.current);
            }
        };
    }, []);

    return (
        <SPCustomerInfoFormStyles>
            <form
                onSubmit={handleSubmit(submitData)}
                className="relative !min-h-[calc(100vh-80px)]"
            >
                <div className="w-full flex flex-col gap-y-[14px]  mb-[120px]">
                    <Row gutter={12}>
                        <Col xs={12}>
                            <div>
                                <Controller
                                    name="last_name"
                                    rules={{ required: true }}
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">
                                                    *
                                                </span>
                                                姓
                                            </p>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="textBox"
                                                {...register(
                                                    'last_name',
                                                    customerInfoValidation(
                                                        t,
                                                    ).last_name(),
                                                )}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                            ></input>
                                            {errors.last_name && (
                                                <span className="error text-xs tracking-tight mt-1">
                                                    {errors.last_name.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                        </Col>

                        <Col xs={12}>
                            <div>
                                <Controller
                                    name="first_name"
                                    rules={{ required: true }}
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">
                                                    *
                                                </span>
                                                名
                                            </p>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="textBox"
                                                {...register(
                                                    'first_name',
                                                    customerInfoValidation(
                                                        t,
                                                    ).first_name(),
                                                )}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                            ></input>
                                            {errors.first_name && (
                                                <span className="error text-xs tracking-tight mt-1">
                                                    {errors.first_name.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={12}>
                        <Col xs={12}>
                            <div>
                                <Controller
                                    name="last_name_kana"
                                    rules={{ required: true }}
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">
                                                    *
                                                </span>
                                                姓（フリガナ）
                                            </p>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="textBox"
                                                {...register(
                                                    'last_name_kana',
                                                    customerInfoValidation(
                                                        t,
                                                    ).last_name_kana(),
                                                )}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                            ></input>
                                            {errors.last_name_kana && (
                                                <span className="error text-xs tracking-tight mt-1">
                                                    {errors.last_name_kana.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                        </Col>

                        <Col xs={12}>
                            <div>
                                <Controller
                                    name="first_name_kana"
                                    rules={{ required: true }}
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">
                                                    *
                                                </span>
                                                名（フリガナ）
                                            </p>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="textBox"
                                                {...register(
                                                    'first_name_kana',
                                                    customerInfoValidation(
                                                        t,
                                                    ).first_name_kana(),
                                                )}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                            ></input>
                                            {errors.first_name_kana && (
                                                <span className="error text-xs tracking-tight mt-1">
                                                    {errors.first_name_kana.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                        </Col>
                    </Row>

                    <div className="flex flex-col w-full">
                        <Controller
                            name="phone"
                            rules={{ required: true }}
                            control={control}
                            render={({ field }) => {
                                const { name, onChange, onBlur, ref } =
                                    register(
                                        'phone',
                                        customerInfoValidation(t).phone(),
                                    );
                                return (
                                    <div className="d-flex flex-column">
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            <span className="text-red mr-1">
                                                *
                                            </span>
                                            電話番号
                                        </p>
                                        <Input
                                            maxLength={12}
                                            {...register('phone')}
                                            onBlur={(evt) => {
                                                setValue('phone', fullWidthNumConvert(evt.target.value,),
                                                );
                                                // onBlur()
                                            }}
                                            value={field.value}
                                            onChange={(evt) => {
                                                let stringWithoutCommas = evt.target.value.replace(/,/g, '',);
                                                if (stringWithoutCommas.match('^[0-9０-９]+$',) || stringWithoutCommas === '') {
                                                    if (stringWithoutCommas.length <= 12) {
                                                        const newVal = fullWidthNumConvert(stringWithoutCommas,);
                                                        field.onChange(stringWithoutCommas,);
                                                        setValue('phone', stringWithoutCommas,);
                                                    }
                                                }
                                            }}
                                        />
                                        {errors.phone && (
                                            <span className="error text-xs tracking-tight mt-1">
                                                {errors.phone.message?.toString()}
                                            </span>
                                        )}
                                    </div>
                                );
                            }}
                        />
                    </div>

                    <div className="flex flex-col w-full">
                        <Controller
                            name="zipcode"
                            control={control}
                            rules={{ required: true }}
                            render={({ field, fieldState }) => {
                                const { name, onChange, onBlur, ref } =
                                    register(
                                        'zipcode',
                                        customerInfoValidation(t).zipcode(),
                                    );
                                return (
                                    <div className="d-flex flex-column">
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            <span className="text-red mr-1">
                                                *
                                            </span>
                                            郵便番号
                                        </p>
                                        <div className="flex">
                                            <Input
                                                placeholder="000-0000"
                                                {...register('zipcode')}
                                                onBlur={(evt) => {
                                                    let string =
                                                        evt.target.value.replace(
                                                            'ー',
                                                            '-',
                                                        );
                                                    string = string.replace(
                                                        '－',
                                                        '-',
                                                    );
                                                    string = string.replace(
                                                        '−',
                                                        '-',
                                                    );
                                                    setValue(
                                                        'zipcode',
                                                        fullWidthNumConvert(
                                                            string,
                                                        ),
                                                    );
                                                }}
                                                maxLength={8}
                                                value={field.value}
                                                onChange={(evt) => {
                                                    // let string = evt.target.value.replace('ー', '-')
                                                    // string = string.replace('－', '-')
                                                    field.onChange(
                                                        evt.target.value,
                                                    );
                                                    setValue(
                                                        'zipcode',
                                                        evt.target.value,
                                                    );
                                                    clearErrors('zipcode');
                                                }}
                                            />
                                            <button
                                                className="searchAddressBtn"
                                                type="button"
                                                onClick={() => {
                                                    if (fieldState.error) {
                                                        return;
                                                    }
                                                    parsePostalCode(
                                                        getValues(
                                                            'zipcode',
                                                        ) as string,
                                                    );
                                                }}
                                            >
                                                住所自動入力
                                            </button>
                                        </div>
                                        {errors.zipcode && (
                                            <span className="error text-xs tracking-tight mt-1">
                                                {errors.zipcode.message?.toString()}
                                            </span>
                                        )}
                                    </div>
                                );
                            }}
                        />
                    </div>

                    <div className="flex flex-col w-full">
                        <Controller
                            name="address"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <div className="d-flex flex-column">
                                    <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                        <span className="text-red mr-1">*</span>
                                        住所
                                    </p>
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        className="textBox"
                                        {...register(
                                            'address',
                                            customerInfoValidation(t).address(),
                                        )}
                                        onBlur={handleValidateWhitespace}
                                    ></input>
                                    {errors.address && (
                                        <span className="error text-xs tracking-tight mt-1">
                                            {errors.address.message?.toString()}
                                        </span>
                                    )}
                                </div>
                            )}
                        />
                    </div>
                </div>

                <div className="d-flex flex-row w-full items-center mt-4 justify-between !absolute bottom-4 border-t border-[#D9D9D9] pt-4 gap-2">
                    <button
                        className="modal-create-user-button1 d-flex flex-row !border-[#D9D9D9] w-full !text-[#262626]"
                        type="button"
                        onClick={backToWebTop}
                    >
                        キャンセル
                    </button>
                    <button
                        className="modal-create-user-button2 d-flex flex-row w-full"
                        type="submit"
                    >
                        保存
                    </button>
                </div>
            </form>
        </SPCustomerInfoFormStyles>
    );
};

export default SPCustomerInfoForm;
