import {
    Button,
    Drawer,
    Upload,
    UploadProps,
    message,
    Spin,
    Input,
    ConfigProvider,
    Modal,
} from 'antd';
import { forwardRef, useState, useRef } from 'react';
import jaJP from 'antd/es/locale/ja_JP';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { CloseOutlined } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { SelectSearchOption } from 'react-select-search';
import { QuoteStatusEnum } from 'utils/enums';
import styled from 'styled-components';
import moment from 'moment';
import { InvoicePDFStyle } from '../invoicePdf/styled';
import LogoTextBorder from 'assets/logo-text-border.svg';
import { usePDF } from 'react-to-pdf';
import UploadWhite from 'assets/icons/upload-white.svg';
import QuoteTitle from 'assets/icons/quote-title.png';
import { IInvoice, IInvoiceChildren, IInvoiceDetail } from 'models';
import { formatCurrency } from 'utils/helper/currency';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import invoiceApi from 'services/invoiceApi';
import { alertError, alertSuccessPopup } from 'utils/helper/appHelper';
import { useModal } from 'react-hooks-use-modal';
import ExclamationIcon from 'assets/icons/exclamation.svg';
import UploadGrey from 'assets/icons/upload-grey.svg'
import querystring from 'query-string'

const digit = /[0-9０-９]/;
const mask = [digit, digit, digit, '-', digit, digit, digit, digit];
const statusOptions = Object.values(QuoteStatusEnum).map((item) => ({
    value: item.value,
    name: item.title,
}));
const typeOptions: SelectSearchOption[] = [
    { value: 'interior', name: '内装' },
    { value: 'exterior', name: '外装' },
    { value: 'water_area', name: '水回り' },
    { value: 'other', name: 'その他' },
];

export interface InvoiceQuotePdf {
    view: (id: number) => void;
}

const ModalQuoteCustom = styled(Drawer)`
    .ant-drawer-body {
        padding: 15px !important;
    }
    .ant-drawer-right > .ant-drawer-content-wrapper {
        margin: auto !important;
        width: 50% !important;
    }
`;

const ModalQuoteCustoms = styled(Modal)`
    .ant-modal-header {
        padding: 0px !important;
    }
    .ant-modal-content {
        min-height: 700px !important;
    }
`;

const UploadCustom = styled(Upload) <{ $isFull?: boolean }>`
    .ant-upload-list-picture-card-container:nth-child(
            ${(props) => (props.$isFull ? '3n' : '3n + 2')}
        ) {
        margin-right: 0px;
    }
    .ant-upload-list-picture-card .ant-upload-list-item {
        border: none;
        padding: 0px;
    }
    .ant-upload-list-picture-card .ant-upload-list-item-info {
        border-radius: 6.55px;
        border: 1px solid #dcdcdc;
    }
    .ant-upload-list {
        width: 332px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
    }
    .ant-upload-select-picture-card {
        order: -1;
    }
`;

interface IProps {
    invoice: IInvoice;
    quoteDetail: any;
    disableQuote: boolean;
    totalItemPrice: number[]
    getDetail: () => void
}
const InvoiceQuotePdf = forwardRef<InvoiceQuotePdf, IProps>((props, ref) => {
    const { invoice, quoteDetail, totalItemPrice, getDetail, disableQuote } = props;
    const {
        control,
        setValue,
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
        watch,
        getValues,
        setError,
    } = useForm();
    const [searchParams, setSearchParams] = useSearchParams();
    const [uploadLoading, setUploadLoading] = useState(false)
    const [exportLoading, setExportLoading] = useState(false)
    const location = useLocation()
    const queryObj = querystring.parse(location.search)
    const id = searchParams.get('id');
    const viewPdf = searchParams.get('viewQuotePdf'); // determine wether that we can edit or view-only
    const navigate = useNavigate();
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const [ModalConfirm, openConfirm, closeConfirm, isOpenConfirm] = useModal(
        'root',
        {
            preventScroll: true,
            // closeOnOverlayClick: false,
        },
    );
    const [isLoading, setIsLoading] = useState(false);
    const { toPDF, targetRef } = usePDF(
        {
            filename: 'quote.pdf',
            page: {
                margin: 10,
                format: 'letter',
                orientation: 'landscape',
            },
            overrides: {
                // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
                pdf: {
                    compress: true
                },
                // see https://html2canvas.hertzen.com/configuration for more options
                canvas: {
                    useCORS: true
                }
            },
        });

    const handleClose = () => {
        clearErrors();
        navigate(`/quote/list/invoice${queryObj?.quote_id ? `?quote_id=${queryObj?.quote_id}&` : '?'}` +
            `${queryObj?.invoice_id ? `invoice_id=${queryObj?.invoice_id}&` : ''}${queryObj?.prev ? `prev=${queryObj?.prev}` : ''}`);
    };

    const handleExport = async (type?: string) => {
        if (type === 'export') {
            setExportLoading(true)
        } else {
            setUploadLoading(true)
        }
        const element: any = targetRef.current;
        html2canvas(element, { useCORS: true, allowTaint: true, scrollY: 0 }).then((canvas) => {
            const image = { type: 'jpeg', quality: 0.98 };
            const margin = [0.5, 0.5];

            let imgWidth = 8.5;
            let pageHeight = 11;

            let innerPageWidth = imgWidth - margin[0] * 2;
            let innerPageHeight = pageHeight - margin[1] * 2;

            // Calculate the number of pages.
            let pxFullHeight = canvas.height;
            let pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
            let nPages = Math.ceil(pxFullHeight / pxPageHeight);

            // Define pageHeight separately so it can be trimmed on the final page.
            let pageHeight2 = innerPageHeight;

            // Create a one-page canvas to split up the full image.
            let pageCanvas = document.createElement('canvas');
            let pageCtx: any = pageCanvas.getContext('2d');
            pageCanvas.width = canvas.width;
            pageCanvas.height = pxPageHeight;

            // Initialize the PDF.
            let pdf = new jsPDF('p', 'in', [8.5, 11]);

            for (let page = 0; page < nPages; page++) {
                // Trim the final page to reduce file size.
                if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
                    pageCanvas.height = pxFullHeight % pxPageHeight;
                    pageHeight2 = (pageCanvas.height * innerPageWidth) / pageCanvas.width;
                }

                // Display the page.
                let w = pageCanvas.width;
                let h = pageCanvas.height;
                pageCtx.fillStyle = 'white';
                pageCtx.fillRect(0, 0, w, h);
                pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);

                // Add the page to the PDF.
                if (page > 0) pdf.addPage();
                let imgData = pageCanvas.toDataURL('image/' + image.type, image.quality);
                pdf.addImage(imgData, image.type, margin[1], margin[0], innerPageWidth, pageHeight2);
            }

            if (type === 'export') {
                pdf.save(`${quoteDetail?.project_title ? quoteDetail?.project_title : invoice?.project_title}.pdf`);
            } else {
                const pdfBlob = pdf.output('blob')
                const newFile = new File([pdfBlob], `${quoteDetail?.project_title ? quoteDetail?.project_title : invoice?.project_title}.pdf`)
                const is_send_message = true
                const formData = new FormData()
                formData.append('estimate_pdf', newFile)
                formData.append('is_send_message', is_send_message.toString())
                uploadFile(formData)
            }
            setExportLoading(false)
            setUploadLoading(false)
        })
        // toPDF()
    }

    const uploadFile = async (formData: FormData) => {
        try {
            const res = await invoiceApi.uploadFileQuotePdf(user?.access_token, formData, invoice?.id)
            if (res) {
                alertSuccessPopup({
                    title: '保存してLINEで送信しました',
                    confirmButtonText: '閉じる',
                });
                closeConfirm()
                handleClose()
                getDetail()
            } else {
                alertError("fail")
            }
        } catch (error) {
            console.log('error', error)
            setExportLoading(false)
            setUploadLoading(false)
        }
        setExportLoading(false)
        setUploadLoading(false)
    }
    return (
        <ModalQuoteCustoms
            width="1000px"
            title={
                <div className="flex justify-end items-center py-[20px] px-[15px]">
                    <Button shape="circle" type="text" onClick={handleClose}>
                        <CloseOutlined />
                    </Button>
                </div>
            }
            closable={false}
            destroyOnClose
            footer={null}
            open={Boolean(viewPdf)}
            onCancel={handleClose}
        // headerStyle={{ background: '#215493' }}
        >
            <ConfigProvider locale={jaJP}>
                {isLoading && (
                    <div
                        style={{
                            width: '100vw',
                            height: '100vh',
                            top: 0,
                            left: 0,
                            zIndex: 9999,
                        }}
                        className="position-fixed d-flex align-items-center justify-content-center"
                    >
                        <Spin className="w-[100px] h-[100px]" />
                    </div>
                )}
                {viewPdf && (
                    <InvoicePDFStyle className="relative">
                        <div
                            className="quote-form-container h-[100%] pb-[100px] px-[10px]"
                            ref={targetRef}
                        >
                            <div className="relative mt-[30px]">
                                <div className="flex flex-row justify-center gap-[20px] ">
                                    {/* <p className='text-center font-bold text-[40px] py-[10px] w-[400px] px-[20px]'
                                    style={{ borderTop: '1px solid #000', borderBottom: '1px solid #000' }} >御見積書</p> */}
                                    <img
                                        src={QuoteTitle}
                                        alt=""
                                        height={50}
                                        width={400}
                                    />
                                </div>

                                <p
                                    className="absolute text-[14px] top-[70px]"
                                    style={{ left: 'calc(50% + 250px)' }}
                                >
                                    見積日
                                    <span className="ml-[10px]">
                                        {moment(
                                            quoteDetail?.desired_date,
                                        ).format('YYYY年MM月DD日')}{' '}
                                    </span>
                                </p>
                            </div>
                            <div className="grid grid-cols-2 w-1/2 text-[20px] mt-[15px] ml-[40px]">
                                <p>{invoice?.customer_name}</p>
                                <p>様</p>
                            </div>
                            <div className="grid grid-cols-5">
                                <div className="col-span-3 grid grid-cols-4">
                                    <div className="col-span-1" />
                                    <div className="col-span-3 text-[16px] text-[#000] mt-[20px]">
                                        いつも大変お世話になっております <br />
                                        下記の通りお見積り申し上げますので{' '}
                                        <br />
                                        何卒ご用命賜りますようにお願い申し上げます
                                    </div>
                                    <div className="col-span-4 mt-[20px] ml-[40px] text-[20px]">
                                        <p>
                                            件名{' '}
                                            <span className="ml-[8px]">
                                                {quoteDetail?.project_title ? quoteDetail?.project_title : invoice?.project_title}
                                            </span>
                                        </p>
                                    </div>

                                    <div
                                        style={{
                                            borderBottom: '4px solid #000',
                                        }}
                                        className="col-span-4 flex flex-row ml-[20px] text-[20px] mt-[80px] gap-[20px]"
                                    >
                                        <p className="w-[30%] whitespace-nowrap ml-[20px]">
                                            御見積合計金額
                                        </p>
                                        <p className="w-[30%] whitespace-nowrap">
                                            {invoice?.total_estimate_before_tax?.toLocaleString('en-US')}
                                        </p>
                                        <p className="w-[26%] whitespace-nowrap text-right">
                                            {invoice?.total_estimate_after_tax?.toLocaleString('en-US')}–
                                        </p>
                                        <p className="w-[14%] whitespace-nowrap text-right">
                                            (税込)
                                        </p>
                                    </div>
                                </div>
                                <div className="pl-[50px] col-span-2">
                                    <p className="mb-[0px]">一般建設業許可</p>
                                    <p className="mb-[0px]">
                                        愛知県知事　許可　（般ー31)　第68510号
                                    </p>
                                    <img
                                        src={LogoTextBorder}
                                        alt=""
                                        height={50}
                                        width={300}
                                        className="mt-[10px]"
                                    />
                                    <p className="mb-[0px] ml-[28px]">
                                        〒4702102
                                    </p>
                                    <p className="mb-[0px] text-[13px] ml-[26px]">
                                        愛知県知多郡東浦町大字緒川字三ツ池1区14-80
                                    </p>
                                    <p className="mb-[0px] ml-[28px]">
                                        TEL0562-85-3650 FAX0562-85-3670
                                    </p>
                                </div>
                            </div>

                            <table className="mt-[30px] mx-[20px] border-[3px] ">
                                <tr className="border">
                                    <th className="w-[30%] py-[10px]">
                                        <div>
                                            <span>名</span>
                                            <span className="ml-[30px]">
                                                称
                                            </span>
                                        </div>
                                    </th>
                                    <th className="w-[5%] py-[10px]">単位</th>
                                    <th className="w-[10%] py-[10px]">
                                        <div className="flex flex-row justify-between px-[15%]">
                                            <span>数</span>
                                            <span>量</span>
                                        </div>
                                    </th>
                                    <th className="w-[10%] py-[10px]">
                                        <div className="flex flex-row justify-between px-[15%]">
                                            <span>単</span>
                                            <span> 価</span>
                                        </div>
                                    </th>
                                    <th className="w-[10%] py-[10px]">
                                        <div className="flex flex-row justify-between px-[15%]">
                                            <span>金</span>
                                            <span>額</span>
                                        </div>
                                    </th>
                                    <th className="w-[10%] py-[10px]">
                                        <div className="flex flex-row justify-between px-[15%]">
                                            <span>備</span>
                                            <span>考</span>
                                        </div>
                                    </th>
                                </tr>
                                {invoice?.invoice_details &&
                                    invoice?.invoice_details.map(
                                        (item: IInvoiceDetail, index) => (
                                            <>
                                                <tr className="!w-[100%]">
                                                    <td className="w-[30%]">
                                                        {item?.content}
                                                    </td>
                                                    <td className="w-[5%]"></td>
                                                    <td className="w-[10%] text-right px-[10px]"></td>
                                                    <td className="w-[10%] text-right px-[10px]"></td>
                                                    <td className="w-[10%] text-right px-[10px]"></td>
                                                    <td className="w-[10%] text-right px-[10px]"></td>
                                                </tr>
                                                {item?.children &&
                                                    item?.children?.map(
                                                        (
                                                            item: IInvoiceChildren,
                                                            indChild,
                                                        ) => (
                                                            <tr
                                                                className="!w-[100%]"
                                                                key={indChild}
                                                            >
                                                                <td className="w-[30%]">
                                                                    {
                                                                        item?.content
                                                                    }
                                                                </td>
                                                                <td className="w-[5%] text-center">
                                                                    {
                                                                        item?.unit_estimate
                                                                    }
                                                                </td>
                                                                <td className="w-[10%] text-right px-[10px]">
                                                                    {item?.quantity_estimate?.toLocaleString(
                                                                        'en-US',
                                                                    )}
                                                                </td>
                                                                <td className="w-[10%] text-right px-[10px]">
                                                                    {item?.price_estimate?.toLocaleString('en-US')}
                                                                </td>
                                                                <td className="w-[10%] text-right px-[10px]">
                                                                    {(
                                                                        item?.price_estimate *
                                                                        item?.quantity_estimate
                                                                    )?.toLocaleString('en-US')}
                                                                </td>
                                                                <td className="w-[10%] text-right px-[10px]"></td>
                                                            </tr>
                                                        ),
                                                    )}
                                                <tr className='!w-[100%]' >
                                                    <td className='w-[30%]'></td>
                                                    <td className='w-[5%]'></td>
                                                    <td className='w-[10%] text-right px-[10px]'></td>
                                                    <td className='w-[10%] text-right px-[10px]'></td>
                                                    <td className='w-[10%] text-right px-[10px]'>{totalItemPrice[index]?.toLocaleString('en-US')}</td>
                                                    <td className='w-[10%] text-right px-[10px]'></td>
                                                </tr>
                                            </>
                                        ),
                                    )}
                                <tr>
                                    <td className="w-[30%]">
                                        {'<税抜合計金額>'}
                                    </td>
                                    <td className="w-[5%]"></td>
                                    <td className="w-[10%] text-right px-[10px]"></td>
                                    <td className="w-[10%] text-right px-[10px]"></td>
                                    <td className="w-[10%] text-right pr-[10px]">
                                        {invoice?.total_estimate_before_tax?.toLocaleString('en-US')}
                                    </td>
                                    <td className="w-[10%] text-right px-[10px]"></td>
                                </tr>
                                <tr>
                                    <td className="w-[30%]">{'<消費税>'}</td>
                                    <td className="w-[5%]"></td>
                                    <td className="w-[10%] text-right px-[10px]"></td>
                                    <td className="w-[10%] text-right px-[10px]"></td>
                                    <td className="w-[11%] text-right pr-[10px]">
                                        {((invoice?.total_estimate_before_tax *
                                            10) /
                                            100)?.toLocaleString('en-US')}
                                    </td>
                                    <td className="w-[11%] text-right px-[10px]"></td>
                                </tr>
                            </table>
                            <table
                                className="mx-[20px]"
                                style={{
                                    borderBottom: '3px solid',
                                    borderLeft: '3px solid',
                                    borderRight: '3px solid',
                                }}
                            >
                                <tr className="h-[100px]">
                                    <td className="w-[57.3%] h-[100px] !border-t-0"></td>
                                    <td className="w-[12.7%] h-[100px] text-center !border-t-0">
                                        合計
                                    </td>
                                    <td className="w-[14%] h-[100px] text-right !border-t-0 pr-[10px]">
                                        {invoice?.total_estimate_after_tax?.toLocaleString('en-US')}
                                    </td>
                                    <td className="w-[14%] h-[100px] text-center !border-t-0"></td>
                                </tr>
                            </table>
                        </div>
                        <div className='flex flex-row absolute bottom-0 left-[calc(50%-230px)] gap-[20px]' >
                            <button
                                className="modal-create-user-button2 !rounded-[2xp] !text-[#000000D9] right-auto d-flex flex-row w-[140px]"
                                style={{
                                    width: '140px', borderRadius: '0px',
                                    background: '#fff', border: '1px solid #D9D9D9'
                                }}
                                type="button"
                                disabled={uploadLoading || exportLoading}
                                onClick={handleClose}
                            >
                                キャンセル
                            </button>
                            <button
                                className="modal-create-user-button2 !rounded-[2xp] !whitespace-nowrap right-auto d-flex flex-row w-[140px]"
                                style={{
                                    width: '140px', borderRadius: '0px',
                                    background: '#215493', border: '1px solid #D9D9D9'
                                }}
                                type="button"
                                disabled={uploadLoading || exportLoading}
                                onClick={() => handleExport('export')}
                            >
                                {exportLoading && <Spin />}<img src={UploadWhite} alt='' />ダウンロード
                            </button>

                            <button
                                className="modal-create-user-button2 !rounded-[2xp] right-auto d-flex flex-row w-[140px]"
                                style={{
                                    width: '140px', borderRadius: '0px',
                                    // background: '#02A509',
                                    background: `${(disableQuote || invoice?.quote?.created_by === '管理者') ? '#F5F5F5' : '#02A509'}`,
                                    color: `${(disableQuote || invoice?.quote?.created_by === '管理者') ? '#8C8C8C' : '#fff'}`,
                                    border: '1px solid #CCCCCC'
                                }}
                                type="button"
                                disabled={uploadLoading || exportLoading || disableQuote || invoice?.quote?.created_by === '管理者'}
                                onClick={openConfirm}
                            >
                                {uploadLoading && <Spin />} LINE送信
                            </button>
                        </div>


                        <ModalConfirm>
                            <div
                                className="d-flex flex-column justify-center !bg-[#ffffff] rounded-[10px] px-[20px]"
                                style={{ width: '449px', height: '285px', zIndex: '9999999999999999' }}
                            >
                                <div className="flex justify-end mt-[10px] cursor-pointer">
                                    <CloseOutlined onClick={closeConfirm} />
                                </div>
                                <div className="flex flex-col justify-center items-center pb-[10px]">
                                    <img
                                        src={ExclamationIcon}
                                        className="w-[80px] h-[80px]"
                                        alt="exclamation icon"
                                    />
                                    <div className="text-[#3A3B3C] font-normal text-center text-[13px] pt-[15px]">
                                        データをもう一度確認してから送信してください。
                                        <br />
                                        このデータを送信してもよろしいですか？
                                    </div>
                                    <div
                                        className="flex gap-[10px]"
                                        style={{ marginTop: '40px' }}
                                    >
                                        <button
                                            className="modal-create-user-button1 d-flex flex-row !w-[147px] !h-[36px] !rounded-[2px]"
                                            onClick={closeConfirm}
                                        >
                                            キャンセル
                                        </button>
                                        <button onClick={() => handleExport('upload')}
                                            disabled={uploadLoading || exportLoading}
                                            className="modal-create-user-button2 d-flex flex-row !w-[147px] !h-[36px] !rounded-[2px]">
                                            {uploadLoading && <Spin />}
                                            送信する
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </ModalConfirm>
                    </InvoicePDFStyle>
                )}
            </ConfigProvider>
        </ModalQuoteCustoms>
    );
});
export default InvoiceQuotePdf;
