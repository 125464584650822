export const formatMoney = (num: string) => {
    num = String(num);

    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export function ObjectToFormData(object: any) {
    const formData = new FormData();
    Object.keys(object).forEach((key) => {
        formData.append(key, object[key]);
    });
    return formData;
}

export const convertNestedObjToSearchParamsArray = (nestedObj: any) => {
    const getPairs = (obj: any, keys: any[] = []): any[] =>
        Object.entries(obj).reduce((pairs: any[], [key, value]: [string, any]) => {
            if (typeof value === 'object')
                pairs.push(...getPairs(value, [...keys, key]));
            else pairs.push([[...keys, key], value]);
            return pairs;
        }, []);

    return getPairs(nestedObj)
        .map(
            ([[key0, ...keysRest], value]: [[string, ...string[]], any]) =>
                `${key0}${keysRest
                    .map(a => `[${a && (Number(a) || !a || String(a) === '0') ? '' : a}]`)
                    .join('')}=${value}`,
        )
        .join('&');
};

export const uniqBy = (arr, predicate) => {
    const cb = typeof predicate === 'function' ? predicate : (o) => o[predicate];

    return [...arr.reduce((map, item) => {
        const key = (item === null || item === undefined) ?
            item : cb(item);

        map.has(key) || map.set(key, item);

        return map;
    }, new Map()).values()];
}
//   Array.from(new Set(arr.map(JSON.stringify))).map(JSON.parse);
