import { FC } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import { ListItemIcon, MenuItem, Stack } from '@mui/material';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import DatePickerField from '../FormField/DatePicker';
import DateTimePickerField from '../FormField/DateTimePicker';
import useCreateScheduleDialog, {
    Props,
    ReceivedProps,
    SCHEDULER_COLORS,
} from './hook';
import CreateScheduleWrapper from './style';
import initStatusIcon from '../../../assets/icons/status/ic_init_fill.svg';
import progressStatusIcon from '../../../assets/icons/status/ic_progress_fill.svg';
import finishStatusIcon from '../../../assets/icons/status/ic_finish_fill.svg';
import { DatePicker, Select, TimePicker } from 'antd';
import zIndex from '@mui/material/styles/zIndex';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import createProjectValidation from 'utils/validation/registers/projects/createProjectValidation';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        zIndex: 100
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
        zIndex: 100
    },
    zIndex: 100
}));

const checkBoxStyles = {
    checkmark: {
        background: '#3DDD74',
        color: 'white',
    },
    checkbox: {
        background: '#3DDD74',
        borderColor: '#3DDD74',
        width: '15px',
        margin: '0 12px 0 0',
        height: '15px',
    },
};

const CreateScheduleDialogLayout: FC<Props> = ({
    open,
    formik,
    editingId,
    workerOptions,
    setCurrentItemId,
    setOpenDeleteConfirmationDialog,
    setOpen,
    control,
    setValue,
    register,
    handleSubmit,
    errors,
    clearErrors,
    watch,
    getValues,
    setError,
    handleValidateWhitespace,
    quoteOptions,
    submitData,
    timeError,
    setTimeError,
    detailId
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <BootstrapDialog
            className="customBootstrapDialog scheduleFormDialog"
            onClose={() => setOpen(false)}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <CreateScheduleWrapper>
                <DialogContent>
                    <div
                        className="description"
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        {detailId
                            ? 'スケジュール編集'
                            : 'スケジュール新規作成'}
                    </div>
                </DialogContent>

                <form onSubmit={handleSubmit(submitData)} className="filterBox">
                    <div className="filterBoxTop">
                        <div className="filterBoxCol">
                            <span className="text-[14px]">
                                <span className="text-red mr-1">
                                    *
                                </span>
                                社員名</span>
                            <div className="w-full">
                                <Controller
                                    name="staff_id"
                                    rules={{ required: true }}
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column mt-[15px]">
                                            <Select
                                                options={workerOptions}
                                                showSearch
                                                showArrow
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                {...register(
                                                    'staff_id',
                                                    createProjectValidation(
                                                        t,
                                                    ).name(),
                                                )}
                                                {...field}
                                                style={{ height: '40px !important', width: '100%' }}
                                                placeholder=""
                                            />
                                            {errors.staff_id && (
                                                <span className="error tracking-tight mt-1">
                                                    {errors.staff_id.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                            </div>
                        </div>

                        <div className="filterBoxCol">
                            <div className="wrapTextField">
                                <Controller
                                    name="title"
                                    rules={{ required: true }}
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column mt-[15px]">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">
                                                    *
                                                </span>
                                                タイトル
                                            </p>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="textBox rounded-[2px] mt-[10px] border-[1px] h-[40px] border-[#D9D9D9] px-2"
                                                {...register(
                                                    'title',
                                                    createProjectValidation(
                                                        t,
                                                    ).title(),
                                                )}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                            ></input>
                                            {errors.title && (
                                                <span className="error tracking-tight mt-1">
                                                    {errors.title.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                        </div>

                        <div className="filterBoxCol">
                            <Controller
                                name="content"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => (
                                    <div className="d-flex flex-column mt-[15px]">
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            <span className="text-red mr-1">
                                                *
                                            </span>
                                            内容
                                        </p>
                                        <textarea
                                            autoComplete="off"
                                            className="rounded-[2px] mt-[10px] border-[1px] border-[#D9D9D9] p-[5px]"
                                            rows={4}
                                            {...register(
                                                'content',
                                                createProjectValidation(
                                                    t,
                                                ).content(),
                                            )}
                                            onBlur={
                                                handleValidateWhitespace
                                            }
                                            maxLength={200}
                                        ></textarea>
                                        {errors.content && (
                                            <span className="error">
                                                {errors.content.message?.toString()}
                                            </span>
                                        )}
                                    </div>
                                )}
                            />
                        </div>

                        <div className="filterBoxCol mt-[15px]">
                            <span className="text-[14px]">
                                <span className="text-red mr-1">
                                    *
                                </span>
                                案件名</span>
                            <div className="w-full">
                                <Controller
                                    name="quote_id"
                                    rules={{ required: true }}
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column mt-[15px]">
                                            <Select
                                                options={quoteOptions}
                                                showSearch
                                                showArrow
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                {...register(
                                                    'quote_id',
                                                    createProjectValidation(
                                                        t,
                                                    ).quote(),
                                                )}
                                                {...field}
                                                style={{ height: '40px !important', width: '100%' }}
                                                placeholder=""
                                            />
                                            {errors.quote_id && (
                                                <span className="error tracking-tight mt-1">
                                                    {errors.quote_id.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />


                            </div>
                        </div>

                        {/* <div className="filterBoxCol mt-[15px]">
                            <span className="text-[14px]">
                                <FormControl fullWidth>
                                    <RadioGroup
                                        row
                                        name="is_all_day"
                                        onChange={() => {
                                            formik.setFieldValue(
                                                'is_all_day',
                                                false,
                                            );
                                        }}
                                    >
                                        <FormControlLabel
                                            value={false}
                                            control={
                                                <Radio
                                                    checked={
                                                        !formik.values
                                                            .is_all_day
                                                    }
                                                />
                                            }
                                            label="日時（期間）"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </span>
                            <div
                                className={classNames(
                                    'inlineField',
                                    !formik.values.is_all_day
                                        ? ''
                                        : 'hiddenValue',
                                )}
                                style={{ marginTop: '10px' }}
                            >
                                <Controller
                                    name="start_time"
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex  flex-column w-full">
                                            <TimePicker
                                                placeholder="日付選択"
                                                {...register(
                                                    'start_time',
                                                    // quoteValidation(
                                                    //     t,
                                                    // )?.start_time(),
                                                )}
                                                {...field}
                                                onChange={(evt) => {
                                                    field.onChange(evt)
                                                    setTimeError('')
                                                }}
                                                showHour
                                                showMinute
                                                showSecond={false}
                                                format={'h:mm a'}
                                                style={{ height: '40px', background: '#fff', color: '#000' }}
                                                className="py-0"
                                            />
                                        </div>
                                    )}
                                />
                                <div className="divider">~</div>
                                <Controller
                                    name="end_time"
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column w-full">
                                            <TimePicker
                                                placeholder="日付選択"
                                                {...register(
                                                    'end_time',
                                                    // quoteValidation(
                                                    //     t,
                                                    // )?.end_time(),
                                                )}
                                                {...field}
                                                onChange={(evt) => {
                                                    field.onChange(evt)
                                                    setTimeError('')
                                                }}
                                                showHour
                                                showMinute
                                                showSecond={false}
                                                format={'h:mm a'}
                                                style={{ height: '40px', background: '#fff', color: '#000' }}
                                                className="py-0"
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                        </div> */}
                        {!formik?.values.is_all_day && timeError === '日時を入力してください。' && (
                            <span className="error">
                                {timeError}
                            </span>
                        )}
                        <div className="filterBoxCol mt-[15px]">
                            {/* <span className="text-[14px]">
                                <FormControl fullWidth>
                                    <RadioGroup
                                        row
                                        name="is_all_day"
                                        onChange={() => {
                                            formik.setFieldValue(
                                                'is_all_day',
                                                true,
                                            );
                                        }}
                                    >
                                        <FormControlLabel
                                            value={false}
                                            control={
                                                <Radio
                                                    checked={
                                                        formik.values.is_all_day
                                                    }
                                                />
                                            }
                                            label="終日"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </span> */}
                            <span className="text-[14px]">
                                <span className="text-red mr-1">
                                    *
                                </span>
                                期間</span>
                            <div
                                className={classNames(
                                    'inlineField',
                                    formik.values.is_all_day
                                        ? ''
                                        : 'hiddenValue',
                                )}
                                style={{ marginTop: '10px' }}
                            >

                                <Controller
                                    name="start_date"
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column w-full">
                                            <DatePicker
                                                placeholder="日付選択"
                                                {...register(
                                                    'start_date',
                                                    createProjectValidation(
                                                        t,
                                                    )?.start_date(),
                                                )}
                                                {...field}
                                                onChange={(evt) => {
                                                    field.onChange(evt)
                                                    setTimeError('')
                                                }}
                                                format={'YYYY年MM月DD日'}
                                                style={{ height: '40px', background: '#fff', color: '#000' }}
                                                className="py-0"
                                            />
                                        </div>
                                    )}
                                />

                                <div className="divider">~</div>
                                <Controller
                                    name="end_date"
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column w-full">
                                            <DatePicker
                                                placeholder="日付選択"
                                                {...register(
                                                    'end_date',
                                                    createProjectValidation(
                                                        t,
                                                    )?.end_date(),
                                                )}
                                                {...field}
                                                onChange={(evt) => {
                                                    field.onChange(evt)
                                                    setTimeError('')
                                                }}
                                                format={'YYYY年MM月DD日'}
                                                style={{ height: '40px', background: '#fff', color: '#000' }}
                                                className="py-0"
                                            />


                                        </div>
                                    )}
                                />
                                {/* <div className="wrapTextField">
                                    <DatePickerField
                                        name="end_date"
                                        placeholder=""
                                        locale="ja"
                                        formik={formik}
                                    />
                                </div> */}
                            </div>
                            {errors.start_date && (
                                <span className="error">
                                    {errors.start_date.message?.toString()}
                                </span>
                            )}
                            {!errors.start_date && errors.end_date && (
                                <span className="error">
                                    {errors.end_date.message?.toString()}
                                </span>
                            )}
                        </div>
                        {formik?.values.is_all_day && timeError === '終日を入力してください。' && (
                            <span className="error">
                                {timeError}
                            </span>
                        )}
                        <div className="filterBoxCol mt-[15px]">
                            {/* <span className="text-[14px]">
                                場所（営業所、住所など）
                            </span> */}
                            <Controller
                                name="location"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => (
                                    <div className="d-flex flex-column">
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            <span className="text-red mr-1">
                                                *
                                            </span>
                                            場所（営業所、住所など）
                                        </p>
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            className="textBox rounded-[2px] mt-[10px] border-[1px] h-[40px] border-[#D9D9D9] px-2"
                                            {...register(
                                                'location',
                                                createProjectValidation(
                                                    t,
                                                ).location(),
                                            )}
                                            onBlur={
                                                handleValidateWhitespace
                                            }
                                        ></input>
                                        {errors.location && (
                                            <span className="error tracking-tight mt-1">
                                                {errors.location.message?.toString()}
                                            </span>
                                        )}
                                    </div>
                                )}
                            />
                        </div>
                        <div className="filterBoxCol mt-[15px]">
                            <span className="text-[14px]">色</span>
                            <div className="wrapColorBox mt-[10px]">
                                {SCHEDULER_COLORS.map((item, index) => (
                                    <button
                                        key={index}
                                        type="button"
                                        onClick={() => {
                                            formik.setFieldValue('color', item);
                                        }}
                                        className={classNames(
                                            formik.values.color === item
                                                ? 'active'
                                                : '',
                                        )}
                                        style={{
                                            backgroundColor: item,
                                        }}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>

                    <DialogActions className="modal-create-plan-buttons d-flex flex-row">
                        <button
                            className="modal-create-plan-button1 d-flex flex-row !rounded-sm"
                            onClick={() => {
                                setOpen(false);
                                // navigate(`/calendar`, {
                                //     state: {
                                //         editingId: null,
                                //     },
                                // });
                            }}
                        >
                            キャンセル
                        </button>
                        <button
                            className="modal-create-plan-button2 d-flex flex-row !rounded-sm"
                            type="submit"
                        >
                            {detailId ? '保存' : '登録'}
                        </button>
                        {detailId && (
                            <button
                                type="button"
                                className="modal-create-user-button2 d-flex flex-row !rounded-sm"
                                style={{
                                    background: '#FF5045',
                                }}
                                onClick={() => {
                                    setCurrentItemId(detailId);
                                    setOpenDeleteConfirmationDialog(true);
                                }}
                            >
                                削除
                            </button>
                        )}
                    </DialogActions>
                </form>
            </CreateScheduleWrapper>
        </BootstrapDialog>
    );
};

const CreateScheduleDialog: FC<ReceivedProps> = (props) => (
    <CreateScheduleDialogLayout {...useCreateScheduleDialog(props)} />
);

export default CreateScheduleDialog;
