import { Dispatch, FC, SetStateAction } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import TabWrapper from './style';

type IProps = {
    activeTab: number;
    setActiveTab: Dispatch<SetStateAction<number>>;
};

const TabBox: FC<IProps> = ({ activeTab, setActiveTab }) => {
    const { userData } = useSelector((state: RootState) => state.users);
    const TAB_DATA = [
        {
            title: '案件',
            id: 0,
        },
        {
            title: '社員',
            id: 1,
        },
    ]
    return (
        <TabWrapper>
            {TAB_DATA.map((item, index) => (
                <button
                    className={classNames(activeTab === item.id ? 'active' : '')}
                    key={item.id}
                    onClick={() => setActiveTab(item.id)}
                    type="button"
                >
                    {item.title}
                </button>
            ))}
        </TabWrapper>
    );
};

export default TabBox;
