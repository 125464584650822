import fetch from './api/config';

const API = {
    GET_LIST_CUSTOMER: () => `/customers`,
    GET_DETAIL_CUSTOMER: (id: number | string) => `/customers/${id}`,
};

export default class CustomerService {
    static getListCustomer = (params: any) =>
        fetch.get(API.GET_LIST_CUSTOMER(), params);

    static deleteCustomer = (id: number | string) =>
        fetch.delete(API.GET_DETAIL_CUSTOMER(id), {});

    static createCustomer = (params: any) =>
        fetch.post(API.GET_LIST_CUSTOMER(), params);

    static updateCustomer = (id: number | string, params: any) =>
        fetch.put(API.GET_DETAIL_CUSTOMER(id), params);

    static getCustomerDetail = (id: number | string) =>
        fetch.get(API.GET_DETAIL_CUSTOMER(id), {});

}
