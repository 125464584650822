import fetch from './api/config';

const API = {
    GET_DIRECT_CHATS: () => `/direct_chats`,
    GET_DIRECT_MESSAGEs: (id) => `/direct_chats/${id}/messages`,
    CREATE_DIRECT_MESSAGE: () => `/direct_chats/messages`,
    CHECK_HAS_UNREAD_MESSAGE: () => `/direct_chats/has_unread_message`,
    GET_DIRECT_CHAT_DETAIL: (id) => `/direct_chats/${id}`,
};

export default class PrivateMessageService {
    static getDirectChats = (params: any) => {
        // fetch.get(API.GET_DIRECT_CHATS(), params);
    }


    static getDirectMessages = (id: any, params) => {
        // fetch.get(API.GET_DIRECT_MESSAGEs(id), params);

    }

    static getDirectMessageDetail = (id: any) => {
        // fetch.get(API.GET_DIRECT_CHAT_DETAIL(id), {});
    }
    static checkHasUnreadMessage = () => {
        // fetch.get(API.CHECK_HAS_UNREAD_MESSAGE(), {});
    }

    static createDirectChats = (params: any) => {
        // fetch.post(API.CREATE_DIRECT_MESSAGE(), params);
    }
}
