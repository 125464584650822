import { ModalProps, Form, Input, Button } from 'antd';
import StyledModal from 'components/Modal';
import { MAX_LENGTH } from 'pages/project/projectPlan/projectTabs/constant';
import { useEffect } from 'react';
import { PatternFormat } from 'react-number-format';
import customerService from 'services/customerService';
import { alertError, alertSuccess } from 'utils/helper/appHelper';

interface I_Props extends ModalProps {
    id?: number | string;
    onClose: () => void;
    onSuccess: () => void;
}

const ModalCustomer = (props: I_Props) => {
    const [form] = Form.useForm();
    const onCloseModal = () => {
        form.resetFields();
        props.onClose();
    };
    const handleTrimSpace = (fieldName: string) => {
        form.setFieldValue(
            `${fieldName}`,
            form.getFieldValue(`${fieldName}`)?.trim(),
        );
    };
    const fetchData = async () => {
        if (!props.id) return;
        try {
            const res = await customerService.getCustomerDetail(props.id);
            form.setFieldsValue((res as any)?.customer);
        } catch (error) {
            alertError(error?.response?.data?.error || '');
        }
    };
    const onAutoFillAddress = async () => {
        form.validateFields(['zipcode']).then(async ({ zipcode }) => {
            form.setFieldValue('address', '');
            const postalCode = require('japan-postal-code');
            postalCode.get(zipcode, async (address) => {
                form.setFieldValue(
                    'address',
                    `${address?.prefecture}${address?.city}${address?.area}`,
                );
                form.validateFields(['address', 'zipcode']);
            });
        });
        setTimeout(() => {
            if (!form.getFieldsValue()?.address) {
                form.setFields([
                    { name: 'zipcode', errors: ['郵便番号が存在しません'] },
                ]);
            }
        }, 300);
    };
    const handleSubmit = async (values) => {
        try {
            const res = props.id
                ? await customerService.updateCustomer(props.id, values)
                : await customerService.createCustomer(values);
            if (res) {
                alertSuccess('顧客の更新に成功しました。');
                onCloseModal();
                props.onSuccess();
            }
        } catch (error) {
            alertError(error?.response?.data?.error || '');
        }
    };
    useEffect(() => {
        if (props.id) {
            fetchData();
        }
    }, [props.id]);
    return (
        <StyledModal
            title={props.id ? "情報編集" : "新規作成"}
            {...props}
            onCancel={onCloseModal}
            okButtonProps={{
                style: { background: '#215493' },
            }}
            okText={!props.id ? '登録' : '保存'}
            onOk={form.submit}
        >
            <Form
                form={form}
                onFinish={handleSubmit}
                style={{ padding: 14 }}
                layout="vertical"
            >
                <div className="flex flex-wrap gap-x-[0.5rem]">
                    <Form.Item
                        label="姓"
                        name="first_name"
                        rules={[{ required: true, whitespace: true }]}
                        style={{ flex: '1 0 100px' }}
                    >
                        <Input
                            maxLength={MAX_LENGTH}
                            onBlur={() => handleTrimSpace('first_name')}
                        />
                    </Form.Item>
                    <Form.Item
                        label="名"
                        name="last_name"
                        rules={[{ required: true, whitespace: true }]}
                        style={{ flex: '1 0 100px' }}
                    >
                        <Input
                            maxLength={MAX_LENGTH}
                            onBlur={() => handleTrimSpace('last_name')}
                        />
                    </Form.Item>
                </div>
                <div className="flex flex-wrap gap-x-[0.5rem]">
                    <Form.Item
                        style={{ flex: '1 0 100px' }}
                        label="姓 (フリガナ)"
                        name="first_name_katakana"
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                            },
                            {
                                pattern: /^[\u30A1-\u30F4]+$/,
                                message: '入力された内容のフォーマットが間違っています。正しいフォーマットを入力してください。',
                            },
                        ]}
                    >
                        <Input
                            maxLength={MAX_LENGTH}
                            onBlur={() =>
                                handleTrimSpace('first_name_katakana')
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        style={{ flex: '1 0 100px' }}
                        label="名 (フリガナ)"
                        name="last_name_katakana"
                        rules={[
                            { required: true, whitespace: true },
                            {
                                pattern: /^[\u30A1-\u30F4]+$/,
                                message: '入力された内容のフォーマットが間違っています。正しいフォーマットを入力してください。',
                            },
                        ]}
                    >
                        <Input
                            maxLength={MAX_LENGTH}
                            onBlur={() => handleTrimSpace('last_name_katakana')}
                        />
                    </Form.Item>
                </div>
                <Form.Item
                    label="電話番号"
                    name="phone_number"
                    rules={[{ required: true, whitespace: true }]}
                >
                    <Input type='number' onBlur={() => handleTrimSpace('phone_number')} />
                </Form.Item>
                <Form.Item label="郵便番号" required>
                    <div className="flex gap-x-[0.5rem]">
                        <Form.Item
                            label="郵便番号"
                            name="zipcode"
                            noStyle
                            rules={[
                                {
                                    pattern: /\d{3}-\d{4}/,
                                    message:
                                        '郵便番号は7桁の数字で入力してください',
                                },
                                { required: true },
                            ]}
                        >
                            <PatternFormat
                                customInput={Input}
                                format="###-####"
                                mask="_"
                                placeholder="xxx-xxxx"
                            />
                        </Form.Item>
                        <Button
                            type="primary"
                            onClick={onAutoFillAddress}
                            style={{ background: '#215493', borderRadius: 8 }}
                        >
                            住所自動入力
                        </Button>
                    </div>
                </Form.Item>
                <Form.Item
                    label="住所"
                    name="address"
                    rules={[{ required: true }]}
                >
                    <Input
                        maxLength={MAX_LENGTH}
                        onBlur={() => handleTrimSpace('address')}
                    />
                </Form.Item>
            </Form>
        </StyledModal>
    );
};

export default ModalCustomer;
