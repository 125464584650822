import { UploadProps } from 'antd';
import { useState, useEffect, FocusEventHandler } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { alertError, alertSuccess, alertSuccessPopup, validateZipcode } from 'utils/helper/appHelper';
import quoteApi from 'services/quoteApi';
import moment from 'moment';
import { validateFullWhitespace } from 'utils/validation/validatorHelper';
import { ERROR_MESSAGE, TYPE_OF_SCREEN } from 'pages/project/projectPlan/projectTabs/constant';
import staffApi from 'services/staffApi';
import dayjs from 'dayjs';

export type ReceivedProps = {
  screenType: "create" | "edit" | "detail";
};
const useProjectForm = (props: ReceivedProps) => {
  const { screenType } = props;
  const {
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    watch,
    getValues,
    setError,
  } = useForm();
  const [isAllowUpload, setAllowUpload] = useState(true);
  const [fileLength, setFileLength] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [partnerOptions, setPartnerOptions] = useState([])
  const [staffOptions, setStaffOptions] = useState([])
  const id = useParams();
  const navigate = useNavigate();
  const [listFile, setListFile]: any = useState([]);
  let storage = localStorage.getItem('user');
  const user = storage !== null ? JSON.parse(storage) : null;
  const [isLoading, setIsLoading] = useState(false);
  const [totalImageSize, setTotalImageSize] = useState<number>(0);
  const [isDisable, setDisable] = useState(false)
  const dummyRequest: UploadProps["customRequest"] = async ({
    file,
    onSuccess
  }) => {
    setTimeout(() => {
      if (onSuccess) {
        onSuccess("ok");
      }
    }, 0);
  };


  const handleClose = () => {
    clearErrors();
    setTotalImageSize(0);
    navigate('/quote/list');
  };

  const submitData = async (value: any, evt: any) => {
    // evt.preventDefault()
    setIsLoading(true);
    let formData = new FormData();

    formData.append('status', value?.status);
    formData.append('content', value?.content);
    formData.append('project_title', value?.project_title);
    formData.append('cooperative_company_id', value?.cooperative_company_id)
    formData.append('desired_date', value?.period[0])
    formData.append('desired_end_date', value?.period[1])
    formData.append('staff_id', value?.worker_id);
    try {
      const res = await quoteApi.updateQuote(user?.access_token, formData, id.id);
      if (res) {
        alertSuccessPopup({
          title: '見積依頼内容の更新に成功しました。',
          confirmButtonText: 'OK',
        });

        setIsLoading(false);
        navigate(`/quote/project/${id.id}`);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getPartners = async () => {
    try {
      const res = await quoteApi.getAllPartner(user?.access_token)
      if (res) {
        const data = res?.data?.cooperative_companies
        setPartnerOptions(data?.map((item) => ({
          value: item.id,
          name: item.company_name,
          label: item.company_name
        })))
      }
    } catch (error) {
      console.log('error', error)
      alertError(ERROR_MESSAGE)
    }
  }

  const getDetail = async () => {
    setLoading(true);
    try {
      const res = await quoteApi.getQuoteDetail(user?.access_token, id.id);
      if (res) {
        const data = res.data.quote;
        setValue('id', data?.id);
        setValue('status', data?.status);
        setValue('project_title', data?.project_title);
        setValue('cooperative_company_id', data?.cooperative_company_id);
        setValue('content', data?.content);
        setValue('total_estimate_after_tax', data?.invoice?.total_estimate_after_tax);
        setValue('worker_id', data?.staff_id);
        setValue('period', [moment(data?.desired_date), data?.desired_end_date ? moment(data?.desired_end_date) : null])
        data?.desired_date &&
          setValue('dateTime', moment(data.desired_date));
      }
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  };

  const handleValidateWhitespace: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const onlyContainWhitespaces = validateFullWhitespace(e.target.value);
    if (onlyContainWhitespaces) {
      setValue(e.target.name, '');
    } else {
      setValue(e.target.name, e.target.value.trim());
    }
  };

  const getListWorkers = async (page = 1, per_page = 1000) => {
    const body = {
      page,
      per_page
    }
    try {
      const res = await staffApi.getStaffs(user?.access_token, body)
      // const response = await workersService.getListWorkers({
      //     page,
      //     per_page,
      //     ...data,
      // });
      if (res !== null && res !== undefined) {
        const data = res?.data?.staffs
        setStaffOptions(data?.map((item) => ({
          value: item.id,
          name: item.name,
          label: item.name
        })))

      }
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    if (id) {
      getDetail();
    }
  }, [id]);

  useEffect(() => {
    setValue('quote_type', 'interior');
    setValue('status', 'default');
    getPartners()
    getListWorkers()
  }, []);

  useEffect(() => {
    if (screenType === TYPE_OF_SCREEN.detail) {
      setDisable(true)
    } else {
      setDisable(false)
    }
  }, [])

  return {
    ...props,
    handleSubmit,
    submitData,
    control,
    dummyRequest,
    setValue,
    register,
    clearErrors,
    watch,
    getValues,
    setError,
    errors,
    navigate,
    fileLength,
    isAllowUpload,
    listFile,
    setFileLength,
    setAllowUpload,
    setListFile,
    totalImageSize,
    setTotalImageSize,
    handleValidateWhitespace,
    screenType,
    partnerOptions,
    isDisable,
    staffOptions
  };
};

export type Props = ReturnType<typeof useProjectForm>;

export default useProjectForm;
