import { TypeOfT } from "utils/type/translations/Translation.type";
import { emailFormat, onlyNumbers, validZipCode } from "../validator";

const partnerValidation = (t: TypeOfT) => ({
    company_name: () => ({
        required: '協力会社名は必須です。',
    }),
    post_code: () => ({
        required: '現場：郵便番号を入力してください。',
        validate: {
            regex: validZipCode('post_code', t!),
        }
    }),
    city: () => ({
        required: '市/県は必須です。',
    }),
    prefecture: () => ({
        required: '区/郡は必須です。',
    }),
    name: () => ({
        required: '氏名は必須です。',
    }),
    role: () => ({
        required: '役職は必須です。',
    }),
    email: () => ({
        required: 'メールは必須です。',
        validate: {
            emailFormat: emailFormat('email', t),
        },
    }),
    phone_number: () => ({
        required: '電話番号は必須です。',
    }),
    staff_id: () => ({
        required: '担当者を選択してください。',
    }),
    fax_number: () => ({
        validate: {
            onlyNumbers: onlyNumbers('fax_number', t),
        },
    }),

});

export default partnerValidation;
