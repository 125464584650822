import styled from 'styled-components';

export const TimeLineWrapper = styled.div`
    .google-visualization-tooltip { display: none !important; }
    .google-visualization-tooltip-item span {
    white-space: pre !important;;
text {
    font-size: 12px !important;
    transform: translate(0, -5px) !important;
  }
    rect {
    font-size: 12px !important;
    transform: translate(0, -5px) !important;
  }

  rect + text {
    font-size: 12px !important;
    transform: translate(0, -5px) !important;
  }
}
`;
