import {
    Button,
    Drawer,
    Upload,
    UploadProps,
    message,
    Spin,
    Input,
    ConfigProvider,
    Modal,
} from 'antd';
import {
    forwardRef,
    useState,
    useRef,
} from 'react';
import jaJP from 'antd/es/locale/ja_JP';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { CloseOutlined, DeleteOutlined } from '@mui/icons-material';
import { Controller, useForm } from 'react-hook-form';
import SelectSearch, { SelectSearchOption } from 'react-select-search';
import { QuoteStatusEnum } from 'utils/enums';
import styled from 'styled-components';
import { QuoteFormStyles } from 'pages/quote/form/styled';
import moment from 'moment';
import { InvoicePDFStyle } from './styled';
import Logo from 'assets/logo.svg'
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Worker } from '@react-pdf-viewer/core';
import { usePDF, Options, Margin, Resolution } from 'react-to-pdf';
import UploadWhite from 'assets/icons/upload-white.svg'
import LogoText from 'assets/logo-text.svg'
import InvoiceTitle from 'assets/icons/invoice-title.png'
import CompanyNumber from 'assets/icons/company-number.png'
import { IInvoice, IInvoiceChildren, IInvoiceDetail } from 'models';
import { formatCurrency } from 'utils/helper/currency';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import invoiceApi from 'services/invoiceApi';
import { alertError, alertSuccessPopup } from 'utils/helper/appHelper';
import { useModal } from 'react-hooks-use-modal';
import ExclamationIcon from 'assets/icons/exclamation.svg';
import UploadGrey from 'assets/icons/upload-grey.svg'
import querystring from 'query-string'

const digit = /[0-9０-９]/;
const mask = [digit, digit, digit, "-", digit, digit, digit, digit];
const statusOptions = Object.values(QuoteStatusEnum).map((item) => ({
    value: item.value,
    name: item.title,
}));
const typeOptions: SelectSearchOption[] = [
    { value: 'interior', name: '内装' },
    { value: 'exterior', name: '外装' },
    { value: 'water_area', name: '水回り' },
    { value: 'other', name: 'その他' },
];

export interface InvoicePdf {
    view: (id: number) => void;
}

const ModalQuoteCustoms = styled(Modal)`
    .ant-modal-header {
        padding: 0px !important;
    }
    .ant-modal-content {
        min-height: 700px !important;
    }
`;
interface IProps {
    invoice: IInvoice;
    quoteDetail: any;
    totalItemPrice: number[];
    disableInvoice: boolean;
    getDetail: () => void;
}
const InvoicePdf = forwardRef<InvoicePdf, IProps>((props, ref) => {
    const { invoice, quoteDetail, totalItemPrice, getDetail, disableInvoice } = props
    const {
        formState: { errors },
        clearErrors,
    } = useForm();
    const pdfExportComponent: any = useRef(null)
    const [searchParams, setSearchParams] = useSearchParams();
    const [uploadLoading, setUploadLoading] = useState(false)
    const [exportLoading, setExportLoading] = useState(false)
    const location = useLocation()
    const queryObj = querystring.parse(location.search)
    const id = searchParams.get('id');
    const viewPdf = searchParams.get('viewPdf'); // determine wether that we can edit or view-only
    const navigate = useNavigate();
    let storage = localStorage.getItem('user')
    const [isLoading, setIsLoading] = useState(false);
    const user = storage !== null ? JSON.parse(storage) : null;
    const [ModalConfirm, openConfirm, closeConfirm, isOpenConfirm] = useModal(
        'root',
        {
            preventScroll: true,
            // closeOnOverlayClick: false,
        },
    );
    const { toPDF, targetRef } = usePDF(
        {
            filename: 'invoice.pdf',
            page: {
                margin: 10,
                format: 'letter',
                orientation: 'landscape',
            },
            overrides: {
                // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
                pdf: {
                    compress: true
                },
                // see https://html2canvas.hertzen.com/configuration for more options
                canvas: {
                    useCORS: true
                }
            },
        });
    const handleClose = () => {
        clearErrors();
        navigate(`/quote/list/invoice${queryObj?.quote_id ? `?quote_id=${queryObj?.quote_id}&` : '?'}${queryObj?.invoice_id ? `invoice_id=${queryObj?.invoice_id}&` : ''}${queryObj?.prev ? `prev=${queryObj?.prev}` : ''}`);
    };
    const handleExport = async (type?: string) => {
        if (type === 'export') {
            setExportLoading(true)
        } else {
            setUploadLoading(true)
        }
        const element: any = targetRef.current;
        // const canvas = await html2canvas(element);
        // const data = canvas.toDataURL("image/png");
        // const imgWidth = 190;
        // const pageHeight = 300;
        // const imgHeight = (canvas.height * imgWidth) / canvas.width;
        // let heightLeft = imgHeight;
        // const doc = new jsPDF('p', 'mm', 'a4');
        // let position = 0;
        // doc.addImage(data, 'PNG', 10, 0, imgWidth, imgHeight + 25);
        // heightLeft -= pageHeight;
        // while (heightLeft >= 0) {
        //     position = heightLeft - imgHeight;
        //     doc.addPage();
        //     doc.addImage(data, 'PNG', 10, position, imgWidth, imgHeight + 25);
        //     heightLeft -= pageHeight;
        // }
        // const pdfBlob = doc.output('blob')

        html2canvas(element, { useCORS: true, allowTaint: true, scrollY: 0 }).then((canvas) => {
            const image = { type: 'jpeg', quality: 0.98 };
            const margin = [0.5, 0.5];
            const filename = 'myfile.pdf';

            let imgWidth = 8.5;
            let pageHeight = 11;

            let innerPageWidth = imgWidth - margin[0] * 2;
            let innerPageHeight = pageHeight - margin[1] * 2;

            // Calculate the number of pages.
            let pxFullHeight = canvas.height;
            let pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
            let nPages = Math.ceil(pxFullHeight / pxPageHeight);

            // Define pageHeight separately so it can be trimmed on the final page.
            let pageHeight2 = innerPageHeight;

            // Create a one-page canvas to split up the full image.
            let pageCanvas = document.createElement('canvas');
            let pageCtx: any = pageCanvas.getContext('2d');
            pageCanvas.width = canvas.width;
            pageCanvas.height = pxPageHeight;

            // Initialize the PDF.
            let pdf = new jsPDF('p', 'in', [8.5, 11]);

            for (let page = 0; page < nPages; page++) {
                // Trim the final page to reduce file size.
                if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
                    pageCanvas.height = pxFullHeight % pxPageHeight;
                    pageHeight2 = (pageCanvas.height * innerPageWidth) / pageCanvas.width;
                }

                // Display the page.
                let w = pageCanvas.width;
                let h = pageCanvas.height;
                pageCtx.fillStyle = 'white';
                pageCtx.fillRect(0, 0, w, h);
                pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);

                // Add the page to the PDF.
                if (page > 0) pdf.addPage();
                let imgData = pageCanvas.toDataURL('image/' + image.type, image.quality);
                pdf.addImage(imgData, image.type, margin[1], margin[0], innerPageWidth, pageHeight2);
            }

            if (type === 'export') {
                pdf.save(`${quoteDetail?.project_title ? quoteDetail?.project_title : invoice?.project_title} 請求書.pdf`);

            } else {
                const pdfBlob = pdf.output('blob')
                const newFile = new File([pdfBlob], `${quoteDetail?.project_title ? quoteDetail?.project_title : invoice?.project_title} 請求書.pdf`)
                const is_send_message = true
                const formData = new FormData()
                formData.append('invoice_pdf', newFile)
                formData.append('is_send_message', is_send_message.toString())
                uploadFile(formData)
            }
            setExportLoading(false)
            setUploadLoading(false)
        })

    }

    const uploadFile = async (formData: FormData) => {
        try {
            const res = await invoiceApi.uploadFileInvoicePdf(user?.access_token, formData, invoice?.id)
            if (res) {
                alertSuccessPopup({
                    title: '保存してLINEで送信しました',
                    confirmButtonText: '閉じる',
                });
                closeConfirm()
                handleClose()
                getDetail()
            } else {
                alertError("fail")
            }
        } catch (error) {
            console.log('error', error)
            setExportLoading(false)
            setUploadLoading(false)
        }
        setExportLoading(false)
        setUploadLoading(false)
    }

    return (
        <ModalQuoteCustoms
            width="1000px"
            title={
                <div className="flex justify-end items-center py-[20px] px-[15px]">
                    <Button shape="circle" type="text" onClick={handleClose}>
                        <CloseOutlined />
                    </Button>
                </div>
            }
            style={{ zIndex: '999' }}
            closable={false}
            destroyOnClose
            footer={null}
            open={Boolean(viewPdf)}
            onCancel={handleClose}
        // headerStyle={{ background: '#215493' }}
        >
            <ConfigProvider locale={jaJP}>
                {isLoading && (
                    <div
                        style={{
                            width: '600px',
                            height: '100vh',
                            top: 0,
                            left: 0,
                            zIndex: 99
                        }}
                        className="position-fixed d-flex align-items-center justify-content-center"
                    >
                        <Spin className='w-[100px] h-[100px]' />
                    </div>
                )}
                {viewPdf &&
                    <InvoicePDFStyle className='bg-[#fff] relative' >
                        <div className="quote-form-container h-[100%] pt-[20px] pb-[100px] px-[10px]" id="element-to-print" ref={targetRef}>
                            <div className='flex flex-row ml-[40px]' >
                                <div className='grid grid-cols-2 w-1/2' >
                                    <div className='col-span-2' >
                                        <p className='mb-0 text-[16px]' >〒{quoteDetail?.customer?.zipcode ? quoteDetail?.customer?.zipcode : invoice?.zipcode}</p>
                                        <p className='ml-[50px]' >{invoice?.customer_address}</p>
                                    </div>
                                    <p className='text-[16px]' >{invoice?.customer_name}</p>
                                    <p className='text-[16px]'>様</p>
                                </div>
                                <div className='flex flex-col items-center gap-[5px]' >
                                    {/* <p className='text-center text-[40px] font-bold py-[10px] mb-0 text-[#3A6B42] w-[400px] px-[20px]'
                                        style={{
                                            borderBottom: '2px solid #000',
                                            borderTop: '1px solid #dfd9d9',
                                            borderLeft: '1px solid #dfd9d9',
                                            borderRight: '1px solid #dfd9d9'
                                        }} >御請求書</p> */}
                                    <img src={InvoiceTitle} alt='' style={{ width: '450px', height: '85px' }} />
                                    <p className='text-center text-[12px]' >御請求日: <span className='ml-[50px]'>{moment(invoice?.payment_date).format('YYYY年MM月DD日')}</span> </p>
                                </div>


                            </div>

                            <div className='grid grid-cols-2 mt-[80px] ml-[40px]' >
                                <div className='grid grid-cols-5' >
                                    <div className='col-span-5 '>
                                        平素は格別のお引き立てを賜り厚く御礼申し上げます。 <br />
                                        下記の通り御請求申し上げます。
                                    </div>
                                    <div className='col-span-5 pl-[20px] text-[20px]'>
                                        <p>{quoteDetail?.project_title ? quoteDetail?.project_title : invoice?.project_title}</p>
                                    </div>

                                    <div style={{ borderBottom: '3px solid #000' }}
                                        className='col-span-4 flex flex-row text-[20px] pb-[16px] mt-[100px] gap-[20px]'>
                                        <p className='w-1/2 ml-[20px] mb-0' >御請求合計金額</p>
                                        <p className='w-1/2 mb-0'>{formatCurrency(invoice?.total_estimate_after_tax)}–</p>

                                    </div>
                                </div>
                                <div className='pl-[100px] mt-[-50px]'>
                                    <p className='mb-[0px]' >一般建設業許可</p>
                                    <p className='mb-[0px]'>愛知県知事　許可　（般ー31)　第68510号</p>
                                    <img src={LogoText} alt='' height={70} width={300} className='mt-[10px]' />
                                    <img src={CompanyNumber} height={30} width={140} className='ml-[40px]' />
                                    {/* <p className='ml-[20px] mt-[10px] mb-[5px] w-fit px-[5px] py-[2px]' style={{ border: '1px solid' }} >T5-180001125352</p> */}
                                    <p className='mb-[0px] ml-[16px]'>〒4702102</p>
                                    <p className='mb-[0px] ml-[38px] text-[12px]'>愛知県知多郡東浦町大字緒川字三ツ池1区14-80</p>
                                    <p className='mb-[0px] ml-[38px]'>TEL　0562-85-3650   FAX　0562-85-3670</p>
                                </div>
                            </div>

                            <table className='mt-[45px] border  mx-[20px]' >
                                <tr className='border ' >
                                    <th className='w-[30%] py-[6px]'>
                                        工事内容・商品名
                                    </th>
                                    <th className='w-[5%] py-[6px]'>
                                        単位
                                    </th>
                                    <th className='w-[10%] py-[6px] gap-[16px]'>
                                        <div className='flex flex-row justify-between px-[15%]' >
                                            <span>数</span><span>量</span>
                                        </div>
                                    </th>
                                    <th className='w-[10%] py-[6px] gap-[16px]'>
                                        <div className='flex flex-row justify-between px-[15%]' >
                                            <span>単</span><span>価</span>
                                        </div>
                                    </th>
                                    <th className='w-[10%] py-[6px] gap-[16px]'>
                                        <div className='flex flex-row justify-between px-[15%]' >
                                            <span>金</span><span>額</span>
                                        </div>
                                    </th>
                                    <th className='w-[10%] py-[6px] gap-[16px]'>
                                        <div className='flex flex-row justify-between px-[15%]' >
                                            <span>価</span><span>考</span>
                                        </div>
                                    </th>
                                </tr>
                                {invoice?.invoice_details && invoice?.invoice_details.map((item: IInvoiceDetail, index) => {
                                    let count = 1
                                    return (
                                        <>
                                            <tr className='!w-[100%]' >
                                                <td className='w-[30%] pl-[6px]'>{item?.content}</td>
                                                <td className='w-[5%]'></td>
                                                <td className='w-[10%] text-right px-[10px]'></td>
                                                <td className='w-[10%] text-right px-[10px]'></td>
                                                <td className='w-[10%] text-right px-[10px]'></td>
                                                <td className='w-[10%] text-right px-[10px]'></td>
                                            </tr>
                                            {item?.children && item?.children?.map((item: IInvoiceChildren, indChild) => (
                                                <tr className='!w-[100%]' key={indChild} >
                                                    <td className='w-[30%] pl-[6px]'>{item?.content}</td>
                                                    <td className='w-[5%] text-center'>{item?.unit_estimate}</td>
                                                    <td className='w-[10%] text-right px-[10px]'>{item?.quantity_estimate?.toLocaleString('en-US')}</td>
                                                    <td className='w-[10%] text-right px-[10px]'>{item?.price_estimate?.toLocaleString('en-US')}</td>
                                                    <td className='w-[10%] text-right px-[10px]'>{(item?.price_estimate * item?.quantity_estimate)?.toLocaleString('en-US')}</td>
                                                    <td className='w-[10%] text-right px-[10px]'></td>
                                                </tr>
                                            ))}
                                            <tr className='!w-[100%]' >
                                                <td className='w-[30%]'></td>
                                                <td className='w-[5%]'></td>
                                                <td className='w-[10%] text-right px-[10px]'></td>
                                                <td className='w-[10%] text-right px-[10px]'></td>
                                                <td className='w-[10%] text-right px-[10px]'>{totalItemPrice[index]?.toLocaleString('en-US')}</td>
                                                <td className='w-[10%] text-right px-[10px]'></td>
                                            </tr>
                                        </>
                                    )
                                })}


                                <tr>
                                    <td className='w-[30%] pl-[6px]'>{'<税抜合計金額>'}</td>
                                    <td className='w-[5%]'></td>
                                    <td className='w-[10%] text-right px-[10px]'></td>
                                    <td className='w-[10%] text-right px-[10px]'></td>
                                    <td className='w-[10%] text-right pr-[10px]'>{invoice?.total_estimate_before_tax?.toLocaleString('en-US')}</td>
                                    <td className='w-[10%] text-right px-[10px]'></td>
                                </tr>
                                <tr>
                                    <td className='w-[30%] pl-[6px]'>{'<消費税>'}</td>
                                    <td className='w-[5%]'></td>
                                    <td className='w-[10%] text-right px-[10px]'></td>
                                    <td className='w-[10%] text-right px-[10px]'></td>
                                    <td className='w-[11%] text-right pr-[10px]'>{(invoice?.total_estimate_before_tax * 10 / 100)?.toLocaleString('en-US')}</td>
                                    <td className='w-[11%] text-right px-[10px]'></td>
                                </tr>
                            </table>
                            <table className='mx-[20px]' style={{ borderBottom: '1px solid', borderLeft: '1px solid', borderRight: '1px solid' }}>
                                <tr>
                                    <td className='w-[57.3%] !border-t-0 pl-[6px]' >口座：知多信用金庫　東浦支店　普通　０３３７２１５ <br />
                                        口座名義：河建株式会社（カワケンカブシキガイシャ）<br />
                                        恐れ入りますが、振込手数料は負担くださいますようにお願い致します。</td>
                                    <td className='w-[12.7%] !border-t-0 text-center'>合計</td>
                                    <td className='w-[14%] !border-t-0 pr-[10px] text-right'>{invoice?.total_estimate_after_tax?.toLocaleString('en-US')}</td>
                                    <td className='w-[14%] !border-t-0 text-right'></td>
                                </tr>
                            </table>
                        </div>
                        <div className='flex flex-row absolute bottom-0 left-[calc(50%-230px)] gap-[20px]' >
                            <button
                                className="modal-create-user-button2 !rounded-[2xp] !text-[#000000D9] right-auto d-flex flex-row w-[140px]"
                                style={{
                                    width: '140px', borderRadius: '0px',
                                    background: '#fff', border: '1px solid #D9D9D9'
                                }}
                                type="button"
                                disabled={uploadLoading || exportLoading}
                                onClick={handleClose}
                            >
                                キャンセル
                            </button>
                            <button
                                className="modal-create-user-button2 !rounded-[2xp] !whitespace-nowrap right-auto d-flex flex-row w-[140px]"
                                style={{
                                    width: '140px', borderRadius: '0px',
                                    background: '#215493',
                                    border: '1px solid #D9D9D9'
                                }}
                                type="button"
                                disabled={uploadLoading || exportLoading}
                                onClick={() => handleExport('export')}
                            >
                                {exportLoading && <Spin />}<img src={UploadWhite} alt='' />ダウンロード
                            </button>

                            <button
                                className="modal-create-user-button2 !rounded-[2xp] right-auto d-flex flex-row w-[140px]"
                                style={{
                                    width: '140px', borderRadius: '0px',
                                    background: `${(disableInvoice || invoice?.quote?.created_by === '管理者') ? '#F5F5F5' : '#02A509'}`,
                                    color: `${(disableInvoice || invoice?.quote?.created_by === '管理者') ? '#8C8C8C' : '#fff'}`,
                                    // background: '#02A509', 
                                    border: '1px solid #CCCCCC'
                                }}
                                type="button"
                                disabled={uploadLoading || exportLoading || disableInvoice || invoice?.quote?.created_by === '管理者'}
                                onClick={openConfirm}
                            >
                                {uploadLoading && <Spin />} LINE送信
                            </button>
                        </div>

                        <ModalConfirm>
                            <div
                                className="d-flex flex-column justify-center !bg-[#ffffff] rounded-[10px] px-[20px]"
                                style={{ width: '449px', height: '285px', zIndex: '9999999999999999' }}
                            >
                                <div className="flex justify-end mt-[10px] cursor-pointer">
                                    <CloseOutlined onClick={closeConfirm} />
                                </div>
                                <div className="flex flex-col justify-center items-center pb-[10px]">
                                    <img
                                        src={ExclamationIcon}
                                        className="w-[80px] h-[80px]"
                                        alt="exclamation icon"
                                    />
                                    <div className="text-[#3A3B3C] font-normal text-center text-[13px] pt-[15px]">
                                        データをもう一度確認してから送信してください。
                                        <br />
                                        このデータを送信してもよろしいですか？
                                    </div>
                                    <div
                                        className="flex gap-[10px]"
                                        style={{ marginTop: '40px' }}
                                    >
                                        <button
                                            className="modal-create-user-button1 d-flex flex-row !w-[147px] !h-[36px] !rounded-[2xp]"
                                            onClick={closeConfirm}
                                        >
                                            キャンセル
                                        </button>
                                        <button onClick={() => handleExport('upload')}
                                            disabled={uploadLoading || exportLoading}
                                            className="modal-create-user-button2 d-flex flex-row !w-[147px] !h-[36px] !rounded-[2xp]">
                                            {uploadLoading && <Spin />}
                                            送信する
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </ModalConfirm>
                    </InvoicePDFStyle>
                }
            </ConfigProvider>
        </ModalQuoteCustoms >
    );

});
export default InvoicePdf;
