export const formatCurrency = (value) => {
    const zero = 0
    if (value && value !== 0) {
        return value?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'JPY'
        })
    }
    return zero?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'JPY'
    })
}