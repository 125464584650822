import { axiosClient } from "./quoteApi";
import pick from 'lodash/pick';

class ScheduleApi {
    createSchedule = (idToken: any, body: any) => {
        const url = `schedules`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` }
        };
        return axiosClient.post(url, body, config);
    };

    updateSchedule = (idToken: any, body: any, id: string) => {
        const url = `schedules/${id}`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` }
        };
        return axiosClient.put(url, body, config);
    };

    getStaffDetail = (idToken: any, id: any) => {
        const url = `staffs/${id}`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` }
        };
        return axiosClient.get(url, config);
    };


    updateStaff = (idToken: any, body: any, id: any) => {
        const url = `staffs/${id}`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` }
        };
        return axiosClient.put(url, body, config);
    };

    getStaffs = (idToken: any, body: any) => {
        let paramsData = pick(body, [
            'page',
            'per_page',
            'filter_value',
        ]);
        const params = new URLSearchParams(paramsData);
        const url = `staffs?${params.toString()}`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.get(url, config);
    };

    deleteStaff = (idToken: any, invoiceId) => {
        const url = `staffs/${invoiceId}`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.delete(url, config);
    };
}
const scheduleApi = new ScheduleApi();
export default scheduleApi;