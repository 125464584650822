// Lib
import { required } from '../validator';

// Type
import { TypeOfT } from 'utils/type/translations/Translation.type';

const createProjectValidation = (t: TypeOfT) => ({
    name: () => ({
        required: '社員名は必須です。',
    }),
    title: () => ({
        required: 'タイトルは必須です。',
    }),
    quote: () => ({
        required: '案件名は必須です。',
    }),
    location: () => ({
        required: '場所（営業所、住所など）は必須です。',
    }),
    start_date: () => ({
        required: '期間は必須です。',
    }),
    end_date: () => ({
        required: '期間は必須です。',
    }),
    content: () => ({
        required: '内容は必須です。',
    }),
    business_type: () => ({
        required: required('business_type', t),
    }),
    client_company_id: () => ({
        required: required('client_company_id', t),
    }),
    manager_worker_id: () => ({
        required: required('manager_worker_id', t),
    }),
    estimate_assigned_worker_id: () => ({
        required: required('estimate_assigned_worker_id', t),
    }),
    start_at: () => ({
        required: required('start_at', t),
    }),
    end_at: () => ({
        required: required('end_at', t),
    }),
    location_address: () => ({
        required: required('location_address', t),
    }),
    location_zipcode: () => ({
        required: required('location_zipcode', t),
    }),
    status: () => ({
        required: required('status', t),
    }),
});

export default createProjectValidation;
