import { useFormik } from 'formik';
import { Dispatch, SetStateAction, useEffect } from 'react';

type IOptions = {
    id: any;
    name: string;
    value: string;
    label: string
}[];

export type ReceivedProps = {
    positionOptions: IOptions;
    workerOptions: IOptions;
    departmentOptions: IOptions;
    setSearchParam: Dispatch<SetStateAction<any>>;
    quoteOptions: IOptions;
    activeTab: number;
    setActiveTab: Dispatch<SetStateAction<number>>;
};

const useFilterBox = (props: ReceivedProps) => {
    const { activeTab, setActiveTab } = props
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            department_id: null,
            position_id: null,
            worker_id: null,
            free_word: '',
        },
        onSubmit: (values: any) => {
            props.setSearchParam({
                ...values,
                department_id: values?.department_id?.id,
                position_id: values?.position_id?.id,
                worker_id: values?.worker_id?.id,
            });
        },
    });

    useEffect(() => {
        formik.setFieldValue(
            'position_id',
            null,
        );
        formik.setFieldValue(
            'worker_id',
            null,
        );
        formik.setFieldValue(
            'free_word',
            '',
        );
        props.setSearchParam({
            department_id: null,
            position_id: null,
            worker_id: null,
            free_word: ''
        });
    }, [activeTab])
    return {
        ...props,
        formik,
    };
};

export type Props = ReturnType<typeof useFilterBox>;

export default useFilterBox;
