import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { Box, TextField, InputAdornment, Button } from '@mui/material';
import { QuoteStatusEnum, QuoteTypeEnum } from 'utils/enums';
import RedTrash from 'assets/icons/Trash.svg';
import SelectSearch, { SelectSearchOption } from 'react-select-search';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { clsx } from 'clsx';
import moment from 'moment';
// import useInvoiceActual, { Props, ReceivedProps } from './hook';
import { ConfigProvider, DatePicker, Input } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import invoiceValidation from 'utils/validation/registers/invoice';
import PlusIcon from 'assets/icons/white-plus.svg'
import jaJP from 'antd/es/locale/ja_JP';
import NumberFormat, { NumberFormatBase, NumericFormat, useNumericFormat } from 'react-number-format';
import UploadWhite from 'assets/icons/upload-white.svg'
import UploadGrey from 'assets/icons/upload-grey.svg'
import UpArrow from 'assets/icons/up-arrow.svg'
import { formatCurrency } from 'utils/helper/currency';
import useInvoiceHome, { Props, ReceivedProps } from '../hook';

const statusOptions = Object.values(QuoteStatusEnum).map((item) => ({
    value: item.value,
    name: item.title,
}));

statusOptions.unshift({
    value: 'all',
    name: 'すべて'
})

const LeftOutlinedCustom = styled(LeftOutlined)``;

const japanNumeral: any = ['０', '１', '２', '３', '４', '５', '６', '７', '８', '９'];

function CustomNumeralNumericFormat(props) {
    const { format, removeFormatting, isCharacterSame, ...rest }: any = useNumericFormat(props);

    const _format = (val) => {
        const _val = format(val);
        return _val.replace(($1) => japanNumeral[Number($1)], /\d/g);
    };

    const _removeFormatting = (val) => {
        const _val = val.replace(new RegExp(japanNumeral.join('|'), 'g'), ($1) =>
            japanNumeral.indexOf($1),
        );

        return removeFormatting(_val);
    };

    const _isCharacterSame = (compareMeta) => {
        const isCharSame = isCharacterSame(compareMeta);
        const { formattedValue, currentValue, formattedValueIndex, currentValueIndex } = compareMeta;
        const curChar = currentValue[currentValueIndex];
        const newChar = formattedValue[formattedValueIndex];
        const curPersianChar = japanNumeral[Number(curChar)] ?? curChar;
        const newPersianChar = japanNumeral[Number(newChar)] ?? newChar;

        return isCharSame || curPersianChar || newPersianChar;
    };

    return (
        <NumberFormatBase
            format={_format}
            removeFormatting={_removeFormatting}
            isCharacterSame={_isCharacterSame}
            {...rest}
        />
    );
}

export function fullWidthNumConvert(fullWidthNum) {
    return fullWidthNum.replace(/[\uFF10-\uFF19]/g, function (m) {
        return String.fromCharCode(m.charCodeAt(0) - 0xfee0);
    });
}

const sampleItem = {
    title: '',
    content: [''],
    price: [''],
    quantity: [''],
    unit: ['式'],
    estimateQuantity: [''],
    estimatePrice: [''],
    tax: ['10']
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#377ACD',
        color: '#fff',
        fontWeight: 600,
        padding: '6px 8px',
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Zen Kaku Gothic Antique',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#1C1E21',
        padding: '0px 8px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const formatter = (str) => str ? str.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""

const parser = (str) => str.replaceAll(",", "")

const convertFullwidthToHalfwidth = (str) => {
    return str.replace(/[！-～]/g, (r) => {
        return String.fromCharCode(r.charCodeAt(0) - 0xFEE0)
    })
}

const InvoiceActualLayout: FC<Props> = ({
    invoice,
    queryObj,
    arrInvoiceActual,
    setArrInvoiceActual,
    totalItemPriceActual,
    totalItemEstimatePrice,
    totalItemRevenueActual,
    totalPriceActual,
    totalEstimatePrice,
    totalRevenueActual,
    setTotalItemPriceActual, setTotalItemEstimatePrice, setTotalItemRevenueActual,
    setTotalPriceActual, setTotalEstimatePrice, setTotalRevenueActual
}) => {
    const navigate = useNavigate();
    const { register, getValues, setError, clearErrors, watch, control, setValue } = useFormContext();

    const calTotalActual = () => {
        let totalPriceActualTmp = 0
        let totalEstimateTmp = 0
        let totalRevenueActualTmp = 0
        let totalPriceActualArr: number[] = []
        let totalEstimateArr: number[] = []
        let totalRevenueActualArr: number[] = []
        if (getValues('titleInv_tab3')?.length > 0) {
            for (let i = 0; i < getValues('titleInv_tab3')?.length; i++) {

                let totalPriceActualItemTmp = 0
                let totalEstimateItemTmp = 0
                let totalRevenueActualItemTmp = 0
                for (let k = 0; k < getValues(`titleInv_tab3.${i}.tax`)?.length; k++) {
                    let price = getValues(`titleInv_tab3.${i}.price.${k}`) ?
                        getValues(`titleInv_tab3.${i}.price.${k}`).replace(/[¥,]/g, '') : ''
                    let estimatePrice = getValues(`titleInv_tab3.${i}.estimatePrice.${k}`) ?
                        getValues(`titleInv_tab3.${i}.estimatePrice.${k}`).replace(/,/g, '') : ''
                    let quantity = getValues(`titleInv_tab3.${i}.quantity.${k}`) ?
                        getValues(`titleInv_tab3.${i}.quantity.${k}`).replace(/,/g, '') : ''
                    let estimateQuantity = getValues(`titleInv_tab3.${i}.estimateQuantity.${k}`) ?
                        getValues(`titleInv_tab3.${i}.estimateQuantity.${k}`).replace(/,/g, '') : ''
                    totalPriceActualTmp += Number(price) * Number(quantity)
                    totalEstimateTmp += Number(estimatePrice) * Number(estimateQuantity)
                    totalRevenueActualTmp += ((Number(estimatePrice) * Number(estimateQuantity) - Number(price) * Number(quantity)))
                    totalPriceActualItemTmp += Number(price) * Number(quantity)
                    totalEstimateItemTmp += Number(estimatePrice) * Number(estimateQuantity)
                    totalRevenueActualItemTmp += (Number(estimatePrice) * Number(estimateQuantity)) - (Number(price) * Number(quantity))

                }
                totalPriceActualArr.push(totalPriceActualItemTmp)
                totalEstimateArr.push(totalEstimateItemTmp)
                totalRevenueActualArr.push(totalRevenueActualItemTmp)

            }

            setTotalItemPriceActual(totalPriceActualArr)
            setTotalItemEstimatePrice(totalEstimateArr)
            setTotalItemRevenueActual(totalRevenueActualArr)
            setValue('priceTotalBudget', totalPriceActualTmp)
            setTotalPriceActual(totalPriceActualTmp)
            setTotalEstimatePrice(totalEstimateTmp)
            setTotalRevenueActual(totalRevenueActualTmp)
        } else {
            setTotalItemPriceActual([])
            setTotalItemEstimatePrice([])
            setTotalItemRevenueActual([])
            setValue('priceTotalBudget', 0)
            setTotalPriceActual(0)
            setTotalEstimatePrice(0)
            setTotalRevenueActual(0)
        }
    }

    const handleBlur = () => {
        calTotalActual()
    }

    const onDeleteTitle = (index) => {
        const filterArrItem = arrInvoiceActual.filter((item, ind) => ind !== index)
        const titleInv_tab3Filter = getValues('titleInv_tab3').filter((item, ind) => ind !== index)
        const arrTmp: any = []
        for (let i = 0; i < filterArrItem.length; i++) {
            const countTmp = arrTmp[arrTmp.length - 1] || 0
            arrTmp.push(true)
        }
        setArrInvoiceActual([...arrTmp])
        setValue('titleInv_tab3', [...titleInv_tab3Filter])
        calTotalActual()
    }

    const onDelete = (titleInd, index) => {
        const contentFilter = getValues(`titleInv_tab3.${titleInd}.content`).filter((item, ind) => ind !== index)
        const unitFilter = getValues(`titleInv_tab3.${titleInd}.unit`).filter((item, ind) => ind !== index)
        const priceFilter = getValues(`titleInv_tab3.${titleInd}.price`).filter((item, ind) => ind !== index)
        const estimatePriceFilter = getValues(`titleInv_tab3.${titleInd}.estimatePrice`).filter((item, ind) => ind !== index)
        const quantityFilter = getValues(`titleInv_tab3.${titleInd}.quantity`).filter((item, ind) => ind !== index)
        const taxFilter = getValues(`titleInv_tab3.${titleInd}.tax`).filter((item, ind) => ind !== index)
        const estimateQuantityFilter = getValues(`titleInv_tab3.${titleInd}.estimateQuantity`).filter((item, ind) => ind !== index)

        setValue(`titleInv_tab3.${titleInd}.content`, [...contentFilter])
        setValue(`titleInv_tab3.${titleInd}.unit`, [...unitFilter])
        setValue(`titleInv_tab3.${titleInd}.quantity`, [...quantityFilter])
        setValue(`titleInv_tab3.${titleInd}.price`, [...priceFilter])
        setValue(`titleInv_tab3.${titleInd}.estimatePrice`, [...estimatePriceFilter])
        setValue(`titleInv_tab3.${index}.estimateQuantity`, [...estimateQuantityFilter])
        setValue(`titleInv_tab3.${titleInd}.tax`, [...taxFilter])
        calTotalActual()
    }

    const onAddArr = () => {
        const arrInvTmp = [...arrInvoiceActual]
        if (arrInvTmp.length > 0) {
            arrInvTmp.push(true)
            setValue(`titleInv_tab3.${arrInvTmp.length - 1}`, sampleItem)
        } else {
            arrInvTmp.push(true)
            setValue(`titleInv_tab3.0`, sampleItem)
        }
        setArrInvoiceActual(arrInvTmp)
    }

    const onAddArrItem = (index: number) => {
        const arrInvTmp = [...arrInvoiceActual]
        const varUnit = watch(`titleInv_tab3.${index}.unit`)
        if (varUnit?.length > 0) {
            arrInvTmp.push(true)
            setValue(`titleInv_tab3.${index}.content.${varUnit?.length}`, '')
            setValue(`titleInv_tab3.${index}.price.${varUnit?.length}`, '')
            setValue(`titleInv_tab3.${index}.quantity.${varUnit?.length}`, '')
            setValue(`titleInv_tab3.${index}.tax.${varUnit?.length}`, '10')
            setValue(`titleInv_tab3.${index}.unit.${varUnit?.length}`, '式')
            setValue(`titleInv_tab3.${index}.estimatePrice.${varUnit?.length}`, 0)
            setValue(`titleInv_tab3.${index}.estimateQuantity.${varUnit?.length}`, 0)

        } else {
            setValue(`titleInv_tab3.${index}.unit.0`, '式')
            setValue(`titleInv_tab3.${index}.content.0`, '')
            setValue(`titleInv_tab3.${index}.price.0`, '')
            setValue(`titleInv_tab3.${index}.quantity.0`, '')
            setValue(`titleInv_tab3.${index}.tax.0`, '10')
            setValue(`titleInv_tab3.${index}.estimatePrice.0`, 0)
            setValue(`titleInv_tab3.${index}.estimateQuantity.0`, 0)
        }
    }

    const onHide = (index) => {
        const arrTmp = [...arrInvoiceActual]
        arrTmp[index] = !arrTmp[index]
        setArrInvoiceActual(arrTmp)
    }

    const onNavigateBack = () => {
        if (queryObj?.prev && queryObj?.prev === 'quote') {
            navigate('/quote/list')
        } else {
            navigate('/invoices')
        }
    }
    return (
        <ConfigProvider locale={jaJP}>
            <div className="container-worker d-flex flex-row !px-[0px] !pt-[0px] !rounded-tr-none !rounded-tl-none">

                <div className="d-flex flex flex-column bg-[#fff] p-[28px] !pt-0 !w-full">
                    <div className="table-workers d-flex flex-column mt-[15px]">
                        <div
                            // className="table-content d-flex flex-column"
                            style={{ border: '1px solid #F2F2F2' }}>

                            <TableContainer table-layout={'auto'}>
                                <Table
                                    sx={{ minWidth: 900 }}
                                    aria-label="customized table"
                                >
                                    <TableHead style={{ background: '#377ACD', color: '#fff' }}>
                                        <TableRow>
                                            <StyledTableCell
                                                className="text-overflow1"
                                                style={{ width: '5%', whiteSpace: 'nowrap' }}
                                            >
                                                <div>番号</div>
                                            </StyledTableCell>

                                            <StyledTableCell
                                                className="text-overflow1"
                                                style={{ width: '25%' }}
                                            >
                                                <div>内容</div>
                                            </StyledTableCell>

                                            <StyledTableCell
                                                className="text-overflow1"
                                                style={{ width: '8%' }}
                                            >
                                                <div>数量</div>
                                            </StyledTableCell>

                                            <StyledTableCell
                                                className="text-overflow1"
                                                style={{ width: '8%' }}
                                            >
                                                <div>単位</div>
                                            </StyledTableCell>

                                            <StyledTableCell
                                                className="text-overflow1"
                                                style={{ width: '11%' }}
                                            >
                                                <div>単価</div>
                                            </StyledTableCell>

                                            <StyledTableCell
                                                className="text-overflow1"
                                                style={{ width: '11%' }}
                                            >
                                                <div>金額</div>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow1"
                                                style={{ width: '11%' }}
                                            >
                                                <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }} >税抜見積金額</div>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow1"
                                                style={{ width: '11%', textAlign: 'center' }}
                                            >
                                                <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }} >粗利</div>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow1"
                                                style={{ width: '5%', padding: '0px' }}
                                            >
                                                <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>操作</div>
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>

                                </Table>
                            </TableContainer>
                            {arrInvoiceActual.map((row, index) => {
                                return (
                                    <div >
                                        <div className="flex flex-row justify-between py-[4px]" >
                                            <div className='flex flex-row w-[100%]' >
                                                <div className='w-[5.88%] px-[8px] flex flex-row gap-[16px] text-[16px]' >
                                                    {index + 1}
                                                    <img src={UpArrow} height={5} width={16} className='cursor-pointer'
                                                        style={{
                                                            transform: `${row ? 'rotate(0)' : 'rotate(180deg)'}`,
                                                            transition: '0.5s transform'
                                                        }}
                                                        alt="" onClick={() => onHide(index)} />
                                                </div>
                                                <div className='w-[27.3%] pl-[4px] pr-[8px] text-[16px]' >
                                                    <Controller
                                                        name={`titleInv_tab3.${index}.title`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <StyledDefaultInput
                                                                {...register(
                                                                    `titleInv_tab3.${index}.title`
                                                                )}
                                                                {...field}
                                                                placeholder='項目は必須です' />

                                                        )}
                                                    />
                                                </div>
                                                <div className='w-[8%] flex flex-row justify-center' >
                                                    <Button className='w-[40px] !h-[30px] text-white bg-[#215493]'
                                                        style={{ borderRadius: '4px', background: '#215493' }}
                                                        onClick={() => onAddArrItem(index)}
                                                    >
                                                        <img src={PlusIcon} alt='plus' height={18} width={18} /></Button>
                                                </div>

                                            </div>
                                            <div
                                                className="flex justify-center w-[5%] pr-[8px] items-center">
                                                <Box
                                                    sx={{
                                                        cursor: 'pointer',
                                                        width: 24,
                                                        height: 24,
                                                        margin: '0px',
                                                        marginLeft: '6px'
                                                    }}
                                                    onClick={() => {
                                                        onDeleteTitle(index)
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            RedTrash
                                                        }
                                                        alt="red trash icon"
                                                    />
                                                </Box>
                                            </div>
                                        </div>

                                        {row && <TableContainer table-layout={'auto'}>
                                            <Table
                                                sx={{ minWidth: 900 }}
                                                aria-label="customized table"
                                            >

                                                <TableBody>
                                                    {watch(`titleInv_tab3.${index}.tax`) && watch(`titleInv_tab3.${index}.tax`).map((row, ind) => {
                                                        if (ind < watch(`titleInv_tab3.${index}.tax`).length) {
                                                            let price = getValues(`titleInv_tab3.${index}.price.${ind}`) || ''
                                                            let quantity = getValues(`titleInv_tab3.${index}.quantity.${ind}`) || ''
                                                            let estimatePrice = getValues(`titleInv_tab3.${index}.estimatePrice.${ind}`) || ''
                                                            let estimateQuantity = getValues(`titleInv_tab3.${index}.estimateQuantity.${ind}`) || ''
                                                            if (String(estimateQuantity).includes(',')) {
                                                                estimateQuantity = estimateQuantity.replace(/,/g, '')
                                                            }
                                                            if (String(price).includes(',') || String(price).includes('¥')) {
                                                                price = price.replace(/[¥,]/g, '')
                                                            }
                                                            if (String(estimatePrice).includes(',')) {
                                                                estimatePrice = estimatePrice.replace(/,/g, '')
                                                            }
                                                            if (String(quantity).includes(',')) {
                                                                quantity = quantity.replace(/,/g, '')
                                                            }
                                                            return (
                                                                <StyledTableRow key={row} style={{ borderTop: `${ind === 0 && '1px solid #F2F2F2'}` }} >
                                                                    <StyledTableCell
                                                                        className="!text-left"
                                                                        component="th"
                                                                        scope="row"
                                                                        style={{ width: '5%' }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                alignItems:
                                                                                    'center',
                                                                            }}
                                                                        />
                                                                    </StyledTableCell>

                                                                    <StyledTableCell
                                                                        className="!text-left"
                                                                        style={{ width: '25%' }}
                                                                    >
                                                                        <Controller
                                                                            name={`titleInv_tab3.${index}.content.${ind}`}
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <StyledDefaultInput
                                                                                    {...register(
                                                                                        `titleInv_tab3.${index}.content.${ind}`
                                                                                    )}
                                                                                    {...field}
                                                                                    placeholder='項目は必須です' />

                                                                            )}
                                                                        />

                                                                    </StyledTableCell>

                                                                    <StyledTableCell
                                                                        className="!text-left"
                                                                        style={{ width: '8%' }}
                                                                    >
                                                                        <div>
                                                                            <Controller
                                                                                name={`titleInv_tab3.${index}.quantity.${ind}`}
                                                                                control={control}
                                                                                render={({ field }) => (

                                                                                    <StyledDefaultInput
                                                                                        {...register(
                                                                                            `titleInv_tab3.${index}.quantity.${ind}`
                                                                                        )} onBlur={(evt) => {
                                                                                            let stringWithoutCommas = evt.target.value.replace(/,/g, '')
                                                                                            setValue(`titleInv_tab3.${index}.quantity.${ind}`, Number(fullWidthNumConvert(stringWithoutCommas)).toLocaleString('en-US'))
                                                                                            handleBlur()
                                                                                        }}
                                                                                        className='text-center'
                                                                                        value={field.value}
                                                                                        onChange={(evt) => {
                                                                                            let stringWithoutCommas = evt.target.value.replace(/,/g, '');
                                                                                            if (stringWithoutCommas.match("^[0-9０-９]+$") || stringWithoutCommas === '') {
                                                                                                const newVal = fullWidthNumConvert(stringWithoutCommas)
                                                                                                field.onChange(stringWithoutCommas);
                                                                                                setValue(`titleInv_tab3.${index}.quantity.${ind}`, stringWithoutCommas)
                                                                                            }
                                                                                        }}
                                                                                        placeholder='数量は必須です'
                                                                                    />
                                                                                )}
                                                                            />
                                                                        </div>
                                                                    </StyledTableCell>

                                                                    <StyledTableCell
                                                                        className="!text-left"
                                                                        style={{ width: '8%' }}
                                                                    >
                                                                        <Controller
                                                                            name={`titleInv_tab3.${index}.unit.${ind}`}
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <StyledDefaultInput maxLength={3}
                                                                                    style={{ textAlign: 'center' }}
                                                                                    {...register(
                                                                                        `titleInv_tab3.${index}.unit.${ind}`
                                                                                    )} {...field} />

                                                                            )}
                                                                        />
                                                                    </StyledTableCell>

                                                                    <StyledTableCell
                                                                        align="center"
                                                                        style={{ width: '11%' }}
                                                                    >
                                                                        <Controller
                                                                            name={`titleInv_tab3.${index}.price.${ind}`}
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <StyledDefaultInput
                                                                                    {...register(
                                                                                        `titleInv_tab3.${index}.price.${ind}`
                                                                                    )} onBlur={(evt) => {
                                                                                        let stringWithoutCommas = evt.target.value.replace(/[¥,]/g, '')
                                                                                        setValue(`titleInv_tab3.${index}.price.${ind}`, formatCurrency(Number(fullWidthNumConvert(stringWithoutCommas))))
                                                                                        handleBlur()
                                                                                    }}
                                                                                    className='text-right'
                                                                                    value={field.value}
                                                                                    onChange={(evt) => {
                                                                                        let stringWithoutCommas = evt.target.value.replace(/[¥,]/g, '');
                                                                                        if (stringWithoutCommas.match("^[0-9０-９]+$") || stringWithoutCommas === '') {
                                                                                            const newVal = fullWidthNumConvert(stringWithoutCommas)
                                                                                            field.onChange(stringWithoutCommas);
                                                                                            setValue(`titleInv_tab3.${index}.price.${ind}`, stringWithoutCommas)
                                                                                        }
                                                                                    }}
                                                                                    placeholder='単価は必須です'
                                                                                />
                                                                            )}
                                                                        />

                                                                    </StyledTableCell>
                                                                    <StyledTableCell
                                                                        align="center"
                                                                        style={{ width: '11%' }}
                                                                    >
                                                                        <NumericFormat
                                                                            customInput={StyledDefaultInput}
                                                                            thousandSeparator=","
                                                                            decimalSeparator="."
                                                                            prefix={'￥'}
                                                                            name='amount_money'
                                                                            className="text-[#666666]"
                                                                            style={{ textAlign: 'right', background: '#fff', color: 'rgba(0, 0, 0, 0.85)' }}
                                                                            value={(Number(price) * Number(quantity)) || '0'}
                                                                            disabled
                                                                        />
                                                                    </StyledTableCell>
                                                                    <StyledTableCell
                                                                        align="center"
                                                                        style={{ width: '11%' }}
                                                                    >
                                                                        <NumericFormat
                                                                            customInput={StyledDefaultInput}
                                                                            thousandSeparator=","
                                                                            decimalSeparator="."
                                                                            prefix={'￥'}
                                                                            name='amount_money'
                                                                            className="text-[#666666]"
                                                                            style={{ textAlign: 'right', background: '#fff', color: 'rgba(0, 0, 0, 0.85)' }}
                                                                            value={(Number(estimatePrice) * Number(estimateQuantity)) || '0'}
                                                                            disabled
                                                                        />
                                                                    </StyledTableCell>
                                                                    <StyledTableCell
                                                                        align="center"
                                                                        style={{ width: '11%' }}
                                                                    >
                                                                        <NumericFormat
                                                                            customInput={StyledDefaultInput}
                                                                            thousandSeparator=","
                                                                            decimalSeparator="."
                                                                            prefix={'￥'}
                                                                            disabled
                                                                            className="text-[#666666]"
                                                                            name='amount_money_tax'
                                                                            style={{ textAlign: 'right', background: '#fff', color: 'rgba(0, 0, 0, 0.85)' }}
                                                                            value={((Number(estimatePrice) * Number(estimateQuantity)) - (Number(price) * Number(quantity))) || '0'}
                                                                        />

                                                                    </StyledTableCell>
                                                                    <StyledTableCell
                                                                        align="center"
                                                                        style={{ width: '5%', paddingLeft: '0px' }}
                                                                    >
                                                                        <div style={{ height: '40px' }}
                                                                            className="flex justify-center items-center">
                                                                            <Box
                                                                                sx={{
                                                                                    cursor: 'pointer',
                                                                                    width: 24,
                                                                                    height: 24,
                                                                                    margin: '0px',
                                                                                    marginLeft: '12px'
                                                                                }}
                                                                                onClick={() => {
                                                                                    onDelete(index, ind)
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={
                                                                                        RedTrash
                                                                                    }
                                                                                    alt="red trash icon"
                                                                                />
                                                                            </Box>
                                                                        </div>
                                                                    </StyledTableCell>
                                                                </StyledTableRow>
                                                            )
                                                        }
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>}
                                        <div className='flex flex-row py-[10px]' style={{ borderTop: '1px solid #F2F2F2', border: '1px solid #F2F2F2' }} >
                                            <div className='w-[6%]' />
                                            <div className='w-[26.2%]' />
                                            <div className='w-[8%]' />
                                            <div className='w-[8%]' />
                                            <div className='w-[11%]' />
                                            <div className='w-[10.7%] font-bold text-right' >
                                                {formatCurrency(totalItemPriceActual[index])}
                                            </div>
                                            <div className='w-[11.5%] font-bold text-right' >
                                                {formatCurrency(totalItemEstimatePrice[index])}
                                            </div>
                                            <div className='w-[11.7%] font-bold text-right' >
                                                {formatCurrency(totalItemRevenueActual[index])}
                                            </div>
                                            <div className='w-[5%]' />
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                        <div className='flex flex-row justify-center mt-[20px]'>
                            <Button className='w-[114px] h-[38px] text-white bg-[#215493]'
                                style={{ borderRadius: '8px', background: '#215493', gap: '8px', marginTop: '10px' }} onClick={onAddArr} >
                                <img src={PlusIcon} alt='plus' height={16} width={16} /> 追加</Button>

                        </div>

                        <div className='flex flex-row h-[103px] mt-[30px]' >
                            <div className='w-[40%]' />
                            <div className='grid grid-cols-3 bg-[#E8F2FF] items-end h-[103px] text-[14px] w-[60%] p-[16px]'
                                style={{ borderRadius: '8px' }} >
                                <div className='flex flex-row gap-[15px]'>
                                    <p style={{ color: '#0000008C' }} >税抜見積合計金額</p>
                                    <p className='text-[#000]' >{formatCurrency(totalEstimatePrice)}</p>
                                </div>
                                <div className='flex flex-row gap-[15px]'>
                                    <p style={{ color: '#0000008C' }}>粗利合計 </p>
                                    <p className='text-[#000]' >{formatCurrency(totalRevenueActual)}</p>
                                </div>
                                <div className='flex flex-row gap-[15px]'>
                                    <p style={{ color: '#0000008C' }}>粗利率</p>
                                    <p className='text-[#000]'>{(totalEstimatePrice > 0 ? (totalRevenueActual / totalEstimatePrice * 100) : 0 || 0).toFixed(2)}%</p>
                                </div>
                                <div className='flex flex-row gap-[15px]'>
                                    <p style={{ color: '#0000008C' }}>税抜実績合計</p>
                                    <p className='text-[#000]'>{formatCurrency(totalPriceActual)}</p>
                                </div>
                                <div className='flex flex-row gap-[15px]'>
                                    <p style={{ color: '#0000008C' }}>税込合計</p>
                                    <p className='text-[#000]'>{formatCurrency(totalPriceActual + totalPriceActual * 10 / 100)}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className='bg-[#fff] flex flex-row justify-end' style={{ padding: '16px 24px 16px 24px' }} >

                <div className='flex flex-row' >
                    <button
                        className="modal-create-user-button1 d-flex flex-row w-[140px]"
                        style={{ width: '140px', borderRadius: '0px', border: '1px solid #D9D9D9', color: '#000000D9' }}
                        type="button"
                        onClick={onNavigateBack}
                    >
                        キャンセル
                    </button>
                    <button
                        className="modal-create-user-button2 d-flex flex-row mx-[15px] w-[140px]"
                        disabled={invoice?.status === 'invoice_accepted_done'}
                        style={{
                            width: '140px', borderRadius: '0px',
                            background: '#215493',
                        }}
                        type="submit"
                    >
                        保存
                    </button>
                </div>
            </div>
        </ConfigProvider>
    );
};

const InvoiceActual: FC<ReceivedProps> = (props) => {
    return <InvoiceActualLayout {...useInvoiceHome(props)} />;
};

const StyledInput = styled(Input)({
    padding: '8px 12px 8px 12px',
    width: '270px'
})

const StyledDefaultInput = styled(Input)({
    padding: '8px 12px 8px 12px',
    // border: 'none',
    height: '31px',
    border: '1px solid #D9D9D9',
    width: '100%',
    borderRadius: '8px'
})

export default InvoiceActual;
