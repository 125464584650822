import styled from "styled-components"

export const StyledFormGroup = styled.div`
  position: relative;
  padding: 12px 12px 0;
  border: 1px solid rgba(217, 217, 217, 1);
`
export const StyledFormGroupLabel = styled.span`
  position: absolute;
  top: -12px;
  left: 12px;
  background-color: white;
`
