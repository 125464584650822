import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { clsx } from 'clsx';
import SelectSearch, { SelectSearchOption } from 'react-select-search';
import { DatePicker } from 'antd';
import PartnerService from 'services/partnerService';
import { alertError } from 'utils/helper/appHelper';
import quoteService from 'services/quoteService';
import { ListData } from 'pages/quote/list/hook';
import { formatMoney } from 'utils/helper/helpers';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const { RangePicker } = DatePicker;

const typeOptions: SelectSearchOption[] = [
    { value: 'all', name: 'すべて' },
    { value: 'interior', name: '内装' },
    { value: 'exterior', name: '外装' },
    { value: 'water_area', name: '水回り' },
    { value: 'other', name: 'その他' },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#E8E8E8',
        color: '#1C1E21',
        fontWeight: 600,
        padding: '12px 14px',
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Zen Kaku Gothic Antique',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#1C1E21',
        padding: '8px 14px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const LeftOutlinedCustom = styled(LeftOutlined)``;

interface ISearchValues {
    filter_value: string;
    invoice_start_date: string;
    invoice_end_date: string;
    prefecture: string;
}

interface IProps {
    idCompany: string | number | undefined;
}

const initialSearchValues: ISearchValues = {
    filter_value: '',
    invoice_start_date: '',
    invoice_end_date: '',
    prefecture: '',
};

const PartnerListDetail = (props: IProps) => {
    const navigate = useNavigate();
    const [quoteList, setQuoteList] = useState<ListData[]>([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [statusPrev, setStatusPrev] = useState(false);
    const [statusNext, setStatusNext] = useState(false);
    const [areaList, setAreaList] = useState<SelectSearchOption[]>([]);
    const [searchValues, setSearchValues] =
        useState<ISearchValues>(initialSearchValues);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const PER_PAGE = 10;
    const startIndex = (page - 1) * PER_PAGE;
    const setPagePrev = () => {
        if (page > 1) {
            setPage(page - 1);
            setStatusNext(false);
            setStatusPrev(true);
        }
    };
    const setPageNext = () => {
        if (page < totalPage) {
            setPage(page + 1);
            setStatusNext(true);
            setStatusPrev(false);
        }
    };
    const handleSearchQuote = () => {
        setPage(1);
    };
    const fetchAreas = async () => {
        try {
            const response = await PartnerService.getListAreas();
            const areaOptions = (response as any)?.areas?.map((item) => {
                return { name: item?.area_kanji, value: item?.area_kanji };
            });
            setAreaList([{ value: 'all', name: 'すべて' }, ...areaOptions]);
        } catch (error) {
            alertError(error?.response?.data?.error || '');
        }
    };
    const fetchQuoteList = async () => {
        try {
            let body = {
                page: page,
                per_page: PER_PAGE,
                cooperative_company_id: props.idCompany,
                free_word: 'jj',
            };
            const data = await quoteService.getQuoteList(body);
            if (data && data.meta) {
                setTotalCount(data.meta.total_count);
                setTotalAmount(data.meta.total_amount);
            }
            if (data && data.quotes.length > 0) {
                const totalCount = data?.meta?.total_count;
                const totalInteger = Math.floor(totalCount / PER_PAGE);
                const totalSurplus = totalCount % PER_PAGE;
                const totalPageCustom =
                    totalSurplus >= 1 ? totalInteger + 1 : totalInteger;
                setTotalPage(totalPageCustom);
                setQuoteList(data.quotes);
            } else {
                setQuoteList([]);
            }
        } catch (error) {
            alertError('データがありません。');
        }
    };
    useEffect(() => {
        if (!props.idCompany) return;
        fetchAreas();
        fetchQuoteList();
    }, [props.idCompany]);
    return (
        <>
            <div className="d-flex flex-row">
                <div className="d-flex flex-column w-full">
                    <p className="text-[20px] font-bold">案件一覧</p>
                    {/* <div className="filterSection flex items-end mb-3">
                        <div className="w-[27%]">
                            <p className="!mb-[6px] text-[14px] font-medium">
                                キーワード
                            </p>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    '.input-search': {
                                        border: '1px solid #cdd1d5',
                                        borderTopLeftRadius: '2px',
                                        borderBottomLeftRadius: '2px',
                                        marginBottom: '14.5px',
                                        paddingLeft: '14px',
                                    },
                                    '.MuiTextField-root': {
                                        border: '1px solid #cdd1d5',
                                        width: '100%',
                                        borderTopLeftRadius: '2px',
                                        borderBottomLeftRadius: '2px',
                                        paddingLeft: '14px',
                                    },
                                    '.MuiInputBase-input': {
                                        height: '38px',
                                        padding: '0px !important',
                                    },
                                    '.MuiButtonBase-root': {
                                        minWidth: '0px',
                                    },
                                }}
                            >
                                <TextField
                                    autoComplete="off"
                                    placeholder="検索"
                                    type="text"
                                    value={searchValues.filter_value}
                                    onChange={(evt) =>
                                        setSearchValues((prev) => ({
                                            ...prev,
                                            filter_value: evt.target.value,
                                        }))
                                    }
                                />
                            </Box>
                        </div>
                        <div className="w-[27%] ml-4">
                            <Box>
                                <p className="!mb-[6px] text-[14px] font-medium">
                                    日付
                                </p>
                                <RangePicker
                                    placeholder={['開始日', '終了日']}
                                    onChange={(dates, dateStrings) => {
                                        const [start, end] = dateStrings;
                                        setSearchValues((prev) => ({
                                            ...prev,
                                            invoice_start_date: start,
                                            invoice_end_date: end,
                                        }));
                                    }}
                                    className="max-h-[38px] w-full"
                                />
                            </Box>
                        </div>
                        <div className="w-[27%] ml-4">
                            <p className="!mb-[6px] text-[14px] font-medium">
                                エリア
                            </p>
                            <Box
                                sx={{
                                    '.select-search__input': {
                                        border: '1px solid #D9D9D9',
                                        boxShadow: 'none',
                                    },
                                    '.select-search__options': {
                                        paddingLeft: 0,
                                    },
                                    '.select-search__option.is-selected': {
                                        background: '#215493',
                                    },
                                }}
                            >
                                <SelectSearch
                                    options={areaList}
                                    search
                                    emptyMessage={'該当なし'}
                                    value={searchValues.prefecture}
                                    onChange={(value) =>
                                        setSearchValues((prev) => ({
                                            ...prev,
                                            prefecture: `${value}`,
                                        }))
                                    }
                                />
                            </Box>
                        </div>

                        <button
                            onClick={handleSearchQuote}
                            className="modal-create-user-button2 !w-[19%] !h-[38px] !rounded-[3px] ml-[46px] mb-[2px]"
                        >
                            検索
                        </button>
                    </div> */}
                    <div className="flex text-[14px] justify-end gap-x-[80px] font-semibold">
                        <p>
                            案件合計:{' '}
                            <span className="text-[#215493]">{totalCount}</span>
                        </p>
                        <p>
                            金額合計{' '}
                            <span className="text-[#F5222D]">
                                {'¥' + formatMoney(String(totalAmount))}
                            </span>
                        </p>
                    </div>
                    <div className="table-workers d-flex flex-column">
                        <div className="table-content d-flex flex-column">
                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 900 }}
                                    aria-label="customized table "
                                >
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell
                                                className="text-overflow1 !text-left"
                                                style={{ width: '5%' }}
                                            >
                                                <div>番号</div>
                                            </StyledTableCell>

                                            <StyledTableCell
                                                className="text-overflow1 !text-left"
                                                style={{ width: '15%' }}
                                            >
                                                <div>案件名</div>
                                            </StyledTableCell>

                                            <StyledTableCell
                                                className="text-overflow1 !text-left"
                                                style={{ width: '8%' }}
                                            >
                                                <div>案件管理者</div>
                                            </StyledTableCell>

                                            <StyledTableCell
                                                className="text-overflow1 !text-left"
                                                style={{ width: '12%' }}
                                            >
                                                <div>完了日</div>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow1 !text-left"
                                                style={{ width: '13%' }}
                                            >
                                                <div>請求状況</div>
                                            </StyledTableCell>

                                            <StyledTableCell
                                                className="text-overflow1"
                                                style={{ width: '8%' }}
                                            >
                                                <div>請負金額</div>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow1 text-center"
                                                style={{ width: '8%' }}
                                            >
                                                <div>操作</div>
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {quoteList.length > 0 &&
                                            quoteList.map((row, idx) => {
                                                return (
                                                    <StyledTableRow
                                                        key={row.id}
                                                    >
                                                        <StyledTableCell
                                                            className="!text-left"
                                                            component="th"
                                                            scope="row"
                                                            style={{
                                                                width: '5%',
                                                            }}
                                                        >
                                                            {startIndex +
                                                                idx +
                                                                1}
                                                        </StyledTableCell>

                                                        <StyledTableCell
                                                            className="!text-left"
                                                            style={{
                                                                width: '15%',
                                                            }}
                                                        >
                                                            <div>
                                                                {
                                                                    row?.project_title
                                                                }
                                                            </div>
                                                        </StyledTableCell>

                                                        <StyledTableCell
                                                            className="!text-left"
                                                            style={{
                                                                width: '8%',
                                                            }}
                                                        >
                                                            <div
                                                                className="text-overflow1"
                                                                style={{
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            >
                                                                {
                                                                    row?.staff_name
                                                                }
                                                            </div>
                                                        </StyledTableCell>

                                                        <StyledTableCell
                                                            className="!text-left"
                                                            style={{
                                                                width: '12%',
                                                            }}
                                                        >
                                                            <div
                                                                className="text-overflow1"
                                                                style={{
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            >
                                                                {row?.desired_end_date &&
                                                                    moment(
                                                                        row.desired_end_date,
                                                                    ).format(
                                                                        'YYYY/MM/DD',
                                                                    )}
                                                            </div>
                                                        </StyledTableCell>

                                                        <StyledTableCell
                                                            className="!text-left"
                                                            style={{
                                                                width: '13%',
                                                            }}
                                                        >
                                                            <div
                                                                className="text-overflow1"
                                                                style={{
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            >
                                                                {row.invoice
                                                                    ?.is_paid ===
                                                                true
                                                                    ? '支払済み'
                                                                    : '未払い'}
                                                            </div>
                                                        </StyledTableCell>

                                                        <StyledTableCell
                                                            className="!text-left"
                                                            style={{
                                                                width: '11%',
                                                            }}
                                                        >
                                                            <div>
                                                                {row.invoice
                                                                    ?.is_paid ===
                                                                true
                                                                    ? '¥' +
                                                                      formatMoney(
                                                                          String(
                                                                              row.invoice?.total_estimate_after_tax.toFixed(
                                                                                  2,
                                                                              ) ||
                                                                                  0,
                                                                          ),
                                                                      )
                                                                    : '0'}
                                                            </div>
                                                        </StyledTableCell>

                                                        <StyledTableCell
                                                            align="center"
                                                            style={{
                                                                width: '8%',
                                                            }}
                                                        >
                                                            <div className="flex justify-center items-center">
                                                                <button
                                                                    className="bg-main border-none py-2 px-2 text-white rounded-[4px] outline-none"
                                                                    onClick={() => {
                                                                        navigate(
                                                                            `/quote/project/${row.id}`,
                                                                        );
                                                                    }}
                                                                >
                                                                    詳細
                                                                </button>
                                                            </div>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                                {quoteList.length === 0 && (
                                    <div className="text-center p-[10px] w-full">
                                        データがありません。
                                    </div>
                                )}
                            </TableContainer>
                        </div>
                    </div>

                    <div className="flex items-baseline gap-[50px] justify-end mt-[20px] pr-[18px]">
                        <p className="font-normal text-[14px] text-[#666666]">
                            ページ {page} / {totalPage}
                        </p>
                        <div className="flex gap-[25px]">
                            <LeftOutlinedCustom
                                style={{ color: '#666666', fontSize: 12 }}
                                className={clsx(
                                    'cursor-pointer !font-semibold',
                                    {
                                        '!text-[black]':
                                            statusPrev && page !== 1,
                                        '!text-[#D3D3D3]': page === 1,
                                    },
                                )}
                                onClick={setPagePrev}
                                disabled
                            />

                            <RightOutlined
                                style={{ color: '#666666', fontSize: 12 }}
                                className={clsx(
                                    'cursor-pointer !font-semibold',
                                    {
                                        '!text-[black] inline-flex':
                                            statusNext && page !== totalPage,
                                        '!text-[#D3D3D3]': page === totalPage,
                                    },
                                )}
                                onClick={setPageNext}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PartnerListDetail;
