import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import { useFormik } from 'formik';

import { RootState } from 'store';
import workersService from 'services/wokersService';
import chatService from 'services/chatService';
import projectsService from 'services/projectsService';
import PositionsService from 'services/positionService';
import { validate } from './validation';

interface IProps {
    isSP?: boolean;
}
const useChat = ({ isSP }: IProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { project_id, chat_id } = useParams();
    const [searchParams] = useSearchParams();
    const isCreate = location?.pathname.includes('/chats/create');
    const fromProject = location?.pathname.includes('/projects/');
    const { userData } = useSelector((state: RootState) => state.users);

    const [projectOptions, setProjectOptions] = useState<
        {
            id: any;
            name: string;
        }[]
    >();
    const [workerOptions, setWorkerOptions] = useState<
        {
            id: any;
            name: string;
        }[]
    >();
    const [positionOptions, setPositionOptions] = useState<
        {
            id: any;
            name: string;
        }[]
    >();
    const [data, setData] = useState<any>({});
    const [isOpenProjectId, setIsOpenProjectId] = useState(false);
    const [isOpenChatWorker, setIsOpenChatWorker] = useState(false);
    const [isOpenChatPosition, setIsOpenChatPosition] = useState(false);

    const initialValues = isCreate
        ? {
            title: '',
            project_id: null,
            chat_worker_ids: [],
            chat_position_ids: [],
        }
        : {
            title: '',
            project_id: null,
            chat_worker_ids: [],
            chat_position_ids: [],
        };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validate,
        onSubmit: (values) => {
            if (project_id) {
                createChatFromProject(values);
                return;
            }
            submitForm(values);
        },
    });

    const fetchProjects = useCallback(async () => {
        try {
            const { projects } = await projectsService.getListProjects({
                page: 1,
                per_page: 9999,
            });
            setProjectOptions(
                projects.map((item) => ({
                    id: item.id,
                    name: item.name,
                })),
            );
        } catch (error) {
            //
        }
    }, []);

    const fetchWorkers = useCallback(async () => {
        try {
            const { workers } = await workersService.getListWorkers({
                page: 1,
                per_page: 9999,
            });
            setWorkerOptions(
                workers.map((item) => ({
                    id: item.id,
                    name: item.name,
                })),
            );
        } catch (error) {
            //
        }
    }, [userData.worker]);

    const fetchPositions = useCallback(async () => {
        try {
            const { positions } = await PositionsService.getListPositions({
                page: 1,
                per_page: 9999,
            });
            setPositionOptions(
                positions.map((item) => ({
                    id: item.id,
                    name: item.name,
                })),
            );
        } catch (error) {
            //
        }
    }, []);

    const fetchChat = useCallback(async () => {
        if (!chat_id) return;

        try {
            let response: any = null;
            if (project_id) {
                response = await projectsService.getChat(project_id, chat_id);
            } else {
                response = await chatService.getChat(chat_id);
            }
            if (response !== null && response !== undefined) {
                setData(response?.chat);

                formik.setValues({
                    title: response?.chat?.title,
                    project_id: response?.chat?.project,
                    chat_worker_ids: response?.chat?.workers,
                    chat_position_ids: response?.chat?.positions,
                });
            }
        } catch (error) { }
    }, [chat_id]);

    const createChatFromProject = async (response: Record<string, any>) => {
        const formData = new FormData();
        const chat_position_ids = response.chat_position_ids.map(
            (item) => item.id,
        );
        const chat_worker_ids = response.chat_worker_ids.map((item) => item.id);

        chat_worker_ids?.forEach((item) =>
            formData.append(`chat_worker_ids[]`, item),
        );
        chat_position_ids?.forEach((item) =>
            formData.append(`chat_position_ids[]`, item),
        );
        formData.append(
            'project_id',
            searchParams?.get('project_id')
                ? searchParams?.get('project_id')
                : response.project_id?.id || null || [],
        );
        formData.append('title', response?.title);

        try {
            if (chat_id) {
                await projectsService.updateChat(project_id, chat_id, formData);
                navigate(`/projects/detail/${project_id}/plan?tab=6`);
            } else {
                await projectsService.createChat(project_id, formData);

                formik.resetForm();
                navigate(`/projects/detail/${project_id}/plan?tab=6`);
            }
        } catch (error) {
            //
        }
    };

    const submitForm = async (response: Record<string, any>) => {
        const payload = {
            ...response,
            chat_position_ids: response.chat_position_ids.map(
                (item) => item.id,
            ),
            chat_worker_ids: response.chat_worker_ids.map((item) => item.id),
            project_id: searchParams?.get('project_id')
                ? searchParams?.get('project_id')
                : response.project_id?.id || null,
        };

        try {
            if (chat_id) {
                await chatService.updateChat(chat_id, {
                    ...payload,
                    chat_worker_ids: [
                        ...payload.chat_worker_ids,
                        (userData.worker as any).id,
                    ],
                });
                navigate(`/chats`);
            } else {
                const { chat } = await chatService.createChat({
                    ...payload,
                    chat_worker_ids: [
                        ...payload.chat_worker_ids,
                        (userData.worker as any).id,
                    ],
                });

                formik.resetForm();
                if (isSP) {
                    navigate(`/sp/chats/detail/${chat.id}`);
                } else {
                    navigate(`/chats/detail/${chat.id}`);
                }
            }
        } catch (error) {
            //
        }
    };

    useEffect(() => {
        if (chat_id) {
            fetchChat();
        }
        fetchProjects();
        fetchPositions();
    }, [fetchChat]);

    return {
        initialValues,
        formik,
        isCreate,
        positionOptions,
        workerOptions,
        projectOptions,
        data,
        searchParams,
        fromProject,
        project_id,
        isOpenProjectId,
        setIsOpenProjectId,
        isOpenChatWorker,
        setIsOpenChatWorker,
        isOpenChatPosition,
        setIsOpenChatPosition
    };
};

export type Props = ReturnType<typeof useChat>;

export default useChat;
