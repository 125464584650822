import { TypeOfT } from "utils/type/translations/Translation.type";
import { validZipCode, required, validKatakana } from "../validator";

const projectQuoteValidation = (t: TypeOfT) => ({
    project_name: () => ({
        required: '案件名を入力してください。',
    }),
    status_project: () => ({
        required: 'ステータスを入力してください。',
    }),
    cooperative_company_id: () => ({
        required: '協力会社を入力してください。',
    }),
    worker: () => ({
        required: '案件管理者を入力してください。',
    }),
    period: () => ({
        required: '期間を入力してください。',
    }),
});

export default projectQuoteValidation;
