import { Modal as AntdModal, ModalProps } from 'antd';
import { ReactNode } from 'react';
import styled from 'styled-components';

const Modal = styled(AntdModal)`
    .ant-modal-title {
        margin-right: 2rem;
        color: white;
    }
    .ant-modal-content {
        padding: 0;
        border-radius: 16px;
    }
    .ant-modal-header {
        padding: 12x;
        margin: 0;
        border-radius: 16px 16px 0 0;
        background: #215493;
    }
    .ant-modal-body {
        padding: 0 10px 0 10px;
    }
    .ant-modal-footer {
        padding: 0 25px 24px 25px;
        display: flex;
    }
    .ant-modal-close {
        width: 24px;
        height: 24px;
        right: 24px;
        border-radius: 100%;
        color: white !important;
    }
`;

interface IProps extends ModalProps {
    moreActions?: ReactNode;
}

const StyledModal = (props: IProps) => (
    <Modal
        centered
        cancelText="キャンセル"
        width={500}
        {...props}
        okButtonProps={{ block: true, ...props.okButtonProps }}
        cancelButtonProps={{
            block: true,
            ...props.cancelButtonProps,
        }}
        title={
            <div className="flex justify-between">
                <span style={{ overflow: 'hidden' }}>{props.title}</span>
                <div style={{ position: 'relative', bottom: 0 }}>
                    {props.moreActions}
                </div>
            </div>
        }
    />
);

export default StyledModal;
