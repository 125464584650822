import { useState, useEffect } from 'react';
import './worker.css';
import { useNavigate } from 'react-router-dom';
import plus from '../../assets/icons/Plus.png';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useModal } from 'react-hooks-use-modal';
import workersService from '../../services/wokersService';
import positionService from '../../services/positionService';
import departmentsService from '../../services/departmentsService';
import CooperativeCompanyService from '../../services/cooperativeCompaniesService';
import userService from 'services/userService';
import { handleFilter } from 'utils/helper/filterDropdown';
import { RootState, AppDispatch, useAppDispatch } from '../../store/index';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { Controller, useForm } from 'react-hook-form';
import { convertObjectToDropdownOptions } from 'utils/helper/dropdownHelper';
import { convertUserConfigToDropdownOptions } from 'utils/helper/userConfigHelper';
import { convertClientCompaniesToDropdownOptionsCreateProject } from 'utils/helper/clientCompanyHelper';
import SelectSearch from 'react-select-search';
import CustomPagination from 'components/CustomPagination';
import WorkerErrorPopup from './workerErrorPopup';
import { TextField, InputAdornment, Box } from '@mui/material';
import { Search } from '@mui/icons-material';
import staffApi from 'services/staffApi';
import PencilIcon from 'assets/icons/PencilSimpleLine.svg';
import RedTrash from 'assets/icons/Trash.svg';
import { alertSuccess, alertSuccessPopup } from 'utils/helper/appHelper';
import ExclamationIcon from 'assets/icons/exclamation.svg';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { clsx } from 'clsx';
import { Tooltip } from 'antd';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#C7DCF5',
        color: '#1C1E21',
        paddingTop: '8px',
        paddingBottom: '8px',
        fontWeight: 600,

    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Zen Kaku Gothic Antique',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#1C1E21',
        padding: '4px 16px',
    },
}));

const LeftOutlinedCustom = styled(LeftOutlined)``;

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Worker = (props) => {
    const {
        register,
        control,
        handleSubmit,
        watch,
        reset,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();
    const navigate = useNavigate();

    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const [workersList, setWorkersList] = useState<any>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage]: any = useState();
    const [statusPrev, setStatusPrev]: any = useState(false);
    const [statusNext, setStatusNext]: any = useState(false);
    const [pageQueries, setPageQueries] = useState({
        page: 1,
        per_page: 20,
    });
    const [searchedState, setSearchedState] = useState({
        cooperative_company_id: '',
        department_id: undefined,
        free_word: '',
        gender_type: undefined,
        position_id: undefined,
    });
    const [cooperativeListCompanies, setCooperativeListCompanies] =
        useState<any>(null);

    const [staffId, setStaffId] = useState<string | null>()

    const [workersDelete, setWorkersDelete] = useState<any>(null);

    const [listGenderValue, setListGenderValue] = useState<any>(null);
    const [deleteError, setDeleteError] = useState<any>(null);

    const onSearch = (data) => {
        setSearchedState({ ...data });
        if (pageQueries.page !== 1) {
            setPageQueries((prevState) => ({ ...prevState, page: 1 }));
        }
        getListWorkers({ page: 1, per_page: pageQueries.per_page, data: data });
    };

    const getListWorkers = async ({ page = 1, per_page = 20, data = {}, }) => {
        const body = {
            page,
            per_page,
            filter_value: getValues('filter_value') || ''
        }
        try {
            const res = await staffApi.getStaffs(user?.access_token, body)
            // const response = await workersService.getListWorkers({
            //     page,
            //     per_page,
            //     ...data,
            // });
            if (res !== null && res !== undefined) {
                setWorkersList(res?.data?.staffs);
                setTotalCount(res?.data?.meta?.total_count);
                const totalCount = res?.data?.meta?.total_count;
                const totalInteger = Math.floor(totalCount / 20);
                const totalSurplus = totalCount % 20;
                const totalPageCustom =
                    totalSurplus >= 1 ? totalInteger + 1 : totalInteger;
                setTotalPage(totalPageCustom);
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const getGender = (gender) => {
        switch (gender) {
            case 'male':
                return '男性'
            case 'female':
                return '女性'
            case 'other':
                return 'その他'
            default:
                return ''
        }
    }

    const deleteWorker = async () => {
        try {
            const res = await staffApi.deleteStaff(user?.access_token, staffId)
            if (res !== null && res !== undefined) {
                if (pageQueries.page !== 1) {
                    setPageQueries((prevState) => ({ ...prevState, page: 1 }));
                }
                alertSuccess(`${workersDelete?.code}を削除しました。`);
                getListWorkers({
                    page: 1,
                    per_page: pageQueries.per_page,
                    data: { ...searchedState },
                });
                setStaffId(null);
                workersDelete(null)
                closeDelete();
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const [winReady, setwinReady] = useState(false);
    useEffect(() => {
        setwinReady(true);
        reset({
            cooperative_company_id: '',
        });
    }, []);

    useEffect(() => {
        getListWorkers({
            page: pageQueries.page,
            per_page: pageQueries.per_page,
        });
    }, []);

    const setPagePrev = () => {
        if (page > 1) {
            setPage(page - 1);
            setStatusNext(false);
            setStatusPrev(true);
        }
    };
    const setPageNext = () => {
        if (page < totalPage) {
            setPage(page + 1);
            setStatusNext(true);
            setStatusPrev(false);
        }
    };

    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        'root',
        {
            preventScroll: true,
            //closeOnOverlayClick: false,
        },
    );

    return (
        <div className="container-worker d-flex flex-row">
            <div className="worker-content d-flex flex-column">
                <div
                    className="create-button d-flex flex-row"
                    style={{ height: '53px' }}
                >
                    <button
                        className="create-user-button d-flex flex-row"
                        onClick={() => navigate('/workers/create')}
                    >
                        <img width={'16px'} height={'16px'} src={plus}></img>
                        新規作成
                    </button>
                </div>
                <form onSubmit={handleSubmit(onSearch)}>
                    <div className="search-workers !h-auto flex flex-row">
                        <div className="search-workers-row grid grid-cols-5 gap-[10px] w-[80%]">
                            <div className="col-span-2">
                                <Box
                                    sx={{
                                        width: '100%',
                                        '.input-search': {
                                            border: '1px solid #cdd1d5',
                                            borderRadius: '8px',
                                            marginBottom: '14.5px',
                                            paddingLeft: '14px',
                                        },
                                        '.MuiTextField-root': {
                                            border: '1px solid #cdd1d5',
                                            width: '100%',
                                            borderRadius: '8px',
                                            marginRight: '12.5px',
                                        },
                                        '.MuiInputBase-input': {
                                            height: '35px',
                                            padding: '0px !important',
                                        },
                                    }}
                                >
                                    <p
                                        style={{
                                            marginTop: 0,
                                            marginBottom: '4px',
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            color: '#344054',
                                        }}
                                    >
                                        キーワード
                                    </p>
                                    <TextField
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Search
                                                        sx={{ color: '#000' }}
                                                    />
                                                </InputAdornment>
                                            ),
                                        }}
                                        autoComplete="off"
                                        id="input-search"
                                        type="text"
                                        name="filter_value"
                                        style={{ borderRadius: '2px' }}
                                        onChange={(e) => {
                                            setValue(
                                                'filter_value',
                                                e.target.value,
                                            );
                                        }}
                                    />
                                </Box>
                            </div>
                            {/* <div className="col-span-1">
                                <div className="input-add-user d-flex flex-column">
                                    <p style={{ marginTop: 0 }}>部署</p>
                                    <Controller
                                        name="department_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Dropdown
                                                className="dropdown-create-user1 height36"
                                                controlClassName="dropdown-control-user height36"
                                                options={departmentOption}
                                                {...field}
                                                placeholder=""
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="col-span-1">
                                <div className="input-add-user d-flex flex-column">
                                    <p style={{ marginTop: 0 }}>役職</p>
                                    <Controller
                                        name="position_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Dropdown
                                                className="dropdown-create-user1 height36"
                                                controlClassName="dropdown-control-user height36"
                                                options={positionOption}
                                                {...field}
                                                placeholder=""
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="col-span-1">
                                <div className="input-add-user d-flex flex-column">
                                    <p style={{ marginTop: 0 }}>性別</p>
                                    <Controller
                                        name="gender_type"
                                        control={control}
                                        render={({ field }) => (
                                            <Dropdown
                                                className="dropdown-create-user1 height36"
                                                controlClassName="dropdown-control-user height36"
                                                options={listGenderValue}
                                                {...field}
                                                placeholder=""
                                            />
                                        )}
                                    />
                                </div>
                            </div> */}
                            <button
                                className="search-user-button margin-left mt-[25px] d-flex flex-column"
                                style={{
                                    width: '150px',
                                    height: '36px',
                                    marginBottom: '12px',
                                }}
                            >
                                検索
                            </button>
                        </div>
                        {/* <div className="mt-[25px] justify-end margin-left d-flex !w-[20%]">
                            <button
                                className="search-user-button d-flex flex-column"
                                style={{
                                    width: '150px',
                                    height: '36px',
                                    marginBottom: '12px',
                                }}
                            >
                                検索
                            </button>
                        </div> */}
                    </div>
                </form>
                {/* <div className='table-workers d-flex flex-column'> */}
                <div className="table-content d-flex flex-column">
                    {winReady ? (
                        <TableContainer component={Paper} table-layout={'auto'}>
                            <Table
                                sx={{ minWidth: 1200 }}
                                aria-label="customized table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell
                                            className="text-overflow1"
                                            align="center"
                                            style={{ width: '5%' }}
                                        >
                                            <div>No</div>
                                        </StyledTableCell>

                                        <StyledTableCell
                                            className="text-overflow1"
                                            align="center"
                                            style={{ width: '15%' }}
                                        >
                                            <div>社員コード</div>
                                        </StyledTableCell>

                                        <StyledTableCell
                                            className="text-overflow1"
                                            align="center"
                                            style={{ width: '10%' }}
                                        >
                                            <div>名前</div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                            className="text-overflow1"
                                            align="center"
                                            style={{ width: '15%' }}
                                        >
                                            <div
                                                style={{ whiteSpace: 'nowrap' }}
                                            >
                                                フリガナ
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                            className="text-overflow1"
                                            align="center"
                                            style={{ width: '5%' }}
                                        >
                                            <div
                                                style={{ whiteSpace: 'nowrap' }}
                                            >
                                                性別
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                            className="text-overflow1"
                                            align="center"
                                            style={{ width: '10%' }}
                                        >
                                            <div>部署</div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                            className="text-overflow1"
                                            align="center"
                                            style={{ width: '10%' }}
                                        >
                                            <div>役職</div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                            className="text-overflow1"
                                            align="center"
                                            style={{ width: '15%' }}
                                        >
                                            <div>メールアドレス</div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                            className="text-overflow1"
                                            align="center"
                                            style={{
                                                width: '20%',
                                                minWidth: '220px',
                                            }}
                                        >
                                            <div>操作</div>
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {workersList &&
                                        workersList.map((row, ind) => {
                                            return (
                                                <StyledTableRow key={ind}>
                                                    <StyledTableCell
                                                        align="center"
                                                        component="th"
                                                        scope="row"
                                                        style={{ width: '10%' }}
                                                    >
                                                        <div
                                                            className="text-overflow1"
                                                            style={{
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                        >
                                                            {ind + 1}
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        align="center"
                                                        style={{ width: '15%' }}
                                                    >
                                                        <div
                                                            className="text-overflow1"
                                                            style={{
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                        >
                                                            {
                                                                row?.code
                                                            }
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        align="center"
                                                        component="th"
                                                        scope="row"
                                                        style={{ width: '10%' }}
                                                    >
                                                        <div
                                                            className="line-clamp-4 text-ellipsis overflow-hidden"
                                                        >
                                                            {row?.name}
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        align="center"
                                                        style={{ width: '15%' }}
                                                    >
                                                        {/* {row?.kana} */}
                                                        <div className="line-clamp-4 text-ellipsis overflow-hidden">
                                                            {row?.furigana_name}
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        align="center"
                                                        style={{ width: '5%' }}
                                                    >
                                                        <div
                                                            className="text-overflow1"
                                                            style={{
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                        >
                                                            {getGender(row?.gender)}
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        align="center"
                                                        style={{ width: '10%' }}
                                                    >
                                                        <div className="line-clamp-4 text-ellipsis overflow-hidden">
                                                            {row?.department}
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        align="center"
                                                        style={{ width: '10%' }}
                                                    >
                                                        <div
                                                            className="line-clamp-4 text-ellipsis overflow-hidden"
                                                        >
                                                            {row?.role}
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        align="center"
                                                        style={{ width: '15%' }}
                                                    >
                                                        <div className="line-clamp-4 text-ellipsis overflow-hidden">
                                                            {row?.email}
                                                        </div>
                                                    </StyledTableCell>

                                                    <StyledTableCell
                                                        align="center"
                                                        style={{ width: '20%' }}
                                                    >
                                                        <div className="flex justify-center items-center">
                                                            <Box
                                                                sx={{
                                                                    cursor: 'pointer',
                                                                    width: 24,
                                                                    height: 24,
                                                                    margin: '0px 8px 0px 4px',
                                                                }}
                                                                onClick={() =>
                                                                    navigate(
                                                                        `/workers/edit/${row?.id}`,
                                                                    )
                                                                }
                                                            >
                                                                <Tooltip title="編集">
                                                                    <img
                                                                        src={
                                                                            PencilIcon
                                                                        }
                                                                        alt="pencil icon"
                                                                    />
                                                                </Tooltip>
                                                            </Box>

                                                            {!row?.invoice && <Box
                                                                sx={{
                                                                    cursor: 'pointer',
                                                                    width: 24,
                                                                    height: 24,
                                                                    margin: '0px 4px',
                                                                }}
                                                                onClick={() => {
                                                                    openDelete();
                                                                    setStaffId(row?.id)
                                                                    setWorkersDelete(row)
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        RedTrash
                                                                    }
                                                                    alt="red trash icon"
                                                                />
                                                            </Box>}
                                                        </div>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : null}
                    <ModalDelete>
                        <div
                            className="modal-create-user justify-center items-center d-flex flex-column"
                            style={{ height: 'auto' }}
                        >
                            <img
                                src={ExclamationIcon}
                                className="w-[80px] h-[80px]"
                                alt="exclamation icon"
                            />
                            <div className="!text-[#215493]  font-medium text-[16px] pt-[5px]">
                                警告
                            </div>
                            <div className="text-[#3A3B3C] font-normal d-flex flex-row mt-[15px]">
                                本当に削除しますか?
                            </div>
                            <div
                                className="modal-create-user-buttons d-flex flex-row"
                                style={{ marginTop: '50px' }}
                            >
                                <button
                                    className="modal-create-user-button1 d-flex flex-row"
                                    onClick={closeDelete}
                                >
                                    キャンセル
                                </button>
                                <button
                                    onClick={deleteWorker}
                                    className="modal-create-user-button2 d-flex flex-row"
                                >
                                    はい
                                </button>
                            </div>
                        </div>
                    </ModalDelete>
                    <WorkerErrorPopup
                        isOpen={!!deleteError}
                        message={deleteError}
                        onClose={() => setDeleteError(null)}
                    />
                </div>
                {/* </div> */}
                {workersList && workersList.length > 0 && (
                    // <CustomPagination
                    //     totalCount={totalCount}
                    //     pageQueries={pageQueries}
                    //     setPageQueries={setPageQueries}
                    //     fetchList={(page) =>
                    //         getListWorkers({
                    //             page,
                    //             per_page: pageQueries.per_page,
                    //             data: { ...searchedState },
                    //         })
                    //     }
                    // />
                    <div className="flex items-baseline gap-[50px] justify-end mt-[20px] pr-[18px]">
                        <p className="font-normal text-[14px] text-[#666666]">
                            ページ {page} / {totalPage}
                        </p>
                        <div className="flex gap-[25px]">
                            <LeftOutlinedCustom
                                style={{ color: '#666666', fontSize: 12 }}
                                className={clsx(
                                    'cursor-pointer !font-semibold',
                                    {
                                        '!text-[black]': statusPrev && page !== 1,
                                        '!text-[#D3D3D3]': page === 1,
                                    },
                                )}
                                onClick={setPagePrev}
                                disabled
                            />

                            <RightOutlined
                                style={{ color: '#666666', fontSize: 12 }}
                                className={clsx(
                                    'cursor-pointer !font-semibold',
                                    {
                                        '!text-[black] inline-flex':
                                            statusNext && page !== totalPage,
                                        '!text-[#D3D3D3]': page === totalPage,
                                    },
                                )}
                                onClick={setPageNext}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Worker;
