import { useEffect, useState } from 'react';
import moment from 'moment';
import { useModal } from 'react-hooks-use-modal';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash';

import estimateService from 'services/estimationService';
import estimateItemService from 'services/estimateItemService';
import childEstimateItemService from 'services/childEstimateItemService';

import ModalCreateNew from './components/ListDetail/ModalCreateNew';
import FormEdit from './components/FormEdit';
import ListDetail from './components/ListDetail';
import CommentBox from './components/CommentBox';
import { useLogicEstimate } from '../hooks';
import { Main } from './styled';
import { Button } from 'antd';
import { ExclamationOutlined } from '@ant-design/icons';

const EstimationEdit = (props) => {
    const [
        ModalCreateEstimateItem,
        openCreateEstimateItem,
        closeCreateEstimateItem,
        isOpen,
    ] = useModal('root', {
        preventScroll: true,
        // //closeOnOverlayClick: false,
    });
    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        'root',
        {
            preventScroll: true,
        },
    );
    const [ModalError, openModalError, closeModal, isOpenModalError] = useModal(
        'root',
        {
            preventScroll: true,
        },
    );
    const { estimationId = null } = useParams();
    const navigate = useNavigate();

    const {
        id,
        info = {},
        project = {},
        workers = [],
        statusOptions = [],
        loading = false,
        estimate = {},
        detailEstimateItem = [],

        setLoading,
        getProfileUser,
        getListWorkers,
        getProjectDetail,
        getDetailEstimate,
        getDetailEstimateItem,
    } = useLogicEstimate();
    const [rowData, setRowData] = useState<any>({});
    const [deleteItemRow, setDeleteItemRow] = useState<any>({});
    const [dataEstimateItem, setDataEstimateItem] = useState<any>([]);

    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (id && estimationId) {
            getProjectDetail(Number(id));
            // getListWorkers();
            getDetailEstimate(Number(estimationId));
            getProfileUser();
        }
    }, []);

    const submitForm = async (data) => {
        setLoading(true);

        return await estimateService
            .updateEstimate(Number(estimationId), {
                project_id: id,
                status: data.status.value,
                assigned_worker_id: data.assigned_worker.value,
                approver_worker_id: data.approver_worker.value,
                // estimate_items_attributes: data.estimate_items,
                approve_expired_at: moment(data.approve_expired_at).format(
                    'YYYY-MM-DD',
                ),
            })
            .then((res) => {
                if (res?.estimate?.id && !data.isGotoEdit) {
                    if (!searchParams.get('from')) {
                        return navigate(`/projects/detail/${id}/plan?tab=2`);
                    }
                    return navigate(-1);
                }

                if (res?.estimate?.id && data.isGotoEdit) {
                    return navigate(
                        `/projects/estimation/${id}/edit/${res?.estimate?.id}`,
                    );
                }
            })
            .catch((err) => { })
            .finally(() => setLoading(false));
    };

    const submitFormCreateNewEstimateItem = async (data) => {
        setLoading(true);
        let formData = new FormData();
        formData.append('name', data?.name?.trim() ?? '');
        formData.append('summary', data?.summary?.trim() ?? '');
        formData.append('note', data?.note?.trim() ?? '');
        formData.append('is_parent', data.is_parent ?? '');
        formData.append('estimate_id', String(estimationId) ?? '');
        formData.append('project_id', String(id) ?? '');
        formData.append('amount', data.amount ?? '');
        formData.append('unit', data?.unit?.trim() ?? '');
        formData.append('unit_price', data.unit_price ?? '');
        formData.append('price', data.price ?? '');

        if (data?.parent_estimate_item_id?.value) {
            formData.append(
                'parent_estimate_item_id',
                String(data?.parent_estimate_item_id?.value) ?? '',
            );
        }

        return await estimateItemService
            .createEstimateItem(formData)
            .then((res) => {
                if (res?.estimate_item?.id) {
                    closeCreateEstimateItem();
                    getDetailEstimate(Number(estimationId));
                    getListItem({});
                }
            })
            .catch((err) => { })
            .finally(() => setLoading(false));
    };

    const [actionEstItem, setActionEstItem] = useState<any>({
        dataItem: { id: '' },
        type: 'create',
    });
    const submitFormUpdateNewEstimateItem = async (data) => {
        return await estimateItemService
            .updateEstimateItem(Number(actionEstItem?.dataItem?.id), {
                estimate_id: estimationId,
                parent_estimate_item_id: data.is_parent
                    ? null
                    : data?.parent_estimate_item_id?.value,
                is_parent: data.is_parent ?? '',
                name: data?.name?.trim() ?? '',
                summary: data?.summary?.trim() ?? '',
                amount: data.amount ?? '',
                unit: data?.unit?.trim() ?? '',
                unit_price: data.unit_price ?? '',
                note: data?.note?.trim() ?? '',
            })
            .then((res) => {
                if (res?.estimate_item?.id) {
                    closeCreateEstimateItem();
                    getDetailEstimate(Number(estimationId));
                    getListItem({});
                }
            })
            .catch((err) => { })
            .finally(() => setLoading(false));
    };

    const getListEstimateChildItem = (child_estimate_items_id: string) => {
        getDetailEstimateItem({
            project_id: String(id),
            estimate_id: estimationId,
            id: child_estimate_items_id,
        });
    };

    const handleDeleteEstimateItem = async () => {
        const response = await estimateItemService.deleteEstimateItem(
            deleteItemRow.id,
            estimationId,
        );

        // call func data
        if (response !== null && response !== undefined) {
            getDetailEstimate(Number(estimationId));
        }
        getListItem({});
        closeDelete();
    };

    const goBackDetailParentEstimate = () => {
        // update array estimate
        dataEstimateItem.pop();
        setDataEstimateItem(dataEstimateItem);

        // update object children estimate item
        const row = _.size(dataEstimateItem)
            ? dataEstimateItem[_.size(dataEstimateItem) - 1]
            : {};
        setRowData(row);

        // call api detail estimate
        if (row?.id) getListEstimateChildItem(row?.id);
        else getDetailEstimate(Number(estimationId));
    };

    const onDeleteEstimateItem = (row) => {
        setDeleteItemRow(row);
        openDelete();
    };

    const onGetDataChildrenEstimateItem = (row) => {
        setRowData(row);
        setDataEstimateItem([...dataEstimateItem, { ...row }]);
        getListEstimateChildItem(row?.id);
    };
    const [dataList, setDataList] = useState<any[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const getListItem = async ({
        page = 1,
        per_page = 30,
        estimate_id = String(estimationId),
    }: any) => {
        const response = await estimateItemService.getListEstimateItem({
            page,
            per_page,
            estimate_id,
        });

        if (response !== null && response !== undefined) {
            const data = response?.estimate_items?.map((item) => {
                var newObj = {};
                Object.keys(item).forEach((key) => {
                    newObj['key'] = item['id'];
                    newObj[key] = item[key];
                    // if (key === "child_estimate_items" && item[key].length > 0)
                    //   newObj["children"] = item[key];
                    // else {
                    // }
                });
                return newObj;
            });
            setDataList(data);
            setTotalCount(response.meta.total_count);
        }
    };

    const handleImportEst = async (event) => {
        let formData = new FormData();
        const fileData = event.target.files[0];
        if (fileData) {
            formData.append('estimate_id', String(estimationId));
            formData.append('attachment', fileData);
            try {
                const response = await estimateItemService.importEstItem(
                    formData,
                );
                if (response) {
                    getListItem({});
                } else {
                    openModalError();
                }
            } catch (error) {
                openModalError();
            }
            getListItem({});
        }
    };

    return (
        <Main>
            <FormEdit
                id={id}
                loading={loading}
                project={project}
                status={statusOptions}
                workers={workers}
                estimate={estimate}
                submitForm={submitForm}
            />

            <div className="d-flex">
                <ListDetail
                    importEst={handleImportEst}
                    totalCount={totalCount}
                    totalPrice={
                        !!_.size(dataEstimateItem)
                            ? detailEstimateItem.total_price
                            : estimate.total_price
                    }
                    onAddLineNew={openCreateEstimateItem}
                    onBack={goBackDetailParentEstimate}
                    // showDataEstimateItem={!!_.size(dataEstimateItem)}
                    openDelete={onDeleteEstimateItem}
                    onGetDetail={onGetDataChildrenEstimateItem}
                    getListItem={getListItem}
                    dataList={dataList}
                    setActionEstItem={setActionEstItem}
                />

                <CommentBox
                    infoUser={info}
                    estimateId={Number(estimationId)}
                    showDataEstimateItem={!!_.size(dataEstimateItem)}
                    estimateItemId={
                        !!_.size(dataEstimateItem) && dataEstimateItem[0]?.id
                    }
                />
            </div>

            <ModalCreateEstimateItem>
                <ModalCreateNew
                    item={rowData}
                    onClose={closeCreateEstimateItem}
                    submitForm={
                        actionEstItem?.type == 'create'
                            ? submitFormCreateNewEstimateItem
                            : submitFormUpdateNewEstimateItem
                    }
                    showDataEstimateItem={!!_.size(dataEstimateItem)}
                    listEstimateItem={estimate?.estimate_items}
                    actionEstItem={actionEstItem}
                />
            </ModalCreateEstimateItem>
            <ModalDelete>
                <div
                    className="modal-create-user d-flex flex-column"
                    style={{ height: '152px' }}
                >
                    <div className="modal-create-user-title d-flex flex-row">
                        本当に削除しますか?
                    </div>
                    <div
                        className="modal-create-user-buttons d-flex flex-row"
                        style={{ marginTop: '50px' }}
                    >
                        <button
                            className="modal-create-user-button1 d-flex flex-row"
                            onClick={closeDelete}
                        >
                            キャンセル
                        </button>
                        <button
                            className="modal-create-user-button2 d-flex flex-row"
                            style={{ background: '#FF5045' }}
                            onClick={handleDeleteEstimateItem}
                        >
                            はい
                        </button>
                    </div>
                </div>
            </ModalDelete>
            <ModalError>
                <div
                    className="modal-create-user !w-[450px] d-flex flex-column relative"
                    style={{ height: '152px' }}
                >
                    <Button
                        className="!bg-[#215493] !text-[white] !border-0 !absolute right-[20px] top-[20px]"
                        onClick={closeModal}
                    >
                        X
                    </Button>
                    <div className="flex !justify-between">
                        <div className="modal-create-user-title !h-full">
                            <p className="!mb-0">
                                <ExclamationOutlined className="icon-error-mss" />
                                データのインポートに失敗しました。
                            </p>
                            ファイルを確認してください
                        </div>
                    </div>
                </div>
            </ModalError>
        </Main>
    );
};

export default EstimationEdit;
