import { FC, useState } from 'react';
import { InvoiceDetailStyles } from './styled';
import pdfFileIcon from 'assets/icons/pdf-file.svg';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import useSPInvoiceDetail, { Props, ReceivedProps } from './hook';
import CalendarIcon from 'assets/icons/calendar-icon.png';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { determineStatus, formatCurrency } from 'utils/helper/appHelper';
import { invoiceStatus, tax } from 'utils/constants';
import querystring from 'query-string';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { parseNamePdf } from 'pages/invoice';

const SPInvoiceLayout: FC<Props> = ({ invoice, updateStatusInvoice }) => {
    const navigate = useNavigate();
    const param = useParams();
    const queryObj = querystring.parse(location.search);
    const [isOpenPreview, setOpenPreview] = useState<boolean>(false);
    const [pdfUrl, setPdfUrl] = useState<string>('');

    const renderContentPreview = () => {
        return (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer fileUrl={pdfUrl} />
            </Worker>
        );
    };

    return (
        <InvoiceDetailStyles>
            {isOpenPreview && (
                <Dialog
                    onClose={() => setOpenPreview(false)}
                    sx={{
                        width: '100%',
                        '.MuiPaper-root': {
                            width: '100%',
                            height: '100%',
                            margin: '0px',
                        },

                        '.rpv-core__inner-container': {
                            paddingBottom: '30px !important',
                        },
                    }}
                    // fullScreen={fullScreen}
                    maxWidth="xl"
                    fullWidth
                    open={isOpenPreview}
                >
                    <DialogContent>{renderContentPreview()}</DialogContent>
                </Dialog>
            )}

            <div
                className="rounded-2xl py-2 px-1 text-[14px]"
                style={{ gap: '10px', margin: '14px 16px' }}
            >
                <div className="flex flex-col gap-2">
                    <div className="flex flex-row justify-between items-center">
                        <p className="font-bold text-center !m-0">基本情報</p>
                        <div
                            className="text-overflow1"
                            style={{
                                whiteSpace: 'nowrap',
                                color: determineStatus(invoice?.status)?.color,
                                backgroundColor: determineStatus(
                                    invoice?.status,
                                )?.bg,
                                borderRadius: '15px',
                                padding: '4px 9px',
                            }}
                        >
                            <p className="!m-0">
                                {determineStatus(invoice?.status)?.jpText}
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-row justify-between">
                        <p className="text-[#666666] !m-0">
                            会社名:{' '}
                            <span className="text-[#000]">
                                {' '}
                                {invoice?.company_name}{' '}
                            </span>
                        </p>
                    </div>
                    <div className="flex flex-row">
                        <img
                            src={CalendarIcon}
                            alt="calendar"
                            style={{ height: '16px' }}
                        />{' '}
                        <p className="text-[#666666] !ml-2 !m-0">
                            {moment(invoice?.invoice_date)?.format(
                                'YYYY年MM月DD日',
                            )}
                        </p>
                    </div>
                    {/* <p className='text-[#666666]' >電話番号: <span className='text-[#000]' >{invoice?.company_bank_account}</span></p> */}
                    <p className="!m-0">{invoice?.company_address}</p>
                    <hr className="bg-[#D9D9D9]" />
                    <p className="text-[#666666] !m-0">
                        お客様:{' '}
                        <span className="text-[#000]">
                            {invoice?.customer_name}
                        </span>
                    </p>
                    <p className="!m-0">{invoice?.customer_address}</p>
                </div>
                {invoice?.estimate_pdf_url && (
                    <div
                        className="flex items-center justify-start m-2 mb-4"
                        onClick={
                            () =>
                                window.open(
                                    invoice?.estimate_pdf_url?.file_path,
                                    '_blank',
                                    'noopener,noreferrer',
                                )
                            // setOpenPreview(true)
                            // setPdfUrl(invoice?.estimate_pdf_url?.file_path)
                        }
                    >
                        <img src={pdfFileIcon} alt="pdf file icon" />
                        <div className="ml-3">
                            <div className="text-ellipsis whitespace-nowrap overflow-hidden	max-w-[450px] ">
                                {parseNamePdf(invoice?.estimate_pdf_url?.file_path)}.PDF
                            </div>
                            <div className="text-[#0000008C]">
                                {invoice?.estimate_pdf_url
                                    ? invoice?.estimate_pdf_url?.size
                                    : '0 KB'}
                            </div>
                        </div>
                    </div>
                )}
                {invoice?.invoice_pdf_url && (
                    <div
                        className="flex items-center justify-start m-2 mb-4"
                        onClick={
                            () =>
                                window.open(
                                    invoice?.invoice_pdf_url?.file_path,
                                    '_blank',
                                    'noopener,noreferrer',
                                )
                            // setOpenPreview(true)
                            // setPdfUrl(invoice?.invoice_pdf_url?.file_path)
                        }
                    >
                        <img src={pdfFileIcon} alt="pdf file icon" />
                        <div className="ml-3">
                            <div className="text-ellipsis whitespace-nowrap overflow-hidden	max-w-[450px] ">
                                {parseNamePdf(invoice?.invoice_pdf_url?.file_path)}.PDF
                            </div>
                            <div className="text-[#0000008C]">
                                {invoice?.invoice_pdf_url
                                    ? invoice?.invoice_pdf_url?.size
                                    : '0 KB'}
                            </div>
                        </div>
                    </div>
                )}
                {invoice?.invoice_details &&
                    invoice?.invoice_details.map((itemParent, index) => (
                        <div key={index}>
                            <p className="text-[14px] font-bold text-start mb-2">
                                {itemParent?.content}
                            </p>
                            {itemParent?.children?.map((item, id) => (
                                <div
                                    className="rounded-2xl py-2 px-3 mb-[15px] wrapperBox text-[14px] gap-1 flex flex-column"
                                    key={id}
                                >
                                    <p className="text-[14px] font-bold text-center">
                                        {item?.content}
                                    </p>
                                    <div className="flex flex-row justify-between">
                                        <p className="text-[#666666]">数量</p>
                                        <p>{item?.quantity_estimate}</p>
                                    </div>
                                    <div className="flex flex-row justify-between">
                                        <p className="text-[#666666]">単位</p>
                                        <p>{item?.unit_estimate}</p>
                                    </div>
                                    <div className="flex flex-row justify-between">
                                        <p className="text-[#666666]">単価</p>
                                        <p>
                                            ¥
                                            {item?.price_estimate &&
                                                item?.price_estimate?.toLocaleString(
                                                    'en-US',
                                                )}
                                        </p>
                                    </div>
                                    <div className="flex flex-row justify-between">
                                        <p className="text-[#666666]">税率</p>
                                        <p>{tax}%</p>
                                    </div>
                                    <div className="flex flex-row justify-between">
                                        <p className="text-[#666666]">金額</p>
                                        <p>
                                            ¥
                                            {item?.price_estimate &&
                                                formatCurrency(
                                                    item?.price_estimate *
                                                    (item?.quantity_estimate ||
                                                        1) *
                                                    (1 + tax / 100),
                                                )}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
            </div>
            <hr className="text-[#666666]" />
            <div
                style={{
                    gap: '10px',
                    margin: '14px 16px',
                }}
                className="px-[16px] pt-[10px]"
            >
                <div className="flex flex-row justify-between">
                    <p className="text-[#666666]">小計</p>
                    <p>¥{formatCurrency(invoice?.total_estimate_before_tax)}</p>
                </div>
                <div className="flex flex-row justify-between">
                    <p className="text-[#666666]">割引</p>
                    {/* <p>-¥{invoice?.total_tax.toLocaleString('en-US')}</p> */}
                    <p>-¥0</p>
                </div>
                <div className="flex flex-row justify-between">
                    <p className="text-[#666666]">消費税</p>
                    <p>
                        ¥
                        {formatCurrency(
                            (tax / 100) * invoice?.total_estimate_before_tax,
                        )}
                    </p>
                </div>
                <div className="flex flex-row justify-between text-[20px] mt-1">
                    <p>合計</p>
                    <p>¥{formatCurrency(invoice?.total_estimate_after_tax)}</p>
                </div>
            </div>
            <div
                className="bg-[#fff] flex flex-row justify-center w-full"
                style={{ padding: '16px 24px 16px 24px' }}
            >
                <div
                    className="d-flex flex-row justify-center text-lg w-full"
                    style={{
                        marginTop: '14px',
                    }}
                >
                    {!(
                        invoice?.status ===
                        invoiceStatus?.estimate_sent_and_waiting_approval
                            ?.value ||
                        invoice?.status ===
                        invoiceStatus?.waiting_for_payment?.value ||
                        invoice?.status ===
                        invoiceStatus?.invoice_sent_and_waiting_approval
                            ?.value
                    ) && (
                            <button
                                className="modal-create-user-button1 d-flex flex-row !w-full"
                                style={{
                                    border: '1px solid #D9D9D9',
                                    color: '#000000D9',
                                }}
                                type="button"
                                onClick={() => navigate(-1)}
                            >
                                キャンセル
                            </button>
                        )}
                    {invoice?.status ===
                        invoiceStatus?.estimate_sent_and_waiting_approval
                            ?.value && (
                            <div className="w-full flex justify-between items-center">
                                <button
                                    className="modal-create-user-button1 d-flex flex-row !border-[#D82121] w-full !text-white bg-[#D82121]"
                                    type="button"
                                    onClick={() =>
                                        updateStatusInvoice(
                                            invoiceStatus?.estimate_rejected?.value,
                                        )
                                    }
                                >
                                    拒否
                                </button>
                                <button
                                    className="modal-create-user-button2 d-flex flex-row w-full"
                                    type="submit"
                                    onClick={() =>
                                        updateStatusInvoice(
                                            invoiceStatus?.estimate_approved?.value,
                                        )
                                    }
                                >
                                    承認
                                </button>
                            </div>
                        )}
                    {invoice?.status ===
                        invoiceStatus?.waiting_for_payment?.value && (
                            <div className="w-full flex justify-between items-center gap-2">
                                <button
                                    className="modal-create-user-button1 d-flex flex-row !w-full"
                                    type="button"
                                    onClick={() => navigate(-1)}
                                    style={{
                                        border: '1px solid #D9D9D9',
                                        color: '#000000D9',
                                    }}
                                >
                                    キャンセル
                                </button>
                                <button
                                    className="modal-create-user-button2 d-flex flex-row !w-full"
                                    type="submit"
                                    onClick={() =>
                                        navigate(
                                            `/sp/quote/payment/${param?.id}${queryObj.top === 'true'
                                                ? '?top=true'
                                                : ''
                                            }`,
                                        )
                                    }
                                >
                                    支払い
                                </button>
                            </div>
                        )}
                    {invoice?.status ===
                        invoiceStatus?.invoice_sent_and_waiting_approval
                            ?.value && (
                            <div className="w-full flex justify-between items-center">
                                <button
                                    className="modal-create-user-button1 d-flex flex-row !border-[#D82121] w-full !text-white bg-[#D82121]"
                                    type="button"
                                    onClick={() =>
                                        updateStatusInvoice(
                                            invoiceStatus?.invoice_rejected?.value,
                                        )
                                    }
                                >
                                    拒否
                                </button>
                                <button
                                    className="modal-create-user-button2 d-flex flex-row w-full"
                                    type="submit"
                                    onClick={() =>
                                        updateStatusInvoice(
                                            invoiceStatus?.invoice_accepted_done
                                                ?.value,
                                        )
                                    }
                                >
                                    承認
                                </button>
                            </div>
                        )}
                </div>
            </div>
        </InvoiceDetailStyles>
    );
};

const SPInvoiceDetail: FC<ReceivedProps> = (props) => {
    return <SPInvoiceLayout {...useSPInvoiceDetail(props)} />;
};

export default SPInvoiceDetail;
