import fetch from './api/config';

const API = {
    GET_LIST_COOPERATIVE_COMPANIES: () => `/cooperative_companies`,
    GET_DETAIL_COOPERATIVE_COMPANY: (id: number | string) => `/cooperative_companies/${id}`,
    GET_LIST_AREAS: () => `/areas`,
};

export default class PartnerService {
    static getListCooperativeCompanies = (params: any) =>
        fetch.get(API.GET_LIST_COOPERATIVE_COMPANIES(), params);

    static deleteCooperativeCompany = (id: number) =>
        fetch.delete(API.GET_DETAIL_COOPERATIVE_COMPANY(id), {});

    static createCooperativeCompany = (params: any) =>
        fetch.post(API.GET_LIST_COOPERATIVE_COMPANIES(), params);

    static updateCooperativeCompany = (id: number | string, params: any) =>
        fetch.put(API.GET_DETAIL_COOPERATIVE_COMPANY(id), params);

    static getCooperativeCompanyDetail = (id: number | string) =>
        fetch.get(API.GET_DETAIL_COOPERATIVE_COMPANY(id), {});

    static getListAreas = () =>
        fetch.get(API.GET_LIST_AREAS(), {});
}
